import { useCallback, useMemo } from "react";
import { getSelectedEnhancedDataLayers } from "@app/store/currentUser/currentUser.selector";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { appSetEnhancedLayers } from "@app/store/userPreferences/userPreferences.actions";
import { StlCheckboxGroup, StlExternalLink } from "@common/components";
import { useGetPickerData } from "@common/components/baseMap/customControls/mapLayers/usePickerData";
import {
    ENHANCED_LAYERS_LIST,
    TEnhancedLayer,
    TMapLayersLocalState,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EnhancedLayerOptionLabelWithLegend = ({ option }: { option: TEnhancedLayer }) => {
    const getLegendStyle = () => ({
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: 5,
        width: 15,
        ...option.style,
    });

    return (
        <div className="enhanced-layers-option">
            <FontAwesomeIcon style={getLegendStyle()} icon={option.icon} />
            <span>{option.display}</span>
            {option.helpIcon && option.helpLink && (
                <StlExternalLink
                    href={option.helpLink}
                    className="enhanced-layers-option-help-link"
                >
                    <FontAwesomeIcon
                        id={option.helpIcon}
                        icon={faInfoCircle}
                        className="enhanced-layers-option-help-icon"
                    />
                </StlExternalLink>
            )}
        </div>
    );
};

type TProps = {
    mapLayersLocalState: TMapLayersLocalState | null;
    updateMapLayersState: (value: Partial<TMapLayersLocalState>) => void;
};

export const MapDACLayersPicker = ({ mapLayersLocalState, updateMapLayersState }: TProps) => {
    const persistedEnhancedDataLayers = useAppSelector(getSelectedEnhancedDataLayers);
    const dispatch = useAppDispatch();

    const onUpdateLayers = (layerCodes: string[]) => {
        if (mapLayersLocalState) {
            updateMapLayersState({ enhancedLayers: layerCodes });
        } else {
            dispatch(appSetEnhancedLayers(layerCodes));
        }
    };

    const selectedLayers = useMemo(() => {
        return mapLayersLocalState
            ? ENHANCED_LAYERS_LIST.filter(layer =>
                  mapLayersLocalState.enhancedLayers.includes(layer.code),
              )
            : persistedEnhancedDataLayers;
    }, [mapLayersLocalState, persistedEnhancedDataLayers]);

    const getLabel = useCallback(
        (option: TEnhancedLayer) => <EnhancedLayerOptionLabelWithLegend option={option} />,
        [],
    );
    const { options, value, changeSelectedLayers } = useGetPickerData({
        availableLayers: ENHANCED_LAYERS_LIST,
        getLabel,
        selectedLayers,
        onUpdateLayers,
    });

    return (
        <div className="enhanced-data-layers">
            <label className="picker-label secondary">Enhanced data layer</label>
            <StlCheckboxGroup options={options} value={value} onChange={changeSelectedLayers} />
        </div>
    );
};
