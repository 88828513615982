import { useCallback, useMemo, useState } from "react";
import type { Map } from "mapbox-gl";
import { useAppDispatch } from "@app/store/hooks";
import { appSetEnhancedLayers } from "@app/store/userPreferences/userPreferences.actions";
import { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapDACLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/dac/mapDACLayersPicker";
import { MapOSMLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/mapOSMLayersPicker";
import { useOsmCheckboxValues } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/useOsmCheckboxValues";
import {
    IMapLayersPickerDisplayConfig,
    MapLayers,
} from "@common/components/baseMap/mapLayers/mapLayers";
import { TMapLayersLocalState } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import "./mapLayersPicker.less";

const DEFAULT_DISPLAY_CONFIG: IMapLayersPickerDisplayConfig = {
    showOsm: true,
    showDac: true,
};

export const MapLayersPicker = ({
    map,
    displayConfig = DEFAULT_DISPLAY_CONFIG,
    options,
    disabledOptions,
    mapLayersConfig,
}: {
    map: Map | null;
    options?: string[];
    disabledOptions?: string[];
    displayConfig?: IMapLayersPickerDisplayConfig;
    mapLayersConfig?: TControlConfig["mapLayersConfig"];
}) => {
    const [mapLayersState, setMapLayersState] = useState<TMapLayersLocalState>({
        osmLayersCategories: {},
        enhancedLayers: [],
    });

    const dispatch = useAppDispatch();

    const isNPAnalysis = arrayIncludes(
        [MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code, MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code],
        mapLayersConfig?.travelModeCode,
    );
    const mapLayersLocalState = useMemo(
        () => (mapLayersConfig?.shouldUseLocalState ? mapLayersState : null),
        [mapLayersConfig, mapLayersState],
    );

    const updateMapLayersState = useCallback((value: Partial<TMapLayersLocalState>) => {
        setMapLayersState(prevState => ({ ...prevState, ...value }));
    }, []);

    const {
        values,
        onChange,
        resetToDefault: resetOsmLayersPickerToDefault,
    } = useOsmCheckboxValues({
        options,
        disabledOptions,
        isNPAnalysis,
        mapLayersLocalState,
        updateMapLayersState,
    });

    const handleReset = () => {
        dispatch(appSetEnhancedLayers([]));
        resetOsmLayersPickerToDefault();
    };

    const _displayConfig = useMemo(() => {
        return { ...DEFAULT_DISPLAY_CONFIG, ...displayConfig };
    }, [displayConfig]);

    if (Object.values(_displayConfig).every(value => !value)) return null;

    return (
        <div className="stl-map-layers-picker">
            <label className="picker-label primary">Map Layers</label>
            {_displayConfig.showOsm && (
                <>
                    <label className="picker-label secondary" htmlFor="osm-layers-select">
                        OSM Layers
                    </label>
                    <MapOSMLayersPicker values={values} onChange={onChange} />
                </>
            )}
            {_displayConfig.showDac && (
                <MapDACLayersPicker
                    mapLayersLocalState={mapLayersLocalState}
                    updateMapLayersState={updateMapLayersState}
                />
            )}
            <div className="reset-button" role="none" onClick={handleReset}>
                Reset to Default
            </div>
            <MapLayers
                map={map}
                config={mapLayersConfig}
                displayConfig={_displayConfig}
                mapLayersLocalState={mapLayersLocalState}
            />
        </div>
    );
};
