import type { Map } from "mapbox-gl";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { EnhancedLayers } from "@common/components/baseMap/mapLayers/enhanced/enhancedLayers";
import { TMapLayersLocalState } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { OsmLayers } from "@common/components/baseMap/mapLayers/osm/osmLayers";

export interface IMapLayersPickerDisplayConfig {
    showOsm?: boolean;
    showDac?: boolean;
}

type TProps = {
    map: Map | null;
    config?: TControlConfig["mapLayersConfig"];
    displayConfig: IMapLayersPickerDisplayConfig;
    mapLayersLocalState?: TMapLayersLocalState | null;
};

export const MapLayers = ({ map, config, displayConfig, mapLayersLocalState }: TProps) => (
    <>
        {displayConfig.showOsm && (
            <OsmLayers map={map} mapLayersLocalState={mapLayersLocalState} />
        )}
        {displayConfig.showDac && (
            <EnhancedLayers map={map} config={config} mapLayersLocalState={mapLayersLocalState} />
        )}
    </>
);
