const ACTION_PREFIX = "CHOOSE_ZONES";

export const CHOOSE_ZONES_ACTION_TYPES = {
    SET_ZONE_ROLE: `${ACTION_PREFIX}/SET_ZONE_ROLE`,
    SET_ZONE_TYPE: `${ACTION_PREFIX}/SET_ZONE_TYPE`,
    SET_LOCATION: `${ACTION_PREFIX}/SET_LOCATION`,
    SET_ROADS: `${ACTION_PREFIX}/SET_ROADS`,
    UPDATE_SELECTED_ZONES: `${ACTION_PREFIX}/UPDATE_SELECTED_ZONES`,
    SET_SELECTED_ZONES: `${ACTION_PREFIX}/SET_SELECTED_ZONES`,
    SET_HOVERED_ZONE: `${ACTION_PREFIX}/SET_HOVERED_ZONE`,
    SET_HOVERED_BUS_ZONES: `${ACTION_PREFIX}/SET_HOVERED_BUS_ZONES`,
    SET_MAP_ERROR: `${ACTION_PREFIX}/SET_MAP_ERROR`,
    SET_WIZARD_MODAL_STATE: `${ACTION_PREFIX}/SET_WIZARD_MODAL_STATE`,
    UPDATE_UI_STATES: `${ACTION_PREFIX}/UPDATE_UI_STATES`,
    SET_SHOULD_CREATE_ZONE_SET: `${ACTION_PREFIX}/SET_SHOULD_CREATE_ZONE_SET`,
    SET_INITIAL_DATA: `${ACTION_PREFIX}/SET_INITIAL_DATA`,
    RESET_SELECTED_ZONES: `${ACTION_PREFIX}/RESET_SELECTED_ZONES`,
    RESET_STATE: `${ACTION_PREFIX}/RESET_STATE`,
    SET_INTERSECTION_ZONE: `${ACTION_PREFIX}/SET_INTERSECTION_ZONE`,
    SET_EDITABLE_FEATURE: `${ACTION_PREFIX}/SET_EDITABLE_FEATURE`,
    SET_MAP_MODE: `${ACTION_PREFIX}/SET_MAP_MODE`,
    SET_SELECTED_GATE: `${ACTION_PREFIX}/SET_SELECTED_GATE`,
    SET_IPF_CALIBRATION: `${ACTION_PREFIX}/SET_IPF_CALIBRATION`,
    SET_VALIDATION: `${ACTION_PREFIX}/SET_VALIDATION`,
    SET_SHOW_ZONES_BY_ROLE: `${ACTION_PREFIX}/SET_SHOW_ZONES_BY_ROLE`,
    SET_LOADING_ZONE_TABLE: `${ACTION_PREFIX}/SET_LOADING_ZONE_TABLE`,
    SET_HOVERED_DAC_ZONE: `${ACTION_PREFIX}/SET_HOVERED_DAC_ZONE`,
};
