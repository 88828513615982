import { useCallback, useMemo } from "react";
import type { EventData, Map } from "mapbox-gl";
import { getSelectedEnhancedDataLayers } from "@app/store/currentUser/currentUser.selector";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { useHover } from "@common/components/baseMap";
import { _createHighlightZone } from "@common/components/baseMap/baseMap.helpers";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import {
    ENHANCED_LAYERS,
    ENHANCED_LAYERS_LIST,
    TMapLayersLocalState,
} from "../mapLayers.constants";
import { useDACZoneLayers } from "./useDACZoneLayers";

export const EnhancedLayers = ({
    map,
    config,
    mapLayersLocalState,
}: {
    map: Map | null;
    config?: TControlConfig["mapLayersConfig"];
    mapLayersLocalState?: Pick<TMapLayersLocalState, "enhancedLayers"> | null;
}) => {
    const persistedEnhancedDataLayers = useAppSelector(getSelectedEnhancedDataLayers);

    const dispatch = useAppDispatch();

    const selectedEnhancedDataLayers = useMemo(() => {
        return mapLayersLocalState
            ? ENHANCED_LAYERS_LIST.filter(layer =>
                  mapLayersLocalState.enhancedLayers.includes(layer.code),
              )
            : persistedEnhancedDataLayers;
    }, [mapLayersLocalState, persistedEnhancedDataLayers]);

    const _showDacLayers = useMemo(
        () =>
            selectedEnhancedDataLayers.some(
                layer => layer.code === ENHANCED_LAYERS.DAC_ZONES.code,
            ),
        [selectedEnhancedDataLayers],
    );

    const layers = useDACZoneLayers({ map: map, show: _showDacLayers });

    const handleHover = useCallback(
        (e: EventData) => {
            const feature = e.features?.[0]?.properties || null;
            dispatch(config?.setHoveredDACZone!(feature));
        },
        [dispatch, config],
    );

    const createHighlightZone = useCallback(() => _createHighlightZone(), []);

    useHover(map, {
        layers: layers,
        onHover: handleHover,
        featureIdProperty: "FID",
        disableAnimatedHover: true,
        disableCursorChanging: true,
        createHighlightZone,
    });

    return null;
};
