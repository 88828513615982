import { ReactNode, useState, useMemo } from "react";
import {
    StlLightbox,
    StlButton,
    StlRadioGroup,
    StlToggleButtonGroup,
    StlToggleButton,
} from "@common/components";
import {
    BASE_MAP_STYLES_LIST,
    MAP_STYLE_PROVIDERS,
    MAP_STYLE_PROVIDERS_VALUES,
} from "@common/components/baseMap/baseMap.constants";
import { EMapStyleIds, TMapStyleProvider } from "@common/components/baseMap/baseMap.types";
import { getBaseMapStyleProvider } from "@common/components/baseMap/baseMap.helpers";
import "./baseMapConfigurationModal.less";

const BASE_MAP_STYLES_OPTIONS = BASE_MAP_STYLES_LIST.map(travelMode => ({
    ...travelMode,
    LabelComponent: (
        <>
            {travelMode.display}
            <img
                className="option-map-style-img"
                src={travelMode.imagePath}
                alt={travelMode.display}
                aria-hidden="true"
            />
        </>
    ),
}));

type THeaderProps = {
    title: string;
    mapStyleProvider: TMapStyleProvider;
    onMapStyleProviderChange: (v: TMapStyleProvider) => void;
};

const BaseMapConfigurationHeader = ({
    title,
    mapStyleProvider,
    onMapStyleProviderChange,
}: THeaderProps) => (
    <div className="stl-base-map-configuration-modal-header">
        <div className="title">{title}</div>
        <StlToggleButtonGroup
            value={mapStyleProvider}
            size="md"
            onChange={(_, v) => onMapStyleProviderChange(v as TMapStyleProvider)}
            className="map-style-provider-toggle"
        >
            {MAP_STYLE_PROVIDERS_VALUES.map(provider => (
                <StlToggleButton
                    key={provider.id}
                    id={provider.id}
                    aria-label={`Show ${provider.name}`}
                >
                    {provider.name}
                </StlToggleButton>
            ))}
        </StlToggleButtonGroup>
    </div>
);

type TProps = {
    show: boolean;
    title: string;
    onHide: () => void;
    style: EMapStyleIds;
    container?: HTMLElement | null;
    onStyleChange: (value: EMapStyleIds) => void;
};

export const BaseMapConfigurationModal = ({ style, container, ...props }: TProps) => {
    const [mapStyleProvider, setMapStyleProvider] = useState(() => getBaseMapStyleProvider(style));
    const [selectedBaseMapStyle, setSelectedBaseMapStyle] = useState(style);

    const mapStyleOptions = useMemo(
        () =>
            BASE_MAP_STYLES_OPTIONS.filter(({ styleId }) =>
                MAP_STYLE_PROVIDERS[mapStyleProvider].styles.includes(styleId),
            ),
        [mapStyleProvider],
    );

    const handleDonePress = () => {
        if (selectedBaseMapStyle !== style) {
            props.onStyleChange(selectedBaseMapStyle);
        }

        props.onHide();
    };

    const handleCancelPress = () => {
        setSelectedBaseMapStyle(style);
        props.onHide();
    };

    return (
        <StlLightbox
            className="stl-base-map-configuration-modal"
            show={props.show}
            title={
                <BaseMapConfigurationHeader
                    title={props.title}
                    mapStyleProvider={mapStyleProvider}
                    onMapStyleProviderChange={setMapStyleProvider}
                />
            }
            onHide={props.onHide}
            centered
            container={container}
            body={
                <StlRadioGroup
                    value={selectedBaseMapStyle}
                    options={mapStyleOptions}
                    onChange={setSelectedBaseMapStyle}
                    getOptionLabel={option => option.LabelComponent as ReactNode}
                    getOptionValue={option => option.styleId as string}
                />
            }
            footer={
                <>
                    <StlButton variant="secondary" onClick={handleCancelPress}>
                        Cancel
                    </StlButton>
                    <StlButton variant="primary" onClick={handleDonePress}>
                        Done
                    </StlButton>
                </>
            }
        />
    );
};
