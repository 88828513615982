import { createSelector } from "reselect";
import { getZonesByZoneKind } from "@common/features/zones/zones.helpers";

export const getZoneSetModalState = state => state.zoneSetModal;

export const getZoneSetMap = state => getZoneSetModalState(state).map;
export const getUiStates = state => getZoneSetModalState(state).uiStates;
export const getZoneSet = state => getZoneSetModalState(state).zoneSet;
export const getZoneSetName = state => getZoneSetModalState(state).zoneSetName;
export const getHoveredZone = state => getZoneSetMap(state).hoveredZone;
export const getSelectedZoneIds = state => getZoneSetModalState(state).selectedZoneIds;
export const getMapError = state => getZoneSetMap(state).mapError;
export const getOtherZoneSet = state => getZoneSetModalState(state).otherZoneSet;

export const getShouldPreserveHoveredZone = createSelector(
    getHoveredZone,
    hoveredZone => hoveredZone?.shouldPreserve,
);

export const getBoundingBoxZones = createSelector(
    getZoneSet,
    getOtherZoneSet,
    (zoneSet, otherZoneSet) => {
        const currentZoneSetZones = zoneSet.zones || [];
        const otherZoneSetZones = otherZoneSet?.zones || [];

        return getZonesByZoneKind([...currentZoneSetZones, ...otherZoneSetZones]);
    },
);

export const hasZoneSetPendingChanges = createSelector(
    getZoneSet,
    getZoneSetName,
    (zoneSet, zoneSetName) => {
        return zoneSetName.isEditMode && zoneSetName.value !== zoneSet.set_name;
    },
);
