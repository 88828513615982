import { v4 as uuidv4 } from "uuid";
import type { IIntersection } from "@common/features/intersections/intersection.types";
import { IAnalysisIntersectionZone } from "@common/services/server/analysesApi.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITMCChooseZonesState, TMC_CHOOSE_ZONES_INITIAL_STATE } from "./tmcChooseZones.state";

export const { actions, reducer } = createSlice({
    name: "tmcChooseZones",
    initialState: TMC_CHOOSE_ZONES_INITIAL_STATE,
    reducers: {
        setTMCChooseZonesState: (state, action: PayloadAction<ITMCChooseZonesState>) => {
            return action.payload;
        },
        resetTMCChooseZonesState: () => {
            return TMC_CHOOSE_ZONES_INITIAL_STATE;
        },
        updateAvailableIntersections: (
            state,
            action: PayloadAction<ITMCChooseZonesState["availableIntersections"]>,
        ) => {
            state.availableIntersections = action.payload;
        },
        setInitialIntersectionZones: (
            state,
            action: PayloadAction<
                Pick<ITMCChooseZonesState, "intersectionZones" | "intersectionZoneIdsList">
            >,
        ) => {
            state.intersectionZones = action.payload.intersectionZones;
            state.intersectionZoneIdsList = action.payload.intersectionZoneIdsList;
        },
        setIntersectionZones: (
            state,
            action: PayloadAction<ITMCChooseZonesState["intersectionZones"]>,
        ) => {
            state.intersectionZones = action.payload;
        },
        setSelectedIntersectionZoneId: (
            state,
            action: PayloadAction<ITMCChooseZonesState["selectedIntersectionZoneId"]>,
        ) => {
            state.selectedIntersectionZoneId = action.payload;
        },
        setSelectedGateId: (
            state,
            action: PayloadAction<ITMCChooseZonesState["selectedIntersectionZoneId"]>,
        ) => {
            state.selectedGateId = action.payload;
        },
        updateIntersectionZone: (state, action: PayloadAction<IIntersection>) => {
            state.intersectionZones[action.payload.id] = action.payload;
        },
        addIntersectionZone: (
            state,
            action: PayloadAction<{
                newIntersection: IIntersection;
                maxIntersectionCount: number;
            }>,
        ) => {
            if (state.intersectionZoneIdsList.length >= action.payload.maxIntersectionCount) {
                return;
            }

            const newIntersection = action.payload.newIntersection;
            const id = newIntersection.id;

            state.intersectionZoneIdsList.push(id);
            state.intersectionZones[id] = newIntersection;
            state.selectedGateId = newIntersection.gates[0].id;
        },
        deleteIntersection: (state, action: PayloadAction<IIntersection["id"]>) => {
            if (state.intersectionZoneIdsList.length <= 1) {
                return;
            }

            const intersectionId = action.payload;

            if (state.intersectionZones.hasOwnProperty(intersectionId)) {
                delete state.intersectionZones[intersectionId];
                state.intersectionZoneIdsList = state.intersectionZoneIdsList.filter(
                    id => id !== intersectionId,
                );
            }

            state.selectedGateId = null;
            state.selectedIntersectionZoneId = null;
        },
        reuseIntersections: (
            state,
            action: PayloadAction<{
                selectedIntersections: IAnalysisIntersectionZone[];
                intersectionToReplace: IIntersection;
            }>,
        ) => {
            const { selectedIntersections, intersectionToReplace } = action.payload;

            const newZoneIds = selectedIntersections.map(zone => zone.zone_uuid);
            const selectedIntersectionsMap: { [id: string]: IIntersection } = Object.fromEntries(
                selectedIntersections.map(zone => [
                    zone.zone_uuid,
                    {
                        ...zone,
                        id: zone.zone_uuid,
                        gates: zone.gates.map(gate => ({
                            ...gate,
                            oneWay: !!gate.direction,
                            id: uuidv4(),
                        })),
                    },
                ]),
            );

            state.intersectionZones = {
                ...state.intersectionZones,
                ...selectedIntersectionsMap,
            };

            state.intersectionZoneIdsList = state.intersectionZoneIdsList.flatMap(
                intersectionId => {
                    if (intersectionId === intersectionToReplace.id) return newZoneIds;

                    return intersectionId;
                },
            );
        },
        setEditableFeature: (
            state,
            action: PayloadAction<ITMCChooseZonesState["editableFeature"]>,
        ) => {
            state.editableFeature = action.payload;
        },
        setMapMode: (state, action: PayloadAction<ITMCChooseZonesState["mapMode"]>) => {
            state.mapMode = action.payload;
        },
        setHoveredGate: (state, action: PayloadAction<ITMCChooseZonesState["hoveredGate"]>) => {
            state.hoveredGate = action.payload;
        },
        setHoveredIntersectionZoneId: (
            state,
            action: PayloadAction<ITMCChooseZonesState["hoveredIntersectionZoneId"]>,
        ) => {
            state.hoveredIntersectionZoneId = action.payload;
        },
    },
});

export const tmcChooseZonesReducer = reducer;
