const ACTION_PREFIX = "userPreferences";

export const USER_PREFERENCES_ACTIONS = {
    INITIALIZE_USER_PREFERENCES: `${ACTION_PREFIX}/INITIALIZE_USER_PREFERENCES`,
    APP_OSM_LAYERS_CATEGORIES: `${ACTION_PREFIX}/APP_OSM_LAYERS_CATEGORIES`,
    APP_ENHANCED_LAYERS: `${ACTION_PREFIX}/APP_ENHANCED_LAYERS`,
    SET_AUTO_REFRESH: `${ACTION_PREFIX}/SET_AUTO_REFRESH`,
    SET_AUTO_APPLY_FILTER: `${ACTION_PREFIX}/SET_AUTO_APPLY_FILTER`,
    SET_AUTO_DISMISS_MESSAGES: `${ACTION_PREFIX}/SET_AUTO_DISMISS_MESSAGES`,
    SET_EMAIL_NOTIFICATION_SETTINGS: `${ACTION_PREFIX}/SET_EMAIL_NOTIFICATION_SETTINGS`,
    SET_VECTOR_MAP_SETTINGS: `${ACTION_PREFIX}/SET_VECTOR_MAP_SETTINGS`,
    SET_PAGINATION: `${ACTION_PREFIX}/SET_PAGINATION`,
    SET_FILTERED: `${ACTION_PREFIX}/SET_FILTERED`,
    SET_SORTING: `${ACTION_PREFIX}/SET_SORTING`,
    SET_COLUMN_VISIBILITY: `${ACTION_PREFIX}/SET_COLUMN_VISIBILITY`,
    SET_THEME: `${ACTION_PREFIX}/SET_THEME`,
    CHANGE_THEME: `${ACTION_PREFIX}/CHANGE_THEME`,
    SET_MAP_STYLE: `${ACTION_PREFIX}/SET_MAP_STYLE`,
    SET_COUNTRY: `${ACTION_PREFIX}/SET_COUNTRY`,
    SET_WEEKDAY_TYPE: `${ACTION_PREFIX}/SET_WEEKDAY_TYPE`,
    SET_NAV_OPEN: `${ACTION_PREFIX}/SET_NAV_OPEN`,
    PATCH_USER_PREFERENCES: `${ACTION_PREFIX}/PATCH_USER_PREFERENCES`,
    PATCH_SESSION_USER_PREFERENCES: `${ACTION_PREFIX}/PATCH_SESSION_USER_PREFERENCES`,
};

export const USER_PREFERENCES_ACTIONS_TO_SAVE = [
    USER_PREFERENCES_ACTIONS.APP_OSM_LAYERS_CATEGORIES,
    USER_PREFERENCES_ACTIONS.APP_ENHANCED_LAYERS,
    USER_PREFERENCES_ACTIONS.SET_AUTO_REFRESH,
    USER_PREFERENCES_ACTIONS.SET_AUTO_APPLY_FILTER,
    USER_PREFERENCES_ACTIONS.SET_AUTO_DISMISS_MESSAGES,
    USER_PREFERENCES_ACTIONS.SET_EMAIL_NOTIFICATION_SETTINGS,
    USER_PREFERENCES_ACTIONS.SET_VECTOR_MAP_SETTINGS,
    USER_PREFERENCES_ACTIONS.SET_PAGINATION,
    USER_PREFERENCES_ACTIONS.SET_FILTERED,
    USER_PREFERENCES_ACTIONS.SET_SORTING,
    USER_PREFERENCES_ACTIONS.SET_COLUMN_VISIBILITY,
    USER_PREFERENCES_ACTIONS.SET_THEME,
    USER_PREFERENCES_ACTIONS.CHANGE_THEME,
    USER_PREFERENCES_ACTIONS.SET_MAP_STYLE,
    USER_PREFERENCES_ACTIONS.SET_COUNTRY,
    USER_PREFERENCES_ACTIONS.SET_NAV_OPEN,
    USER_PREFERENCES_ACTIONS.PATCH_USER_PREFERENCES,
];
