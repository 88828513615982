import {
    getBasics,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/basics/state/basics.selectors";
import { TRAVEL_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import { getGeneral } from "@app/analysis/state/general/general.selectors";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import {
    ROAD_TYPES,
    ZONE_TYPES,
    ZONE_TYPES_LIST,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { createSelector } from "reselect";

import {
    ANALYSIS_TYPES_AVAILABLE_ZONE_TYPES,
    SELECTED_ZONES_ROLES,
} from "./chooseZones.constants";
import { getZoneIdsByZoneKind } from "./chooseZones.helpers";

/*
    Getter of the main object, just for the sake of convenience.
*/
export const getBaseChooseZones = state => state.analysisConfiguration.chooseZones;

/*
    Getters sub-objects of the main one. Just for the sake of convenience.
*/
export const getZoneRole = state => getBaseChooseZones(state).zoneRole;
export const getZoneType = state => getBaseChooseZones(state).zoneType;
export const getLocation = state => getBaseChooseZones(state).location;
export const getRoads = state => getBaseChooseZones(state).roads;
export const getSelectedZones = state => getBaseChooseZones(state).selectedZones;
export const getQuestionableZoneIds = state => getBaseChooseZones(state).questionableZoneIds;
export const getZoneVintage = state => getBaseChooseZones(state).zoneVintage;
export const getMapError = state => getBaseChooseZones(state).mapError;
export const getIsWizardModalOpen = state => getBaseChooseZones(state).isWizardModalOpen;
export const getUiStates = state => getBaseChooseZones(state).uiStates;
export const getShouldCreateZoneSet = state => getBaseChooseZones(state).shouldCreateZoneSet;
export const getZonesValidation = state => getBaseChooseZones(state).validation;
export const getIPFCalibrationSettings = state => getBaseChooseZones(state).ipfCalibrationSettings;
export const getHoveredBusZones = state => getBaseChooseZones(state).hoveredBusZones;

/*
    These getters should be private (used only in this file) and are necessary only for proper
    createSelector` memoization. If you want to get those properties, please use parent object.
 */
const getAnalysisTypeCode = state => getGeneral(state).analysisTypeCode;
const getTravelModeCode = state => getBasics(state).travelModeCode;
const _getHoveredZone = state => getBaseChooseZones(state).hoveredZone;

/*
    Other getters which are used in different places.
    Please create new ones with caution. We definitely don't want this file become the place
    where functions used only once and/or don't serve as helpers.
*/

export const getShouldShowZonesByRole = state => getUiStates(state).showZonesByRole;

export const getSelectedZonesFilteredByRole = createSelector(
    getSelectedZones,
    getShouldShowZonesByRole,
    (selectedZones, showZonesByRole) => {
        return SELECTED_ZONES_ROLES.reduce((filteredSelectedZones, zoneRole) => {
            if (showZonesByRole[zoneRole]) {
                return {
                    ...filteredSelectedZones,
                    [zoneRole]: selectedZones[zoneRole],
                };
            }

            return { ...filteredSelectedZones };
        }, {});
    },
);

export const getHoveredZone = createSelector(
    _getHoveredZone,
    getSelectedZones,
    (hoveredZone, selectedZones) => {
        if (!hoveredZone) return null;

        const allZones = Object.values(selectedZones).flat();
        const foundZone = allZones.find(
            zone => String(zone.zone_id) === String(hoveredZone.zone_id),
        );

        return { ...hoveredZone, ...foundZone };
    },
);

export const getShouldPreserveHoveredZone = createSelector(
    getHoveredZone,
    hoveredZone => hoveredZone?.shouldPreserve,
);

export const getAvailableZoneTypesList = createSelector(
    getAnalysisTypeCode,
    getTravelModeCode,
    state => getIsUSTruckVolumeAnalysis(state),
    (analysisTypeCode, travelModeCode, isUSTruckVolumeAnalysis) => {
        const isBusRailTravelMode =
            travelModeCode === TRAVEL_MODES.BUS.code || travelModeCode === TRAVEL_MODES.RAIL.code;

        if (isBusRailTravelMode) {
            return ZONE_TYPES_LIST.filter(type => type.id !== ZONE_TYPES.OSM_LINE_SEGMENTS.id);
        }
        if (isUSTruckVolumeAnalysis) {
            return ZONE_TYPES_LIST.filter(type => type.id === ZONE_TYPES.OSM_LINE_SEGMENTS.id);
        }

        return ANALYSIS_TYPES_AVAILABLE_ZONE_TYPES[analysisTypeCode] || [];
    },
);

export const getIsOSMLineSegmentsAnalysis = createSelector(
    getAvailableZoneTypesList,
    availableZoneTypesList =>
        availableZoneTypesList.length === 1 &&
        availableZoneTypesList[0].id === ZONE_TYPES.OSM_LINE_SEGMENTS.id,
);

/*
    re-select selectors.
    Implements memoization. Use them in case of some calculations you need to perform together
    with store selectors.
*/
export const getSelectedZoneIds = createSelector(getSelectedZones, selectedZones => {
    return Object.entries(selectedZones).reduce((selectedZonesMap, [role, zones]) => {
        selectedZonesMap[role] = zones.map(zone => zone.zone_id);
        return selectedZonesMap;
    }, {});
});

export const getSelectedZoneIdsFilteredByRole = createSelector(
    getSelectedZonesFilteredByRole,
    selectedZonesFilteredByRole => {
        return Object.entries(selectedZonesFilteredByRole).reduce(
            (selectedZonesMap, [role, zones]) => {
                selectedZonesMap[role] = zones.map(zone => zone.zone_id);
                return selectedZonesMap;
            },
            {},
        );
    },
);

export const getSelectedZonesForLayerGroupRequest = createSelector(
    getSelectedZones,
    selectedZones => {
        const zonesGroupedByZoneKinds = Object.values(selectedZones).reduce((map, zones) => {
            zones.forEach(zone => {
                if (!map[zone.zone_kind_id]) map[zone.zone_kind_id] = [];

                map[zone.zone_kind_id].push(zone.zone_id);
            });

            return map;
        }, {});

        return Object.entries(zonesGroupedByZoneKinds).map(([zoneKindId, zoneIds]) => ({
            zone_kind_id: zoneKindId,
            zone_ids: zoneIds,
        }));
    },
);

// Creates an array of selected zones in specified by BE format.
export const getApiSelectedZones = createSelector(getSelectedZones, selectedZones => {
    const zoneIdsMap = Object.keys(selectedZones).reduce((result, zoneRole) => {
        const hashedZones = selectedZones[zoneRole].reduce((result2, zone) => {
            const zone_id = zone.zone_id;
            const zoneIdObj = result2[zone_id] || {};

            return {
                ...result2,
                [zone_id]: {
                    ...zoneIdObj,
                    zoneKindId: zone.zone_kind_id,
                    zoneRoles: {
                        ...zoneIdObj.zoneRoles,
                        [zoneRole]: true,
                    },
                },
            };
        }, result);

        return { ...result, ...hashedZones };
    }, {});

    return Object.keys(zoneIdsMap).reduce((result, key) => {
        const zoneObj = zoneIdsMap[key];
        const zoneKindId = zoneObj.zoneKindId;
        const zoneKindData = result[zoneKindId] || [];

        zoneKindData.push({
            zone_id: key,
            zone_roles: zoneObj.zoneRoles,
            ...(zoneObj.directions && { directions: zoneObj.directions }),
        });

        return {
            ...result,
            [zoneKindId]: zoneKindData,
        };
    }, {});
});

export const getSelectedZoneIdsByZoneKind = createSelector(getSelectedZones, selectedZones =>
    getZoneIdsByZoneKind(selectedZones),
);

export const getInvalidZoneIds = createSelector(getSelectedZones, selectedZones => {
    return Object.values(selectedZones).reduce((result, zones) => {
        zones.forEach(zone => {
            if (zone.error_type) result.push(String(zone.zone_id));
        });

        return result;
    }, []);
});

// AADT 2023 allows:
// - custom line zones
// - OSM segments of Residential/Tertiary split types which are not "service" roads
export const getValidAADT2023Zones = createSelector(getSelectedZones, selectedZones => {
    const validZones = selectedZones.oz.filter(zone => {
        if (ZONE_KINDS.USER.id.includes(zone.zone_kind_id)) {
            return [ZONE_GEOM_TYPES.LINE.id, "L"].includes(zone.geom_type);
        }
        if (ZONE_KINDS.OSM.id.includes(zone.zone_kind_id)) {
            return !ROAD_TYPES.SERVICE.roadIds.includes(zone.highway);
        }
        return false;
    });
    return { validZones, areAllZonesValid: validZones.length === selectedZones.oz.length };
});
