import {
    BASE_VIZ_DEFAULTS,
    VIZ_MAP_LAYERS,
    VIZ_ZONE_TYPES,
} from "@app/viz3/base/state/baseViz.constants";
import {
    METRIC_TYPES,
    SEGMENT_DISTRIBUTION_METRIC_KEY,
} from "@app/viz3/segmentVisualization/widgets/segmentDistribution/segmentDistribution.constants";
import {
    SEGMENT_TIME_DISTRIBUTION_METRIC_KEY,
    TIME_DISTRIBUTION_METRIC_TYPES,
} from "@app/viz3/segmentVisualization/widgets/timeDistribution/segmentTimeDistribution.constants";

export const SEGMENT_ZONE_TYPE = {
    ...VIZ_ZONE_TYPES.ZONE,
    displayName: "Segments",
} as const;

export const SEGMENT_ZONE_TYPES_LIST = [SEGMENT_ZONE_TYPE];

export type TSegmentZoneTypesList = typeof SEGMENT_ZONE_TYPES_LIST;

export const SEGMENT_LAYERS = {
    SEGMENT: {
        code: "segment",
        label: "Segments",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export type TSegmentLayerType = keyof typeof SEGMENT_LAYERS;
export type TSegmentLayer = typeof SEGMENT_LAYERS[keyof typeof SEGMENT_LAYERS];

export const VISUALIZATION_PROPERTIES = {
    TRAFFIC: {
        code: "traffic",
        label: "Traffic",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.traffic,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: TIME_DISTRIBUTION_METRIC_TYPES.vmt,
    },
    CONGESTION: {
        code: "congestion",
        label: "Congestion",
        unit: "Percent",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.congestion,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    AVG_SPEED: {
        code: "avg_speed",
        label: "Average Speed",
        units: { MPH: "Mph", KPH: "Kph" },
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.speed,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: TIME_DISTRIBUTION_METRIC_TYPES.speed,
    },
    AVG_DURATION: {
        code: "avg_duration",
        label: "Average Travel Time",
        unit: "Seconds",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.travel_time,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    VHD: {
        code: "vhd",
        label: "VHD",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: {},
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: TIME_DISTRIBUTION_METRIC_TYPES.vhd,
    },
    VOLUME: {
        code: "volume",
        label: "Average Volume",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: {},
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: TIME_DISTRIBUTION_METRIC_TYPES.volume,
    },
};
export const VISUALIZATION_PROPERTIES_LIST = Object.values(VISUALIZATION_PROPERTIES);
export type TVisualizationPropertyType = keyof typeof VISUALIZATION_PROPERTIES;
export type TVisualizationProperty =
    typeof VISUALIZATION_PROPERTIES[keyof typeof VISUALIZATION_PROPERTIES];

// Network Performance Segment Visualization Properties
export const NP_SEGMENT_VISUALIZATION_PROPERTIES = {
    TRAFFIC: { ...VISUALIZATION_PROPERTIES.TRAFFIC },
    CONGESTION: { ...VISUALIZATION_PROPERTIES.CONGESTION },
    AVG_SPEED: { ...VISUALIZATION_PROPERTIES.AVG_SPEED },
    AVG_DURATION: { ...VISUALIZATION_PROPERTIES.AVG_DURATION },
    VHD: { ...VISUALIZATION_PROPERTIES.VHD },
    SPEED_PERCENTILE: {
        code: "speed_percentile",
        label: "Speed Percentile",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.speed,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
};
export const NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST = Object.values(
    NP_SEGMENT_VISUALIZATION_PROPERTIES,
);

// Time distribution chart won't be shown if one of properties is selected
export const SPECIFIC_SEGMENT_VISUALIZATION_PROPERTIES_LIST = VISUALIZATION_PROPERTIES_LIST.filter(
    property => property.code !== VISUALIZATION_PROPERTIES.TRAFFIC.code,
);

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [SEGMENT_LAYERS.SEGMENT.code]: true,
    [SEGMENT_LAYERS.BASE.code]: true,
    [SEGMENT_LAYERS.UNSELECTED.code]: false,
    [SEGMENT_LAYERS.LABEL.code]: true,
    [SEGMENT_LAYERS.LEGEND.code]: true,
} as const;

export const SEGMENT_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        visualizationProperty: VISUALIZATION_PROPERTIES.TRAFFIC,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};

export const TRAFFIC_BEHAVIORS = {
    zoneType: VIZ_ZONE_TYPES.ZONE,
} as const;

export type TTrafficBehaviorType = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const NETWORK_PERFORMANCE_DISTRIBUTION_CHART_TYPE = "segmentDistributionMetric";
