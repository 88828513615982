const ACTION_PREFIX = "ZONE_SET_MODAL";

export const ZONE_SET_MODAL_ACTIONS = {
    SET_ZONE_SET_MODAL_OPEN: `${ACTION_PREFIX}/SET_ZONE_SET_MODAL_OPEN`,
    SET_UPLOAD_ZONE_SET_MODAL_OPEN: `${ACTION_PREFIX}/SET_UPLOAD_ZONE_SET_MODAL_OPEN`,
    SET_LINKED_ZONE_SET_MODAL_OPEN: `${ACTION_PREFIX}/SET_LINKED_ZONE_SET_MODAL_OPEN`,
    SET_SCREEN_MODE: `${ACTION_PREFIX}/SET_SCREEN_MODE`,
    SET_VIEW_MODE: `${ACTION_PREFIX}/SET_VIEW_MODE`,
    UPDATE_ZONE_SET: `${ACTION_PREFIX}/UPDATE_ZONE_SET`,
    RESET_ZONE_SET_MODAL_STATE: `${ACTION_PREFIX}/RESET_ZONE_SET_MODAL_STATE`,
    SET_IS_LOADING: `${ACTION_PREFIX}/SET_IS_LOADING`,
    SET_ZONE_SET_NAME: `${ACTION_PREFIX}/SET_ZONE_SET_NAME`,
    SET_SELECTED_ZONE_SET_ID: `${ACTION_PREFIX}/SET_SELECTED_ZONE_SET_ID`,
    SET_HOVERED_ZONE: `${ACTION_PREFIX}/SET_HOVERED_ZONE`,
    SET_HOVERED_BUS_ZONES: `${ACTION_PREFIX}/SET_HOVERED_BUS_ZONES`,
    SET_HOVERED_DAC_ZONE: `${ACTION_PREFIX}/SET_HOVERED_DAC_ZONE`,
    SET_MAP_ERROR: `${ACTION_PREFIX}/SET_MAP_ERROR`,
    SET_SELECTED_ZONE_IDS: `${ACTION_PREFIX}/SET_SELECTED_ZONE_IDS`,
    SET_UI_STATE: `${ACTION_PREFIX}/SET_UI_STATE`,
};
