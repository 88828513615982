import type { Feature } from "geojson";
import type { ICalibrationDetails } from "@common/features/zonesManager/zonesManager.types";
import { EDefaultColors } from "@common/constants/color.constants";

export interface IZone<WithCalibration extends boolean = false> {
    area: number;
    customer_zone_id?: number | null;
    calibration_detail: WithCalibration extends true ? ICalibrationDetails : undefined;
    direction: number | null | undefined;
    display_zone_kind_id?: number;
    geom_type: string;
    geometry: string;
    line_geometry?: string;
    start_geometry?: string;
    end_geometry?: string;
    is_bidi?: boolean;
    is_pass: boolean;
    is_immutable?: boolean;
    is_questionable: boolean;
    is_simplified: boolean;
    point_on_surface: string;
    road_type: string | null;
    sa_version_id: number | null;
    status: string;
    within_core_region: boolean | null;
    within_per_zone_area: boolean | null;
    within_region: boolean | null;
    within_study_core_region: boolean | null;
    within_study_region: boolean | null;
    with_calibration?: WithCalibration;
    zone_id: number;
    zone_kind_id: number;
    zone_name: string;
    feature?: Feature;
    highway?: "motorway" | "trunk" | "primary" | "secondary" | "tertiary" | "residential";
}

export interface IZoneSet {
    calibration_zone_count: number;
    can_delete: boolean;
    created_by: string;
    created_date: string;
    geom_type: string;
    is_immutable: boolean | null;
    is_questionable: boolean;
    linked_zone_sets?: ILinkedZoneSet[];
    outside_usca: boolean;
    sa_version_id: number;
    set_id: number;
    set_name: string;
    status: string;
    touches_ca: boolean;
    touches_us: boolean;
    updated_by: string;
    updated_date: string;
    with_all_direction: boolean;
    with_calibration: boolean;
    with_pass_through: boolean;
    within_core_region: boolean;
    within_per_zone_area: boolean;
    within_region: boolean;
    within_study_core_region: boolean | null;
    within_study_region: boolean | null;
    zone_count: number;
    zone_set_color?: EDefaultColors;
    zones?: IZone[];
}

export interface ILinkedZoneSet {
    set_id: number;
    set_name: string;
    zone_set_color: EDefaultColors;
}

export enum ZoneRoles {
    oz = "oz",
    dz = "dz",
    mfz = "mfz",
    tfz = "tfz",
    cz = "cz",
    az = "az",
}

export type THoveredZone = Partial<IZone> & {
    [key: string]: unknown;
};

export type TSelectedZone = Partial<IZone> & {
    [key: string]: unknown;
};
