import { ReactNode } from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { ConfirmationDialog } from "@common/components/dialogs/confirmationDialog";
import { NotificationDialog } from "@common/components/dialogs/notificationDialog";
import type { DialogProps } from "@material-ui/core/Dialog/Dialog";

type THandlerHide = () => void;

type TCommonConfig = Omit<DialogProps, "open"> & {
    show?: boolean;
    okText?: ReactNode;
    footer?: ReactNode;
    content?: ReactNode;
    closeText?: ReactNode;
    titleContent?: ReactNode;
    hideCloseButton?: boolean;
    onCancel?: () => void;
};

type TNotificationConfig = TCommonConfig & {
    testid?: string;
    ariaLabel?: string;
    description?: string;
    centered?: boolean;
    hideHeader?: boolean;
    onClose?: () => void;
};

type TConfirmationConfig = TCommonConfig & {
    isWarning?: boolean;
    onOK?: () => void;
    cancelText?: ReactNode;
};

type TRenderProps = (TNotificationConfig | TConfirmationConfig) & {
    show: boolean;
    onHide: () => void;
};

const destroyFns = [] as Array<THandlerHide>;

// LightboxService provides an imperative way to open simple modals like Notification/Confirmation
// without the need to additionally handle modal state
// Inspired by https://github.com/ant-design/ant-design/blob/master/components/modal/confirm.tsx
export const LightboxService = {
    // An alternative to StlNotification.show(message)
    // Could be used when we need more attention from the user since modal blocks user interface
    // until closed
    openNotification(config: TNotificationConfig) {
        const div = document.createElement("div");
        document.body.appendChild(div);
        let currentConfig = { ...config, onHide, show: true };

        const destroy = () => {
            const unmountResult = ReactDOM.unmountComponentAtNode(div);
            if (unmountResult && div.parentNode) {
                div.parentNode.removeChild(div);
            }

            if (config.onClose) {
                config.onClose();
            }

            for (let i = 0; i < destroyFns.length; i++) {
                const fn = destroyFns[i];
                if (fn === onHide) {
                    destroyFns.splice(i, 1);
                    break;
                }
            }
        };

        const render = (props: TRenderProps) => {
            // Render modal asynchronous as it might be opened at event handles, e.g onClick
            setTimeout(() => {
                ReactDOM.render(
                    // @ts-ignore
                    <IntlProvider locale={window.locale} messages={window.messages}>
                        <NotificationDialog {...props} />
                    </IntlProvider>,
                    div,
                );
            });
        };

        function onHide() {
            currentConfig = {
                ...currentConfig,
                show: false,
                onExited: destroy,
            };
            render(currentConfig);
        }

        render(currentConfig);

        destroyFns.push(onHide);

        return {
            destroy: onHide,
        };
    },

    // An alternative to StlNotification.show(message)
    // Could be used when we need more attention from the user since modal blocks user interface
    // until closed
    openConfirmation(config: TConfirmationConfig) {
        const div = document.createElement("div");
        document.body.appendChild(div);
        let titleContent = config.titleContent;
        if (config.isWarning) {
            titleContent = (
                <>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="pull-left" />
                    Warning
                </>
            );
        }
        let currentConfig = {
            ...config,
            className: classNames(config.className, {
                "stl-confirmation-dialog-modal-warning": config.isWarning,
            }),
            titleContent,
            onHide,
            show: true,
        };

        const destroy = () => {
            const unmountResult = ReactDOM.unmountComponentAtNode(div);
            if (unmountResult && div.parentNode) {
                div.parentNode.removeChild(div);
            }

            for (let i = 0; i < destroyFns.length; i++) {
                const fn = destroyFns[i];
                if (fn === onHide) {
                    destroyFns.splice(i, 1);
                    break;
                }
            }
        };

        const render = (props: TRenderProps) => {
            // Render modal asynchronous as it might be opened at event handles, e.g onClick
            setTimeout(() => {
                ReactDOM.render(
                    // @ts-ignore Property 'locale' and 'message' does not exist on type 'Window & typeof globalThis'.
                    <IntlProvider locale={window.locale} messages={window.messages}>
                        <ConfirmationDialog {...props} />
                    </IntlProvider>,
                    div,
                );
            });
        };

        function onHide() {
            currentConfig = {
                ...currentConfig,
                show: false,
                onExited: destroy,
            };
            render(currentConfig);
        }

        render(currentConfig);

        destroyFns.push(onHide);

        return {
            destroy: onHide,
        };
    },
};
