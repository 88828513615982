import axios from "axios";
import type { Geometry, FeatureCollection } from "geojson";

export type TGetArcgisLayerResponse<T extends Geometry | null> = FeatureCollection<T> & {
    properties?: {
        exceededTransferLimit: boolean;
    };
};

type TArcGISGeometry =
    | {
          xmin: number;
          ymin: number;
          xmax: number;
          ymax: number;
      }
    | string;

interface IQuantizationParams {
    extent?: TArcGISGeometry;
    originPosition?: "upperLeft" | "lowerLeft";
    mode?: "view" | "edit";
    tolerance: number;
}

// Params description: https://developers.arcgis.com/rest/services-reference/enterprise/query-feature-service-layer-.htm
export interface IGetArcgisLayerParams {
    geometry?: TArcGISGeometry;
    resultOffset: number;
    resultRecordCount: number;
    where: string;
    quantizationParameters?: IQuantizationParams;
}

export const ArcgisFeatureService = {
    getPaginatedDacZonesLayer(params: IGetArcgisLayerParams, signal?: AbortSignal) {
        // Params description: https://developers.arcgis.com/rest/services-reference/enterprise/query-feature-service-layer-.htm
        return axios
            .get<unknown, TGetArcgisLayerResponse<Geometry>>(
                "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/usa_november_2022/FeatureServer/0/query",
                {
                    params: {
                        geometryType: "esriGeometryEnvelope",
                        inSR: "4326",
                        f: "geojson",
                        resultType: "tile",
                        outFields:
                            "FID, SN_C, N_CLT, N_ENY, N_HLTH, N_HSG, N_PLN, N_TRN, N_WTR, N_WKFC",
                        multipatchOption: "xyFootprint",
                        returnExceededLimitFeatures: true,
                        sqlFormat: "none",
                        ...params,
                    },
                    signal,
                },
            )
            .then(res => res)
            .catch(error => {
                // We need it to define if it's necessary to show error notification.
                // If request was cancelled, we don't need to show error.
                const _error = axios.isCancel(error)
                    ? ""
                    : "Unknown error occurred while receiving feature collection.";

                return Promise.reject(_error);
            });
    },
};
