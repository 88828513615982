import { GeoJsonProperties, LineString } from "geojson";
import { v4 as uuidv4 } from "uuid";
import {
    PEAK_HOUR_OPTIONS,
    PEAK_HOUR_OPTIONS_LIST,
    TRAFFIC_BEHAVIOR_OPTIONS,
} from "@app/viz3/tmcVisualization/state/tmcViz.constants";
import {
    ICustomRangeGateData,
    IDiagramData,
    IGateData,
    IHoveredArrow,
    IIntersection,
    IIntersectionZoneGate,
    ISelectedGate,
} from "@common/features/intersections/intersection.types";
import { capitalizeTheFirstLetter } from "@common/formatters/formatString";
import { IAnalysisIntersectionZone } from "@common/services/server/analysesApi.types";
import centroid from "@turf/centroid";
import {
    GATE_TYPE_OPTIONS,
    GATE_TYPE_OPTIONS_LIST,
    IIntersectionRole,
    INTERSECTION_GATE_DIRECTIONS,
    INTERSECTION_GATES,
    OUTBOUND_TRIP_DIRECTIONS,
    TGateType,
} from "./intersection.constants";

export const getGateTypeObject = (role: IIntersectionRole) =>
    GATE_TYPE_OPTIONS_LIST.find(gateType => gateType.value === role);

export const getDirectionOptions = (gateRole: TGateType) => GATE_TYPE_OPTIONS[gateRole].direction;

export const getDefaultGateDirection = (isOneWayValue: boolean, gateRole: IIntersectionRole) => {
    if (!isOneWayValue) return undefined;

    return getDirectionOptions(gateRole)[0].value;
};

export const getNextDefaultGateRole = (currentGates: Array<IIntersectionZoneGate>) => {
    const allGateRoles = Object.keys(GATE_TYPE_OPTIONS);
    if (currentGates.length >= allGateRoles.length) return null;

    const unusedGateRoles = allGateRoles.filter(
        gateRole => !currentGates.find(({ role }) => role === gateRole),
    );

    return unusedGateRoles.length ? unusedGateRoles[0] : null;
};

export const makeGateObject = ({
    gateProperty,
    value,
    gate,
}: {
    gateProperty: string;
    value: boolean | string;
    gate: IIntersectionZoneGate;
}) => {
    switch (gateProperty) {
        case "role":
            return {
                oneWay: false,
                role: value,
                direction: undefined,
            };
        case "road":
            return {
                road: value,
            };
        case "oneWay":
            return {
                oneWay: value,
                direction: getDefaultGateDirection(!!value, gate.role),
            };
        case "direction":
            return {
                direction: value,
            };
        default:
            return gate;
    }
};

export const convertGeoJsonToCentroidFeature = (
    geoJson: string,
    properties: GeoJsonProperties = {},
) => {
    const geometry = JSON.parse(geoJson);

    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: centroid(geometry).geometry.coordinates,
        },
        properties,
    };
};

export const convertIntersectionApiObjectToIntersection = (
    intersectionZone: IAnalysisIntersectionZone,
): IIntersection => {
    return {
        ...intersectionZone,
        id: String(intersectionZone.zone_id),
        gates: intersectionZone.gates.map(gate => ({
            ...gate,
            id: uuidv4(),
        })) as IIntersection["gates"],
        isImported: true,
    };
};

export const getGateDirection = (geometry: LineString, direction: string) => {
    const _direction = capitalizeTheFirstLetter(direction);
    const [firstPointCoords, secondPointCoords] = geometry.coordinates;

    const angleFromCoordinates =
        (Math.atan(
            (secondPointCoords[1] - firstPointCoords[1]) /
                (secondPointCoords[0] - firstPointCoords[0]),
        ) *
            180) /
        Math.PI;

    const absoluteValue = Math.abs(angleFromCoordinates);

    const gateDirection =
        180 - (angleFromCoordinates > 0 ? angleFromCoordinates : 180 - absoluteValue);

    if (!_direction) {
        return gateDirection;
    } else {
        const isAnglePositive = angleFromCoordinates > 0;

        if (isAnglePositive) {
            return _direction === INTERSECTION_GATE_DIRECTIONS.NORTHBOUND ||
                _direction === INTERSECTION_GATE_DIRECTIONS.WESTBOUND
                ? 180 + gateDirection
                : gateDirection;
        } else {
            return _direction === INTERSECTION_GATE_DIRECTIONS.NORTHBOUND ||
                _direction === INTERSECTION_GATE_DIRECTIONS.EASTBOUND
                ? absoluteValue
                : 180 + absoluteValue;
        }
    }
};

export const validateIntersectionName = (intersection: IIntersection) => {
    const intersectionTitleError = intersection.zone_name.trim().length
        ? ""
        : "Please name the intersection.";

    return {
        ...intersection,
        invalidName: intersectionTitleError,
    };
};

export const validateGate = ({
    gate,
    gates,
}: {
    gate: IIntersectionZoneGate;
    gates: Array<IIntersectionZoneGate>;
}) => {
    const invalidName = [],
        invalidRole = [],
        invalidGeometry = [];
    const { gatesWithSameRole, gatesWithSameName } = gates.reduce(
        (res, _gate) => {
            if (_gate.role === gate.role) {
                res.gatesWithSameRole++;
            }
            if (_gate.road && _gate.road === gate.road) {
                res.gatesWithSameName++;
            }
            return res;
        },
        { gatesWithSameName: 0, gatesWithSameRole: 0 },
    );

    if (!gate.road.trim()) {
        invalidName.push("Please name the gate.");
    }

    if (!gate.line_geom) {
        invalidGeometry.push("Please draw the gate.");
    }

    if (gatesWithSameName > 1) {
        invalidName.push("Gate name must be unique.");
    }

    if (gatesWithSameRole > 1) {
        invalidRole.push("Please select unique role for the gate.");
    }

    return {
        ...gate,
        invalidName,
        invalidRole,
        invalidGeometry,
        errors: [
            ...invalidRole,
            ...invalidName,
            ...invalidGeometry,
            ...(gate.geometryWarnings || []),
        ],
    };
};

export const validateGateRolesUniqueness = (gates: Array<IIntersectionZoneGate>) => {
    const uniqueRoles = gates.reduce((res, gate) => res.add(gate.role), new Set());

    return uniqueRoles.size === gates.length;
};

export const validateGateNamesUniqueness = (gates: Array<IIntersectionZoneGate>) => {
    const { uniqueNames, emptyNames } = gates.reduce(
        (res, gate) => {
            if (gate.road) {
                res.uniqueNames.add(gate.road);
            } else {
                res.emptyNames++;
            }
            return res;
        },
        { uniqueNames: new Set(), emptyNames: 0 },
    );

    return uniqueNames.size === gates.length - emptyNames;
};

/*
    Configurations where all gate directions are inbound or outbound are invalid:
    1. North - Northbound; East - Eastbound; South - Southbound; West - Westbound
    2. North - Southbound; East - Westbound; South - Northbound; West - Eastbound
 */
export const validateGateDirections = (gates: Array<IIntersectionZoneGate>) => {
    const { outboundCount, inboundCount } = gates.reduce(
        (res, gate) => {
            switch (gate.role) {
                case INTERSECTION_GATES.NORTH.role:
                    if (gate.direction === INTERSECTION_GATE_DIRECTIONS.NORTHBOUND) {
                        res.outboundCount++;
                    } else if (gate.direction === INTERSECTION_GATE_DIRECTIONS.SOUTHBOUND) {
                        res.inboundCount++;
                    }
                    break;
                case INTERSECTION_GATES.SOUTH.role:
                    if (gate.direction === INTERSECTION_GATE_DIRECTIONS.SOUTHBOUND) {
                        res.outboundCount++;
                    } else if (gate.direction === INTERSECTION_GATE_DIRECTIONS.NORTHBOUND) {
                        res.inboundCount++;
                    }
                    break;
                case INTERSECTION_GATES.WEST.role:
                    if (gate.direction === INTERSECTION_GATE_DIRECTIONS.WESTBOUND) {
                        res.outboundCount++;
                    } else if (gate.direction === INTERSECTION_GATE_DIRECTIONS.EASTBOUND) {
                        res.inboundCount++;
                    }
                    break;
                case INTERSECTION_GATES.EAST.role:
                    if (gate.direction === INTERSECTION_GATE_DIRECTIONS.EASTBOUND) {
                        res.outboundCount++;
                    } else if (gate.direction === INTERSECTION_GATE_DIRECTIONS.WESTBOUND) {
                        res.inboundCount++;
                    }
                    break;
            }
            return res;
        },
        { outboundCount: 0, inboundCount: 0 },
    );

    return outboundCount !== gates.length && inboundCount !== gates.length;
};

export const getDirectionValue = (
    arrow: IHoveredArrow | null,
    selectedGate: ISelectedGate | undefined,
    data: Array<ICustomRangeGateData> | IDiagramData,
) => {
    const isOutbound = arrow?.type === TRAFFIC_BEHAVIOR_OPTIONS.OUTBOUND.value;
    const outBoundDirection = OUTBOUND_TRIP_DIRECTIONS[selectedGate!.role][arrow!.gate_role];

    if (Array.isArray(data)) {
        const _gate = data.find(gate => gate.role === arrow?.gate_role) as ICustomRangeGateData;

        const direction = isOutbound ? outBoundDirection : arrow?.type.split("-")[1];

        return _gate[direction as keyof ICustomRangeGateData];
    }

    return PEAK_HOUR_OPTIONS_LIST.reduce((result, option) => {
        const selectedOption = data.day_type_peak_hours?.[0][option.value as "am" | "pm"];

        if (!selectedOption) return result;

        const value = isOutbound
            ? selectedOption[arrow!.gate_role as IIntersectionRole][
                  outBoundDirection as keyof IGateData
              ]
            : selectedOption[selectedGate?.role as IIntersectionRole][
                  arrow!.type.split("-")[1] as keyof IGateData
              ];
        const peakHourOption =
            PEAK_HOUR_OPTIONS[option.value.toUpperCase() as keyof typeof PEAK_HOUR_OPTIONS];

        return [...result, `${[peakHourOption.label]}: ${value}`];
    }, [] as Array<string>)
        .join()
        .replace(/,/g, ", ");
};
