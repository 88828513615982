import { useCallback, useEffect, useState } from "react";
import type { FillLayer, Layer, LngLatBounds, Map } from "mapbox-gl";
import type { FeatureCollection } from "geojson";
import { debounce } from "lodash-es";
import { getFirstLabelLayerId, removeSource } from "@common/utils/mapUtils";
import { ArcgisFeatureService } from "@common/services/arcgisFeature.service";
import { StlNotification } from "@common/components";
import {
    DAC_ZONES_STYLE,
    MIN_INTERACTIVE_ZOOM_FOR_DAC_ZONES,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";

const recordCount = 2000;

const SOURCE_ID = "stl:cs-j40-source";
const LAYER_ID = "stl:j-40-geom-layer";
const OUTLINE_LAYER_ID = "stl:j-40-geom-outline-layer";

export const ZOOM_STEPS = {
    MIN: 7.5,
    MIDDLE: 10,
};

const getTolerance = (zoom: number) => {
    if (zoom < ZOOM_STEPS.MIN) return 0.02;

    if (zoom < ZOOM_STEPS.MIDDLE) return 0.01;

    return 0;
};

const getBeforeLayerId = (map: Map) => {
    const { layers = [] } = map.getStyle();

    const firstStlLayerId = layers.find(layer => layer.id.includes("stl:"))?.id;

    return firstStlLayerId || getFirstLabelLayerId(map);
};

export interface IDACZonesStyle {
    POLYGON: {
        COLOR: string;
        OPACITY: number;
    };
    LINE: {
        WIDTH: number;
        COLOR: string;
    };
}

export const useDACZoneLayers = ({
    map,
    show,
    where = "SN_C=1",
    style = DAC_ZONES_STYLE,
    sourceIdPostfix = "",
}: {
    map: mapboxgl.Map | null;
    show: boolean;
    where?: string;
    style?: IDACZonesStyle;
    sourceIdPostfix?: string;
}) => {
    const [bounds, setBounds] = useState<null | LngLatBounds>(null);
    const [dacZonesLayers, setDacZonesLayers] = useState<Layer[]>([]);

    const sourceId = SOURCE_ID + sourceIdPostfix;
    useEffect(() => {
        if (!map || !show) return undefined;

        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
            promoteId: "FID",
        });

        const labelId = getBeforeLayerId(map);

        map.addLayer(
            {
                id: OUTLINE_LAYER_ID + sourceIdPostfix,
                type: "line",
                minzoom: MIN_INTERACTIVE_ZOOM_FOR_DAC_ZONES,
                source: sourceId,
                paint: {
                    "line-width": [
                        "step",
                        ["zoom"],
                        style.LINE.WIDTH / 3,
                        ZOOM_STEPS.MIN,
                        style.LINE.WIDTH / 2,
                        ZOOM_STEPS.MIDDLE,
                        style.LINE.WIDTH,
                    ],
                    "line-color": style.LINE.COLOR,
                },
            },
            labelId,
        );

        const layer: FillLayer = {
            id: LAYER_ID + sourceIdPostfix,
            type: "fill",
            minzoom: MIN_INTERACTIVE_ZOOM_FOR_DAC_ZONES,
            source: sourceId,
            paint: {
                "fill-color": style.POLYGON.COLOR,
                "fill-opacity": 0.4,
            },
            filter: [
                "any",
                ["==", ["get", "N_CLT"], 1],
                ["==", ["get", "N_ENY"], 1],
                ["==", ["get", "N_HLTH"], 1],
                ["==", ["get", "N_HSG"], 1],
                ["==", ["get", "N_PLN"], 1],
                ["==", ["get", "N_TRN"], 1],
                ["==", ["get", "N_WTR"], 1],
                ["==", ["get", "N_WKFC"], 1],
            ],
        };

        map.addLayer(layer, labelId);

        setDacZonesLayers([layer]);

        return () => {
            if (map.getSource(sourceId)) {
                removeSource(map, sourceId);
            }
        };
    }, [style, map, show, sourceId, sourceIdPostfix]);

    const addZonesToMap = useCallback(
        ({
            _map,
            geometry,
            resultOffset,
            signal,
            tolerance,
            features,
        }: {
            _map: Map;
            geometry: string;
            resultOffset: number;
            signal: AbortSignal;
            tolerance: number;
            features: FeatureCollection["features"];
        }) => {
            ArcgisFeatureService.getPaginatedDacZonesLayer(
                {
                    where,
                    geometry,
                    resultOffset,
                    resultRecordCount: recordCount,
                    quantizationParameters: {
                        tolerance,
                    },
                },
                signal,
            )
                .then(result => {
                    const source = _map.getSource(sourceId);

                    if (source.type !== "geojson") return;

                    const { properties, ...featureCollection } = result;

                    const updatedFeatureList = [...features, ...featureCollection.features];

                    if (properties?.exceededTransferLimit) {
                        addZonesToMap({
                            _map,
                            geometry,
                            resultOffset: resultOffset + recordCount,
                            signal,
                            tolerance,
                            features: updatedFeatureList,
                        });
                    } else {
                        source.setData({
                            type: "FeatureCollection",
                            features: updatedFeatureList,
                        });
                    }
                })
                .catch(error => {
                    if (error) {
                        StlNotification.error(error);
                    }
                });
        },
        [sourceId, where],
    );

    // Subscribe on bound change
    useEffect(() => {
        if (!map || !show) return undefined;

        const updateBounds = debounce(() => {
            const _bounds = map.getBounds();

            setBounds(_bounds);
        }, 300);

        map.on("moveend", updateBounds);

        return () => {
            map.off("moveend", updateBounds);
        };
    }, [map, show]);

    // Set bounds on DAC layer checkbox select
    useEffect(() => {
        if (!map || !show) return undefined;

        const _bounds = map.getBounds();

        setBounds(_bounds);

        return () => {
            setBounds(null);
        };
    }, [map, show]);

    useEffect(() => {
        if (!map || !bounds || !show) return undefined;

        const zoomLevel = map.getZoom();

        if (zoomLevel < MIN_INTERACTIVE_ZOOM_FOR_DAC_ZONES) return undefined;

        const abortController = new AbortController();

        addZonesToMap({
            _map: map,
            geometry: bounds.toArray().flat().join(","),
            resultOffset: 0,
            signal: abortController.signal,
            tolerance: getTolerance(zoomLevel),
            features: [],
        });

        return () => {
            abortController.abort();
        };
    }, [map, addZonesToMap, bounds, show]);

    return dacZonesLayers;
};
