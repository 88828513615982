const ACTION_PREFIX = "baseViz";

export const BASE_VIZ_ACTIONS = {
    SET_SELECTED_ANALYSIS: `${ACTION_PREFIX}/SET_SELECTED_ANALYSIS`,
    UPDATE_SELECTED_ANALYSIS: `${ACTION_PREFIX}/UPDATE_SELECTED_ANALYSIS`,
    SET_ANALYSIS_COMMON_CONFIG: `${ACTION_PREFIX}/SET_ANALYSIS_COMMON_CONFIG`,
    SET_ANALYSIS_USER_CONFIG: `${ACTION_PREFIX}/SET_ANALYSIS_USER_CONFIG`,
    SET_FILTER: `${ACTION_PREFIX}/SET_FILTER`,
    UPDATE_WIDGET: `${ACTION_PREFIX}/UPDATE_WIDGET`,
    SET_VISUALIZATION: `${ACTION_PREFIX}/SET_VISUALIZATION`,
    SET_LAYER_VISIBILITY: `${ACTION_PREFIX}/SET_LAYER_VISIBILITY`,
    SET_HOVERED_ZONE: `${ACTION_PREFIX}/SET_HOVERED_ZONE`,
    SET_HOVERED_DAC_ZONE: `${ACTION_PREFIX}/SET_HOVERED_DAC_ZONE`,
    SET_STATIC_GEOMETRIES: `${ACTION_PREFIX}/SET_STATIC_GEOMETRIES`,
    SET_MAP_MODE: `${ACTION_PREFIX}/SET_MAP_MODE`,
    SET_MAP_LOADED: `${ACTION_PREFIX}/SET_MAP_LOADED`,
    SET_MAP_LOCATION: `${ACTION_PREFIX}/SET_MAP_LOCATION`,
    SET_MULTIPLE_SELECTION: `${ACTION_PREFIX}/SET_MULTIPLE_SELECTION`,
    SET_MAP_ERROR: `${ACTION_PREFIX}/SET_MAP_ERROR`,
    SET_IS_MAP_LOADING: `${ACTION_PREFIX}/SET_IS_MAP_LOADING`,
    SET_MAP_STYLE: `${ACTION_PREFIX}/SET_MAP_STYLE`,
    SET_VIZ_STATE: `${ACTION_PREFIX}/SET_VIZ_STATE`,
    SET_PENDING_EXPORT_REQUESTS: `${ACTION_PREFIX}/SET_PENDING_EXPORT_REQUESTS`,
    RESET_VIZ_STATE: `${ACTION_PREFIX}/RESET_VIZ_STATE`,
    SET_FILTER_HASH: `${ACTION_PREFIX}/SET_FILTER_HASH`,
    SET_SEGMENTS_GROUP_CONFIG: `${ACTION_PREFIX}/SET_SEGMENTS_GROUP_CONFIG`,
    RESET_SEGMENTS_GROUP_CONFIG: `${ACTION_PREFIX}/RESET_SEGMENTS_GROUP_CONFIG`,
    SET_SEGMENTS_GROUP_MAP_CONFIG: `${ACTION_PREFIX}/SET_SEGMENTS_GROUP_MAP_CONFIG`,
};
