import { ReactNode, useMemo, forwardRef, ForwardedRef } from "react";

type TProps = {
    children?: ReactNode;
    className?: string;
    size?: number | null;
};

export const Pane = forwardRef(
    ({ children, className, size }: TProps, ref: ForwardedRef<HTMLDivElement>) => {
        const styles = useMemo(() => {
            return size !== undefined && size !== null
                ? {
                      width: size,
                      flex: "none",
                  }
                : {};
        }, [size]);

        return (
            <div ref={ref} className={className} style={styles}>
                {children}
            </div>
        );
    },
);
