import { useMemo } from "react";
import type {
    TEnhancedLayer,
    TOSMLayer,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";

export const useGetPickerData = <T extends TEnhancedLayer | TOSMLayer>({
    availableLayers,
    getLabel,
    selectedLayers,
    onUpdateLayers,
}: {
    availableLayers: T[];
    getLabel: (option: T) => void;
    selectedLayers: T[];
    onUpdateLayers: (layerCodes: string[]) => void;
}) => {
    const options = useMemo(
        () =>
            availableLayers.map(option => ({
                label: getLabel(option),
                value: option.code,
            })),
        [availableLayers, getLabel],
    );

    const value = useMemo(
        () => selectedLayers.reduce((result, option) => ({ ...result, [option.code]: true }), {}),
        [selectedLayers],
    );

    const changeSelectedLayers = (updatedSelectedLayers: Record<string, boolean>) => {
        const layerCodes =
            availableLayers
                .filter(layer => updatedSelectedLayers[layer.code])
                .map(layer => layer.code) || [];

        onUpdateLayers(layerCodes);
    };

    return { options, value, changeSelectedLayers };
};
