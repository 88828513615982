export const SEARCH_KEY = "search" as const;

export const MIN_QUERY_LENGTH = 3 as const;

// words (articles and prepositions) that should be ignored during search
export const EXCLUDED_WORDS = ["the", "out", "for"];

export const TRAVEL_MODES_OPTIONS = {
    All_Vehicles: {
        value: "All_Vehicles",
        label: "All Vehicles",
    },
    Truck: {
        value: "Truck",
        label: "Truck",
    },
    Bus: {
        value: "Bus",
        label: "Bus",
    },
    Rail: {
        value: "Rail",
        label: "Rail",
    },
    Bicycle: {
        value: "Bicycle",
        label: "Bike",
    },
    Pedestrian: {
        value: "Pedestrian",
        label: "Pedestrian",
    },
};

export const METRICS_OPTIONS = {
    HWL: {
        value: "HWL",
        label: "Home and Work Locations",
    },
    "85th percentile": {
        value: "85th percentile",
        label: "85th percentile",
    },
    "average trip speed": {
        value: "average trip speed",
        label: "Average Trip Speed",
    },
    "trip length": {
        value: "trip length",
        label: "Trip Length",
    },
    "travel time": {
        value: "travel time",
        label: "Travel Time",
    },
    "speed percentile": {
        value: "speed percentile",
        label: "Speed Percentile",
    },
};
