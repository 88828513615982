import { merge } from "lodash-es";
import { BASE_VIZ_ACTIONS } from "./baseViz.actionTypes";
import { BASE_VIZ_INITIAL_STATE, DEFAULT_SEGMENT_GROUP_STATE } from "./baseViz.state";

export const baseVizReducer = (state = BASE_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.SET_SELECTED_ANALYSIS: {
            return {
                ...state,
                selectedAnalysis: data.selectedAnalysis,
                filters: BASE_VIZ_INITIAL_STATE.filters,
                changedFilters: BASE_VIZ_INITIAL_STATE.changedFilters,
            };
        }

        case BASE_VIZ_ACTIONS.UPDATE_SELECTED_ANALYSIS: {
            return {
                ...state,
                selectedAnalysis: {
                    ...state.selectedAnalysis,
                    ...data,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            const mapStyle = data.baseMapStyle || BASE_VIZ_INITIAL_STATE.map.style;

            return {
                ...state,
                map: {
                    ...state.map,
                    style: mapStyle,
                },
                widgets: merge({}, BASE_VIZ_INITIAL_STATE.widgets, data.widgets),
            };
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_USER_CONFIG: {
            return {
                ...state,
                filters: data.filters,
                visualization: data.visualization,
            };
        }

        case BASE_VIZ_ACTIONS.SET_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [data.filterName]: data.value,
                },
                changedFilters: {
                    ...state.changedFilters,
                    [data.filterName]: true,
                },
            };
        }

        case BASE_VIZ_ACTIONS.UPDATE_WIDGET: {
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [data.widget.id]: {
                        ...state.widgets[data.widget.id],
                        ...data.widget.value,
                    },
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_VISUALIZATION: {
            return {
                ...state,
                visualization: {
                    ...state.visualization,
                    [data.filterName]: data.value,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_LAYER_VISIBILITY: {
            return {
                ...state,
                visualization: {
                    ...state.visualization,
                    mapLayersVisibility: {
                        ...state.visualization.mapLayersVisibility,
                        [data.name]: data.visibility,
                    },
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_HOVERED_ZONE: {
            return {
                ...state,
                map: {
                    ...state.map,
                    hoveredZone: data.zone,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_HOVERED_DAC_ZONE: {
            return {
                ...state,
                map: {
                    ...state.map,
                    hoveredDacZone: data.zone,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_STATIC_GEOMETRIES: {
            return {
                ...state,
                map: {
                    ...state.map,
                    staticGeometries: data.staticGeometries,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_IS_MAP_LOADING: {
            return {
                ...state,
                map: {
                    ...state.map,
                    isLoading: data.isLoading,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MAP_MODE: {
            return {
                ...state,
                map: {
                    ...state.map,
                    mode: data.mode,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MAP_LOADED: {
            return {
                ...state,
                map: {
                    ...state.map,
                    loaded: true,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MAP_LOCATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    location: data.location,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MULTIPLE_SELECTION: {
            return {
                ...state,
                multipleSelection: {
                    zoneType: data.multipleSelection.zoneType || state.multipleSelection.zoneType,
                    selectedZoneIds: {
                        ...state.multipleSelection.selectedZoneIds,
                        ...data.multipleSelection.selectedZoneIds,
                    },
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MAP_ERROR: {
            return {
                ...state,
                map: {
                    ...state.map,
                    error: data
                        ? {
                              isServerError: data.isServerError || false,
                              errorTexts: data.errorTexts,
                          }
                        : null,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_MAP_STYLE: {
            return {
                ...state,
                map: {
                    ...state.map,
                    style: data,
                    loaded: false,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_PENDING_EXPORT_REQUESTS: {
            return {
                ...state,
                pendingExportRequests: {
                    ...state.pendingExportRequests,
                    ...data.pendingExportRequests,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            const mapStyle = state.map?.style;
            const newState = BASE_VIZ_INITIAL_STATE;

            // remember user’s choice for base map style and continue to use that when open up another analysis
            return {
                ...newState,
                map: {
                    ...newState.map,
                    style: mapStyle || newState.map.style,
                },
            };
        }

        case BASE_VIZ_ACTIONS.SET_FILTER_HASH: {
            return {
                ...state,
                filterHash: data,
            };
        }

        case BASE_VIZ_ACTIONS.SET_SEGMENTS_GROUP_CONFIG: {
            return {
                ...state,
                segmentsGroupConfigurations: {
                    ...state.segmentsGroupConfigurations,
                    ...data,
                },
            };
        }

        case BASE_VIZ_ACTIONS.RESET_SEGMENTS_GROUP_CONFIG: {
            return {
                ...state,
                segmentsGroupConfigurations: DEFAULT_SEGMENT_GROUP_STATE,
            };
        }

        case BASE_VIZ_ACTIONS.SET_SEGMENTS_GROUP_MAP_CONFIG: {
            return {
                ...state,
                segmentsGroupConfigurations: {
                    ...state.segmentsGroupConfigurations,
                    map: {
                        ...state.segmentsGroupConfigurations.map,
                        ...data,
                    },
                },
            };
        }
        default:
            return state;
    }
};
