import { getCommercialVehicleSettings } from "@app/analysis/addOns/state/addOns.selectors";
import {
    COMMERCIAL_VEHICLE_TYPE_OPTIONS,
    TRAVEL_MODES_OPTIONS,
} from "@app/analysis/basics/components/travelModeSection/travelModeSection.constants";
import {
    ALL_VEHICLES_TRAVEL_MODES,
    ALL_VEHICLES_TRAVEL_MODES_LIST,
    BIKE_PED_VOLUME_SUPPORTED_ANALYSES,
    CALIBRATIONS,
    CALIBRATIONS_LIST,
    CREATE_ANALYSIS_TYPES,
    DEFAULT_AADT_YEAR,
    TRAVEL_MODES,
    TRAVEL_MODES_LIST,
} from "@app/analysis/state/analysisConfiguration.constants";
import { getAnalysisTypeFromCode } from "@app/analysis/state/analysisConfiguration.helpers";
import {
    analysisTypeObjSelector,
    getAnalysisTypeCode,
    getGeneral,
    getIsReadOnlyMode,
    isAnalysisTypeOf,
} from "@app/analysis/state/general/general.selectors";
import { getAadtSettings } from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { getIPFCalibrationValuesPayload } from "@app/analysis/zones/chooseZones/base/sidebar/ipfCalibrationSection/ipfCalibrationSection.helpers";
import { getIPFCalibrationSettings } from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { METRICS_PACKAGES_CATEGORIES } from "@app/bigBang/projects/state/projects.constants";
import { getUniqTravelModes } from "@app/bigBang/projects/state/projects.helpers";
import {
    getAvailableCalibrations,
    getIsOrgHasFeature,
    getIsSandbox,
} from "@app/store/currentUser/currentUser.selector";
import { MODES_OF_TRAVEL, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { getTagsOptionsList } from "@common/features/tags/state/tags.selectors";
import {
    getIsAllVehiclesOrTruckTravelMode,
    getIsBikeOrPedTravelMode,
    getIsNetworkPerformanceAnalysis,
} from "@common/helpers/analysis";
import { createSelector } from "reselect";

/*
    Getter of the main object, just for the sake of convenience.
*/
export function getBasics(state) {
    return state.analysisConfiguration.basics;
}

/*
    Getters sub-objects of the main one. Just for the sake of convenience.
*/

export function getBasicsValidation(state) {
    return getBasics(state).validation;
}
export function getAnalysisCountry(state) {
    return getBasics(state).country;
}

export const getDirectAnalysesZonesLimits = state => getBasics(state).analysesZonesLimits;

export const getCalibrationSettings = state => getBasics(state).calibrationSettings;

/*
    This getters should be private (used only in this file) and are necessary only for proper
    `createSelector` memoization. If you want to get those properties, please use parent object.
*/
const getTravelModeCode = state => getBasics(state).travelModeCode;
const getSelectedTagIds = state => getBasics(state).tagIds;
const getCalibrationCode = state => getCalibrationSettings(state).calibrationCode;
const getAvailableCalibrationCodes = state =>
    getCalibrationSettings(state).availableCalibrationCodes;
const getAADTCalibrationYear = state =>
    getCalibrationSettings(state).aadtCalibrationYear || DEFAULT_AADT_YEAR;

/*
    Other getters which are used in different places.
    Please create new ones with caution. We definitely don't want this file become the place
    where functions used only once and/or don't serve as helpers.
*/

/*
    re-select selectors.
    Implements memoization. Use them in case of some calculations you need to perform together
    with store selectors.
*/

export const travelModeObjSelector = createSelector(getTravelModeCode, travelModeCode => {
    const allVehicleMode = ALL_VEHICLES_TRAVEL_MODES_LIST.find(
        mode => mode.code === travelModeCode,
    );

    if (allVehicleMode) {
        return allVehicleMode;
    }
    return TRAVEL_MODES_LIST.find(travelMode => travelMode.code === travelModeCode);
});

export const getSelectedTagsOptionsList = createSelector(
    getTagsOptionsList,
    getSelectedTagIds,
    (tagsOptionsList, selectedTagIds) =>
        tagsOptionsList.filter(tagOption => selectedTagIds.includes(tagOption.value)),
);

export const calibrationObjSelector = createSelector(
    getCalibrationCode,
    travelModeObjSelector,
    state => getAnalysisTypeCode(state),
    (calibrationCode, travelMode, analysisTypeCode) => {
        if (calibrationCode === CALIBRATIONS.VOLUME.code) {
            if (!BIKE_PED_VOLUME_SUPPORTED_ANALYSES.includes(analysisTypeCode)) {
                return CALIBRATIONS.VOLUME;
            }
            switch (travelMode.code) {
                case TRAVEL_MODES.BICYCLE.code:
                    return CALIBRATIONS.BIKE_VOLUME;
                case TRAVEL_MODES.PEDESTRIAN.code:
                    return CALIBRATIONS.PED_VOLUME;
                case TRAVEL_MODES.TRUCK.code:
                    return CALIBRATIONS.TRUCK_VOLUME;
                default:
                    return CALIBRATIONS.VOLUME;
            }
        }
        return CALIBRATIONS_LIST.find(calibration => calibration.code === calibrationCode);
    },
);

export const availableCalibrationsSelector = createSelector(
    travelModeObjSelector,
    state => analysisTypeObjSelector(state),
    getAvailableCalibrationCodes,
    getAvailableCalibrations,
    getAnalysisCountry,
    state => getIsReadOnlyMode(state),
    state => getCommercialVehicleSettings(state),
    (
        travelModeObj,
        analysisTypeObj,
        availableCalibrationCodes,
        availableCalibrations,
        country,
        readOnly,
        { commercialVehicleType },
    ) => {
        const isBikeOrPedTravelMode = getIsBikeOrPedTravelMode(travelModeObj.code);
        const isTruckTravelMode = travelModeObj.code === TRAVEL_MODES.TRUCK.code;
        const isVehicleOrTruckTravelMode = getIsAllVehiclesOrTruckTravelMode(travelModeObj.code);
        const isHeavyDutyOnly = commercialVehicleType.cvHeavy && !commercialVehicleType.cvMedium;
        // Get the CALIBRATIONS based on analysis type or travel mode.
        const calibrationsForAnalysisType = analysisTypeObj.calibrations;
        let allowedCalibrations;
        if (calibrationsForAnalysisType !== undefined) {
            allowedCalibrations = travelModeObj.calibration.modes.filter(option =>
                calibrationsForAnalysisType.includes(option),
            );
        } else {
            allowedCalibrations = travelModeObj.calibration.modes;
        }

        // We still need Index Output to show old analysis's details in View mode
        if (isBikeOrPedTravelMode && readOnly) {
            allowedCalibrations = [...allowedCalibrations, CALIBRATIONS.INDEX];
        }

        // Make IPF calibration available for OD analysis in All Vehicles/Truck travel mode
        if (analysisTypeObj.code === CREATE_ANALYSIS_TYPES.OD.code && isVehicleOrTruckTravelMode) {
            allowedCalibrations = [...allowedCalibrations, CALIBRATIONS.IPF];
        } else if (analysisTypeObj.code === CREATE_ANALYSIS_TYPES.AADT.code) {
            // Make AADT_OUTPUT calibration available for AADT analyses
            allowedCalibrations = [CALIBRATIONS.AADT_OUTPUT];
        }

        const _allowedCalibrations = allowedCalibrations.reduce((res, calibration) => {
            if (calibration.code === CALIBRATIONS.VOLUME.code) {
                let volumeCalibration;
                if (isTruckTravelMode && (country === ORG_COUNTRIES.CA.code || isHeavyDutyOnly)) {
                    return res;
                } else if (!BIKE_PED_VOLUME_SUPPORTED_ANALYSES.includes(analysisTypeObj.code)) {
                    if (isBikeOrPedTravelMode) {
                        return res;
                    }
                    volumeCalibration = CALIBRATIONS.VOLUME;
                } else {
                    volumeCalibration = calibration;
                }
                const isCalibrationAvailable = availableCalibrations.find(
                    _calibration => _calibration.featureName === volumeCalibration.featureName,
                );
                if (isCalibrationAvailable) {
                    res.push(calibration);
                }
                return res;
            }

            return [...res, calibration];
        }, []);

        // Filter the CALIBRATIONS to the available ones.
        return _allowedCalibrations.filter(calibration =>
            availableCalibrationCodes.includes(calibration.code),
        );
    },
);

export const getDefaultCalibrationCode = createSelector(
    availableCalibrationsSelector,
    availableCalibrations => availableCalibrations[0] && availableCalibrations[0].code,
);

// Returns an array of the CALIBRATIONS as options for the StlSelect component.
export const availableCalibrationOptionsSelector = createSelector(
    travelModeObjSelector,
    availableCalibrationsSelector,
    getAnalysisCountry,
    state => getIsReadOnlyMode(state),
    (travelModeObj, availableCalibrations, country, readOnly) => {
        const isBusOrRailMode = [MODES_OF_TRAVEL.BUS.code, MODES_OF_TRAVEL.RAIL.code].includes(
            travelModeObj.code,
        );
        const isBusOrRailReadOnly = isBusOrRailMode && readOnly;
        const suffixes = travelModeObj.calibration.displaySuffixes;

        return availableCalibrations.map(calibration => {
            let label;
            if (calibration.shouldIgnoreSuffix) {
                label = calibration.display;
            } else {
                // Bus and Rail analyses in read-only modes should always display default calibration suffix
                const suffix = isBusOrRailReadOnly
                    ? suffixes.default
                    : suffixes[calibration.code]?.[country] ||
                      suffixes[calibration.code] ||
                      suffixes.default;

                label = `${calibration.display} (${suffix})`;
            }

            return {
                value: calibration.code,
                label,
            };
        });
    },
);

// Returns a calibration code that is valid for the travel mode and analysis type combination.
export const validCalibrationCodeSelector = createSelector(
    availableCalibrationsSelector,
    getCalibrationCode,
    getDefaultCalibrationCode,
    getIsSandbox,
    getGeneral,
    travelModeObjSelector,
    state => getCommercialVehicleSettings(state),
    (
        availableCalibrations,
        calibrationCode,
        defaultCalibrationCode,
        isSandbox,
        { analysisTypeCode },
        travelModeObj,
        commercialVehicleSettings,
    ) => {
        if (isSandbox && travelModeObj.code === MODES_OF_TRAVEL.TRUCK.code) {
            const truckDefaultCalibrationCode =
                commercialVehicleSettings?.commercialVehicleType?.cvMedium &&
                [CREATE_ANALYSIS_TYPES.ZA.code, CREATE_ANALYSIS_TYPES.SEGMENT.code].includes(
                    analysisTypeCode,
                )
                    ? CALIBRATIONS.VOLUME.code
                    : CALIBRATIONS.INDEX.code;

            if (
                availableCalibrations.find(
                    calibration => calibration.code === truckDefaultCalibrationCode,
                )
            ) {
                return truckDefaultCalibrationCode;
            }
        }

        if (!availableCalibrations.find(calibration => calibration.code === calibrationCode)) {
            return defaultCalibrationCode;
        }

        return calibrationCode;
    },
);

// Returns an object that should be merged with the projectData to set the calibration info.
export const analysisCalibrationInfoSelector = createSelector(
    getCalibrationCode,
    getAADTCalibrationYear,
    getIPFCalibrationSettings,
    state => isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.AADT.code, state),
    (calibrationCode, aadtCalibrationYear, ipfCalibrationSettings, isAADTAnalysis) => {
        if (isAADTAnalysis && calibrationCode === CALIBRATIONS.AADT_OUTPUT.code) {
            // Force AADT analysis to be treated as index (no_calibration)
            return { calibration_type: CALIBRATIONS.INDEX.code };
        } else if (calibrationCode === CALIBRATIONS.AADT.code) {
            return {
                calibration_type: `${CALIBRATIONS.AADT.code}_${aadtCalibrationYear}`,
            };
        } else if (calibrationCode === CALIBRATIONS.USER_COUNTS.code) {
            return {
                calibration_type: calibrationCode,
            };
        } else if (calibrationCode === CALIBRATIONS.IPF.code) {
            return {
                calibration_type: calibrationCode,
                ipf_calibration_values: getIPFCalibrationValuesPayload(ipfCalibrationSettings),
            };
        }

        return { calibration_type: calibrationCode };
    },
);

// Get available All Vehicles travel mode by analysis type and country
export const getAvailableAllVehiclesTravelMode = createSelector(
    getGeneral,
    getBasics,
    ({ analysisTypeCode }, { country }) => {
        if (
            analysisTypeCode === CREATE_ANALYSIS_TYPES.AADT.code ||
            (country === ORG_COUNTRIES.CA.code &&
                !getIsNetworkPerformanceAnalysis(analysisTypeCode))
        ) {
            return ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_ONLY;
        }
        return TRAVEL_MODES.ALL_VEHICLES;
    },
);

// Get available travel modes by analysis type and country
export const getAvailableTravelModes = createSelector(
    getGeneral,
    getBasics,
    state => getAadtSettings(state),
    getAvailableAllVehiclesTravelMode,
    ({ analysisTypeCode, projectFolder }, { country }, aadtSettings, availableAllVehiclesMode) => {
        const analysisType = getAnalysisTypeFromCode(analysisTypeCode);
        const travelModes = analysisType.travelModes.reduce((res, travelMode) => {
            if (travelMode.code === TRAVEL_MODES.ALL_VEHICLES.code) {
                res.push(availableAllVehiclesMode);
            } else if (travelMode.getIsAvailable({ analysisTypeCode, country })) {
                res.push(travelMode);
            }

            return res;
        }, []);

        if (!projectFolder) return travelModes;

        const availableTravelModes = getUniqTravelModes(projectFolder.metrics_packages).map(
            type => {
                return METRICS_PACKAGES_CATEGORIES.MODE_OF_TRAVEL.types[type.feature_id].code;
            },
        );

        return analysisType.travelModes.filter(travelMode =>
            availableTravelModes.includes(travelMode.code),
        );
    },
);

export const getDeprecatedTravelModes = createSelector(
    getGeneral,
    getBasics,
    ({ analysisTypeCode }) => {
        const analysisType = getAnalysisTypeFromCode(analysisTypeCode);
        return analysisType.deprecatedTravelModes || [];
    },
);

// Checks that analysis is US Truck Volume
export const getIsUSTruckVolumeAnalysis = createSelector(
    getBasics,
    ({ travelModeCode, country, calibrationSettings }) => {
        return (
            country === ORG_COUNTRIES.US.code &&
            travelModeCode === TRAVEL_MODES.TRUCK.code &&
            calibrationSettings.calibrationCode === CALIBRATIONS.VOLUME.code
        );
    },
);

export const getAvailableCommercialVehicleTypeOptions = createSelector(
    getBasics,
    getIsSandbox,
    ({ travelModeCode }, isSandbox) => {
        const commercialVehiclesTypeOptions =
            TRAVEL_MODES_OPTIONS[travelModeCode].commercialVehiclesTypeOptions;

        if (!commercialVehiclesTypeOptions) return undefined;

        return isSandbox
            ? [COMMERCIAL_VEHICLE_TYPE_OPTIONS.HEAVY_AND_MEDIUM]
            : commercialVehiclesTypeOptions;
    },
);

export const getAvailableAllVehiclesDataTypeOptions = state => {
    const { travelModeCode } = getBasics(state);
    const analysisTypeCode = getAnalysisTypeCode(state);

    return TRAVEL_MODES_OPTIONS[travelModeCode].allVehiclesDataTypeOptions?.filter(opt => {
        const featureEnabled = !opt.featureName || getIsOrgHasFeature(state, opt.featureName);

        return opt.getIsAvailable({ analysisTypeCode, featureEnabled });
    });
};
