export const METRIC_TYPES = {
    traffic: {
        id: "traffic",
        name: "Traffic",
        chartTitle: "Traffic Distribution",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        dataField: "bins",
    },
    congestion: {
        id: "congestion",
        name: "Congestion",
        chartTitle: "Congestion",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        dataField: "bins",
    },
    speed: {
        id: "speed",
        name: "Speed",
        chartTitle: "Segment Speed",
        aggregation: "Avg",
        chartType: "timeDistributionPctl",
        ignoredFilters: ["visualization_property"],
        dataField: "percentiles",
    },
    travel_time: {
        id: "travel_time",
        name: "Travel Time",
        chartTitle: "Travel Time",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        dataField: "bins",
    },
};
export const METRIC_TYPES_LIST = Object.values(METRIC_TYPES);
export type TMetricTypesKey = keyof typeof METRIC_TYPES;
export type TMetricType = typeof METRIC_TYPES[keyof typeof METRIC_TYPES];

export const SEGMENT_DISTRIBUTION_METRIC_KEY = "distributionMetric";
