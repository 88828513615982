import { ForwardedRef, forwardRef, CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/pro-solid-svg-icons";
import "./divider.less";

const NoOp = () => {};

type TProps = {
    onMouseDown?: (event: any) => void;
    style?: CSSProperties;
};

export const Divider = forwardRef(
    (
        { onMouseDown, style }: TProps = {
            onMouseDown: NoOp,
            style: {},
        },
        ref: ForwardedRef<HTMLDivElement>,
    ) => (
        <div className="stl-divider" style={style} ref={ref}>
            <div className="divider-handle" role="presentation" onMouseDown={onMouseDown}>
                <FontAwesomeIcon icon={faGripLinesVertical} />
            </div>
        </div>
    ),
);
