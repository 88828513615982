import { OSM_VINTAGE } from "@app/store/staticData/state/staticData.constants";
import {
    IAnalysisZonesAllLimits,
    IKeywordsStringByAnalysisId,
    IOSMVintageDate,
    IVintageDatesByYear,
    TAnalysesFilterData,
} from "@app/store/staticData/state/staticData.types";
import {
    IAvailableDataPeriodAPI,
    ISATCAvailableDataPeriodAPI,
} from "@common/services/server/analysesApi.types";

export interface IStaticDataState {
    osmLayersVintageDate: IOSMVintageDate;
    zoneVintageDates: IVintageDatesByYear;
    analysisTypesKeywords: IKeywordsStringByAnalysisId;
    availableDataPeriods: Array<IAvailableDataPeriodAPI>;
    satcAvailableDataPeriods: Array<ISATCAvailableDataPeriodAPI>;
    analysesMetadata: TAnalysesFilterData;
    analysesZonesLimits: IAnalysisZonesAllLimits | null;
}

export const STATIC_DATA_STATE = {
    availableDataPeriods: [],
    satcAvailableDataPeriods: [],
    zoneVintageDates: {},
    osmLayersVintageDate: OSM_VINTAGE,
    analysisTypesKeywords: {},
    analysesMetadata: {},
    analysesZonesLimits: null,
} as IStaticDataState;
