import { CHOOSE_ZONES_INITIAL_STATE } from "./chooseZones.state";
import { CHOOSE_ZONES_ACTION_TYPES } from "./chooseZones.actionTypes";
import { isFieldsInvalid } from "./chooseZones.helpers";

export const chooseZonesReducer = (state = CHOOSE_ZONES_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case CHOOSE_ZONES_ACTION_TYPES.SET_ZONE_ROLE: {
            return {
                ...state,
                zoneRole: data.zoneRole,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_ZONE_TYPE: {
            return {
                ...state,
                zoneType: data.zoneType,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_LOCATION: {
            return {
                ...state,
                location: data.location,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_ROADS: {
            return {
                ...state,
                roads: data.roads,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.UPDATE_SELECTED_ZONES: {
            return {
                ...state,
                selectedZones: {
                    ...state.selectedZones,
                    [data.zoneType]: data.selectedZones,
                },
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_SELECTED_ZONES: {
            return {
                ...state,
                selectedZones: data.selectedZones,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_MAP_ERROR: {
            return {
                ...state,
                mapError: data.mapError,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_HOVERED_ZONE: {
            return {
                ...state,
                hoveredZone: data.zone,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_HOVERED_DAC_ZONE: {
            return {
                ...state,
                hoveredDacZone: data.zone,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_HOVERED_BUS_ZONES: {
            return {
                ...state,
                hoveredBusZones: data.zones,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_WIZARD_MODAL_STATE: {
            return {
                ...state,
                isWizardModalOpen: data.isWizardModalOpen,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.UPDATE_UI_STATES:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    ...data,
                },
            };

        case CHOOSE_ZONES_ACTION_TYPES.SET_SHOULD_CREATE_ZONE_SET: {
            return {
                ...state,
                shouldCreateZoneSet: data.shouldCreateZoneSet,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_IPF_CALIBRATION: {
            return {
                ...state,
                ipfCalibrationSettings: {
                    ...state.ipfCalibrationSettings,
                    ...data.ipfCalibrationSettings,
                },
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_INITIAL_DATA: {
            return {
                ...state,
                ...data,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.RESET_SELECTED_ZONES: {
            return {
                ...state,
                selectedZones: CHOOSE_ZONES_INITIAL_STATE.selectedZones,
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.RESET_STATE: {
            return CHOOSE_ZONES_INITIAL_STATE;
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_VALIDATION: {
            const newFields = {
                ...state.validation.fields,
                ...data.validation,
            };

            return {
                ...state,
                validation: {
                    fields: newFields,
                    isInvalid: isFieldsInvalid(newFields),
                },
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_SHOW_ZONES_BY_ROLE: {
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    showZonesByRole: {
                        ...state.uiStates.showZonesByRole,
                        [data.zoneRole]: !state.uiStates.showZonesByRole[data.zoneRole],
                    },
                },
            };
        }

        case CHOOSE_ZONES_ACTION_TYPES.SET_LOADING_ZONE_TABLE: {
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    loadingZoneTables: {
                        ...state.uiStates.loadingZoneTables,
                        [data.zoneRole]: data.isLoading,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
};
