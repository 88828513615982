export const createButton = ({
    className,
    tooltip,
    onClick,
}: {
    tooltip: string;
    className: string;
    onClick?: (event: Event) => void;
}) => {
    const button = window.document.createElement("button");

    button.className = className;
    button.title = tooltip;
    button.ariaLabel = tooltip;
    if (onClick) button.addEventListener("click", onClick, false);

    return button;
};

export const createImage = ({ src, alt }: { src: string; alt: string }) => {
    const img = window.document.createElement("img");

    img.src = src;
    img.alt = alt;

    return img;
};

export const createMask = ({ className }: { className: string }) => {
    const div = window.document.createElement("div");

    div.className = className;

    return div;
};
