import { faDrawPolygon } from "@fortawesome/pro-solid-svg-icons";

export const MIN_INTERACTIVE_ZOOM = {
    DEFAULT: 9,
    OSM: 13,
    RAIL: 8,
    LINE_GATES: 13,
};

export const OSM_LAYERS = {
    OSM_VEHICLE: {
        code: "vehicle",
        layerCode: "OSM_VEHICLE",
        display: "Vehicle",
        legend: {
            style: "solid #FF00A8",
        },
        features: [
            "personal_all_vehicle_travel",
            "commercial_truck_travel",
            "personal_gps_travel",
        ],
    },
    OSM_BICYCLE: {
        code: "bicycle",
        layerCode: "OSM_BICYCLE",
        display: "Bicycle",
        legend: {
            style: "dashed #00F0FF",
        },
        features: ["personal_bike_travel"],
    },
    OSM_PEDESTRIAN: {
        code: "pedestrian",
        layerCode: "OSM_PEDESTRIAN",
        display: "Pedestrian",
        legend: {
            style: "dotted #FF7A00",
        },
        features: ["personal_ped_travel"],
    },
} as const;

export const OSM_LAYERS_LIST = Object.values(OSM_LAYERS);
export type TOSMLayer = typeof OSM_LAYERS[keyof typeof OSM_LAYERS];

export const OSM_LAYER_STYLES = {
    [OSM_LAYERS.OSM_VEHICLE.layerCode]: {
        line: {
            "line-color": "#FF00A8",
            "line-width": 2,
            "line-opacity": 1,
        },
    },
    [OSM_LAYERS.OSM_BICYCLE.layerCode]: {
        line: {
            "line-color": "#00F0FF",
            "line-width": 1.5,
            "line-opacity": 1,
            "line-dasharray": [8, 4],
            "line-offset": 4,
        },
    },
    [OSM_LAYERS.OSM_PEDESTRIAN.layerCode]: {
        line: {
            "line-color": "#FF7A00",
            "line-width": 2,
            "line-opacity": 1,
            "line-dasharray": [4, 4],
            "line-offset": -4,
        },
    },
};

export const ENHANCED_LAYERS = {
    DAC_ZONES: {
        code: "dac_zones",
        layerCode: "DAC_ZONES",
        display: "DAC Zones",
        icon: faDrawPolygon,
        style: {
            color: "#C970FF",
        },
        features: [],
        helpIcon: "enhanced-layers-dac-zones-info-icon",
        helpLink: "https://screeningtool.geoplatform.gov/",
    },
};

export const ENHANCED_LAYERS_LIST = Object.values(ENHANCED_LAYERS);
export type TEnhancedLayer = typeof ENHANCED_LAYERS[keyof typeof ENHANCED_LAYERS];

export const DAC_ZONES_BURDEN_CATEGORIES = {
    N_CLT: {
        code: "N_CLT",
        name: "Climate change",
    },
    N_ENY: {
        code: "N_ENY",
        name: "Energy",
    },
    N_HLTH: {
        code: "N_HLTH",
        name: "Health",
    },
    N_HSG: {
        code: "N_HSG",
        name: "Housing",
    },
    N_PLN: {
        code: "N_PLN",
        name: "Legacy pollution",
    },
    N_TRN: {
        code: "N_TRN",
        name: "Transportation",
    },
    N_WTR: {
        code: "N_WTR",
        name: "Water and wastewater",
    },
    N_WKFC: {
        code: "N_WKFC",
        name: "Workforce development",
    },
};
export type TDACZonesBurdenCategoryKey = keyof typeof DAC_ZONES_BURDEN_CATEGORIES;

export const MIN_INTERACTIVE_ZOOM_FOR_DAC_ZONES = 7;

export const DAC_ZONES_STYLE = {
    POLYGON: {
        COLOR: "#C970FF",
        OPACITY: 0.2,
    },
    LINE: {
        WIDTH: 3,
        COLOR: "#C970FF",
    },
};

export type TMapLayersLocalState = {
    osmLayersCategories: Record<string, string[]>;
    enhancedLayers: Array<TEnhancedLayer["code"]>;
};
