import { MAP_STYLES } from "@common/features/zoneSets/zoneSetMap/zoneSetMap.constants";
import { COLORS } from "@common/constants/color.constants";

export const ZONE_SET_MODES = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    VIEW: "VIEW",
    UPLOAD: "UPLOAD",
} as const;

export const ZONE_SET_SCREEN_MODES = {
    ZONE_SET_NAME: "ZONE_SET_NAME",
    CREATE_ZONE_SET: "CREATE_ZONE_SET",
    EDIT_ZONE_SET: "EDIT_ZONE_SET",
    VIEW_ZONE_SET: "VIEW_ZONE_SET",
    START_UPLOAD_ZONES: "START_UPLOAD_ZONES",
    EDIT_UPLOAD_ZONES: "EDIT_UPLOAD_ZONES",
    EDIT_UPLOAD_ZONES_CALIBRATION: "EDIT_UPLOAD_ZONES_CALIBRATION",
    VIEW_UPLOAD_ZONE_SET: "VIEW_UPLOAD_ZONE_SET",
} as const;

export const ZONE_SET_SCREEN_MODE_BY_VIEW_MODE = {
    [ZONE_SET_MODES.CREATE]: ZONE_SET_SCREEN_MODES.ZONE_SET_NAME,
    [ZONE_SET_MODES.VIEW]: ZONE_SET_SCREEN_MODES.VIEW_ZONE_SET,
    [ZONE_SET_MODES.EDIT]: ZONE_SET_SCREEN_MODES.EDIT_ZONE_SET,
    [ZONE_SET_MODES.UPLOAD]: ZONE_SET_SCREEN_MODES.START_UPLOAD_ZONES,
} as const;

export const OTHER_ZONE_SETS_STYLE = { color: COLORS.RED, opacity: MAP_STYLES.OPACITY.ACTIVE };
