import { useAppSelector } from "@app/store/hooks";
import { getOSMLayersVintageDate } from "@app/store/staticData/state/staticData.selectors";
import { NestedCheckbox } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/nestedCheckbox";
import { TOSMLayer } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { IZoneVintage } from "@app/analysis/zones/chooseZones/state/chooseZones.types";
import { getOSMLayerLabel } from "@app/store/staticData/state/staticData.helpers";
import { IOsmCategoryValuesAndData } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/useOsmCheckboxValues";

const OsmOptionLabelWithLegend = ({
    option,
    osmVintageDate,
}: {
    option: Pick<TOSMLayer, "display" | "legend">;
    osmVintageDate: IZoneVintage;
}) => {
    return (
        <>
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: 5,
                    width: 15,
                    borderBottom: `3px ${option.legend.style}`,
                }}
            />
            <span>{getOSMLayerLabel(option.display, osmVintageDate)}</span>
        </>
    );
};

type TProps = {
    values: IOsmCategoryValuesAndData[];
    onChange: ({ code, isChecked }: { code: string; isChecked: boolean }) => void;
};

export const MapOSMLayersPicker = ({ values, onChange }: TProps) => {
    const osmLayersVintageDate: IZoneVintage = useAppSelector(getOSMLayersVintageDate);

    return (
        <div className="stl-osm-layers-picker">
            {values.map(osmGroup => (
                <NestedCheckbox
                    key={osmGroup.value}
                    value={osmGroup.value}
                    checked={osmGroup.checked}
                    showCheckbox={values.length > 1}
                    expanded={osmGroup.expanded}
                    getLabel={() => (
                        <OsmOptionLabelWithLegend
                            option={osmGroup.labelData}
                            osmVintageDate={osmLayersVintageDate}
                        />
                    )}
                    onChange={onChange}
                    nestedCheckboxesData={osmGroup.nestedCheckboxesData}
                />
            ))}
        </div>
    );
};
