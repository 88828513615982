import { HttpService, IRequestConfig } from "@common/services/server/http.service";
import { loggerService } from "@common/services/logger.service";
import {
    ICopyZoneSetData,
    IGetBusRailAgenciesData,
    ICreateZoneSetsFromShapefileData,
    IUpdateZoneInZoneSetData,
    IUpdateZoneSetData,
    IZoneSetsResponse,
    IDeleteZoneSetResponse,
    IZoneSetResponse,
    TGetZoneSetsParams,
    IGetZoneSetByIdParams,
    ICreateZoneSetsFromShapefileResponse,
    ICreateNewZoneSetData,
    IZoneVersionIDs,
    ICreateZoneGatesData,
    ICreateZoneGatesResponse,
    IPolygonZoneAreaData,
    IPolygonZoneAreaResponse,
    IDeleteZonesData,
    IZoneSetAPI,
    IReverseZoneData,
    IUploadZonesFromShapefileData,
    IUploadZonesFromShapefileResponse,
    IGetBusRailAgenciesResponse,
    IValidateZoneSetNameResponse,
    IValidateZoneSetName,
    IZoneResponse,
    IUpdateZoneInputs,
    ICopyZoneData,
    IUpdateZoneSetResponse,
    IGetZoneResponse,
    IBulkUpdateAnalysisZonesDirectionData,
    IBulkReverseAnalysisZonesDirectionData,
    IBulkUpdateZoneSetZonesDirectionData,
    IBulkReverseZoneSetZonesDirectionData,
    IBulkUpdateAnalysisZonesDirectionResponse,
    IBulkUpdateZoneSetZonesDirectionResponse,
    IZoneVintageDatesResponse,
    IGetZonesByZoneKindIdResponse,
    ISaveNewZoneInputs,
    IGetSpotCounterGateDirectionResponse,
    ISpotCounterGateDirectionData,
    IBulkCreateCustomGatesFromLineZonesData,
    IBulkCreateCustomGatesFromLineZonesResponse,
} from "./zonesApi.types";

// Updates a zone set with data from its zones if it is in Processing status as its data can be stale.
const refreshZoneSet = (zoneSet: IZoneSetAPI): void => {
    const _zoneSet = { ...zoneSet };

    if (zoneSet && _zoneSet.zones && _zoneSet.status === "Processing") {
        // Update zone set with_calibration as it may be stale.
        _zoneSet.with_calibration = _zoneSet.zones.some(zone => zone.with_calibration);
    }
};

// An interceptor for responses containing a zone set to refresh its data if needed.
const refreshZoneSetResponseInterceptor = <T extends { zone_set: IZoneSetAPI }>(res: T): T => {
    if (res.zone_set) {
        refreshZoneSet(res.zone_set);
    }
    return res;
};

export const ZonesApiService = {
    getZoneVintageDates() {
        return HttpService.get<IZoneVintageDatesResponse>("/zone/zone_vintage_dates").catch(
            HttpService.showIfError("Unknown error occurred while fetching zone vintage dates."),
        );
    },
    createZone(zoneKindId: number, data: ISaveNewZoneInputs) {
        return HttpService.post<IZoneResponse>(`/zone/${zoneKindId}`, data).catch(
            HttpService.showIfError("Unknown error occurred while creating zone."),
        );
    },
    getZoneSets(config?: IRequestConfig<TGetZoneSetsParams>): Promise<IZoneSetsResponse> {
        return HttpService.get<IZoneSetsResponse>("/sets", config).catch(
            HttpService.showIfError("Unknown error occurred while receiving zone sets."),
        );
    },
    getZoneSetById(
        zoneSetId: number,
        config: IRequestConfig<IGetZoneSetByIdParams>,
    ): Promise<IZoneSetResponse> {
        return HttpService.get<IZoneSetResponse>(`/sets/${zoneSetId}`, config).then(
            refreshZoneSetResponseInterceptor,
        );
    },
    getZoneArea(data: IPolygonZoneAreaData) {
        return HttpService.post<IPolygonZoneAreaResponse>("/zone/area", data).catch(
            HttpService.showIfError("Unknown error occurred while creating zone gates."),
        );
    },
    updateZone(zoneId: number, zoneKindId: number, data: IUpdateZoneInputs) {
        return HttpService.put<IZoneResponse>(`/zone/${zoneKindId}/${zoneId}`, data).catch(
            HttpService.showIfError("Unknown error occurred while creating zone."),
        );
    },
    updateZoneSet(
        zoneSetId: number,
        data: Partial<IUpdateZoneSetData>,
    ): Promise<IUpdateZoneSetResponse> {
        // For investigation, try to catch a bug when 'undefined' zoneSetId param is passed
        if (!zoneSetId) {
            loggerService.error("Missing 'zoneSetId' param", JSON.stringify(data));
            return Promise.reject({
                showIfError: "Unable to update zone set: missing 'zoneSetId' param.",
            });
        }
        return HttpService.put<IUpdateZoneSetResponse>(`/sets/${zoneSetId}`, data)
            .then(refreshZoneSetResponseInterceptor)
            .catch(HttpService.showIfError("Unknown error occurred while updating zone set."));
    },
    updateZoneInZoneSet(
        zoneSetId: number,
        zoneId: number,
        data: Partial<IUpdateZoneInZoneSetData>,
    ): Promise<IZoneSetResponse> {
        return HttpService.post<IZoneSetResponse>(`/zone/edit/${zoneSetId}/${zoneId}`, data)
            .then(refreshZoneSetResponseInterceptor)
            .catch(HttpService.showIfError("Unknown error occurred while updating zone."));
    },
    createZoneGates(data: ICreateZoneGatesData) {
        return HttpService.post<ICreateZoneGatesResponse>("/zone/gate", data).catch(
            HttpService.showIfError("Unknown error occurred while creating zone gates."),
        );
    },
    createNewZoneSet(data: ICreateNewZoneSetData): Promise<IZoneSetResponse> {
        return HttpService.post<IZoneSetResponse>("/sets", data)
            .then(refreshZoneSetResponseInterceptor)
            .catch(HttpService.showIfError("Unknown error occurred while saving zone set."));
    },
    validateZoneSetName(data: IValidateZoneSetName): Promise<IValidateZoneSetNameResponse> {
        return HttpService.post<IValidateZoneSetNameResponse>("/sets/validate", data).catch(
            HttpService.showIfError("Unknown error occurred while validate zone set name."),
        );
    },
    createZoneSetsFromShapefile(
        data: Partial<ICreateZoneSetsFromShapefileData>,
    ): Promise<ICreateZoneSetsFromShapefileResponse> {
        return HttpService.post<ICreateZoneSetsFromShapefileResponse>(
            "/sets_from_shapefile",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while saving zone set."));
    },
    uploadZonesFromShapefile(
        data: Partial<IUploadZonesFromShapefileData>,
    ): Promise<IUploadZonesFromShapefileResponse> {
        return HttpService.post<IUploadZonesFromShapefileResponse>(
            "/zones_from_shapefile",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while saving zone set."));
    },
    deleteZoneSet(zoneSet: IZoneSetAPI): Promise<IDeleteZoneSetResponse> {
        return HttpService.delete<IDeleteZoneSetResponse>(`/sets/${zoneSet.set_id}`)
            .then(HttpService.showIfSuccess(`Zone Set ${zoneSet.set_name} deleted successfully.`))
            .catch(HttpService.showIfError("Unknown error occurred while deleting zone set."));
    },
    deleteZonesFromZoneSet(zoneSetId: number, data: IDeleteZonesData): Promise<IZoneSetResponse> {
        return HttpService.post<IZoneSetResponse>(`/sets/delete_zone/${zoneSetId}`, data)
            .then(refreshZoneSetResponseInterceptor)
            .catch(HttpService.showIfError("Unknown error occurred while deleting zones."));
    },
    copyZone(zoneKindId: number, zoneId: number, data: ICopyZoneData) {
        return HttpService.post(`/zone/copy/${zoneKindId}/${zoneId}`, data).catch(
            HttpService.showIfError("Unknown error occurred while copying zone."),
        );
    },
    copyZoneInZoneSet(
        zoneSetId: number,
        zoneKindId: number,
        zoneId: number,
        data: IZoneVersionIDs,
    ) {
        return HttpService.post(`/zone/copy/${zoneSetId}/${zoneKindId}/${zoneId}`, data).catch(
            HttpService.showIfError("Unknown error occurred while copying zone in zone set."),
        );
    },
    reverseLineZone(data: IReverseZoneData): Promise<IReverseZoneData> {
        return HttpService.post<IReverseZoneData>("/zone/reverse", data).catch(
            HttpService.showIfError("Unknown error occurred while reversing zone."),
        );
    },
    copyZoneSet(zoneSetId: number, data: ICopyZoneSetData): Promise<IZoneSetResponse> {
        return HttpService.post<IZoneSetResponse>(`/sets/copy/${zoneSetId}`, data).catch(
            HttpService.showIfError("Unknown error occurred while copying zone set."),
        );
    },
    getDownloadZoneSetUrl: (zoneSet: IZoneSetAPI): string =>
        `/server/export/set/${zoneSet.set_id}`,
    getBusAgencies(data: IGetBusRailAgenciesData) {
        return HttpService.post<IGetBusRailAgenciesResponse>("/zone/bus_agencies", data).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching bus agencies and lines.",
            ),
        );
    },
    getRailAgencies(data: IGetBusRailAgenciesData) {
        return HttpService.post<IGetBusRailAgenciesResponse>("/zone/rail_agencies", data).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching rail agencies and lines.",
            ),
        );
    },
    getZonesByZoneKindId(
        zoneKindId: number,
        config?: IRequestConfig,
    ): Promise<IGetZonesByZoneKindIdResponse> {
        return HttpService.get<IGetZonesByZoneKindIdResponse>(
            `/zones/${zoneKindId}`,
            config,
        ).catch(HttpService.showIfError("Unknown error occurred while fetching zones."));
    },
    getZoneById(
        zoneKindId: number,
        zoneId: number,
        config?: IRequestConfig,
    ): Promise<IGetZoneResponse> {
        return HttpService.get<IGetZoneResponse>(`/zone/${zoneKindId}/${zoneId}`, config).catch(
            HttpService.showIfError("Unknown error occurred while fetching zone info."),
        );
    },
    getSpotCounterGateDirection(data: ISpotCounterGateDirectionData) {
        return HttpService.post<IGetSpotCounterGateDirectionResponse>(
            "/zone/gate_direction",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while updating gate direction."));
    },
    bulkUpdateAnalysisZonesDirection(data: IBulkUpdateAnalysisZonesDirectionData) {
        return HttpService.post<IBulkUpdateAnalysisZonesDirectionResponse>(
            "/zone/bulk_action/direction",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while updating zones direction."));
    },
    bulkReverseAnalysisZonesDirection(data: IBulkReverseAnalysisZonesDirectionData) {
        return HttpService.post<IBulkUpdateAnalysisZonesDirectionResponse>(
            "/zone/bulk_action/reverse",
            data,
        ).catch(
            HttpService.showIfError("Unknown error occurred while reversing zones direction."),
        );
    },
    bulkUpdateZonesDirection(zoneSetId: number, data: IBulkUpdateZoneSetZonesDirectionData) {
        return HttpService.post<IBulkUpdateZoneSetZonesDirectionResponse>(
            `/sets/${zoneSetId}/bulk_action/direction`,
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while updating zones direction."));
    },
    bulkReverseZonesDirection(zoneSetId: number, data: IBulkReverseZoneSetZonesDirectionData) {
        return HttpService.post<IBulkUpdateZoneSetZonesDirectionResponse>(
            `/sets/${zoneSetId}/bulk_action/reverse`,
            data,
        ).catch(
            HttpService.showIfError("Unknown error occurred while reversing zones direction."),
        );
    },
    bulkCreateCustomGatesFromLineZones(data: IBulkCreateCustomGatesFromLineZonesData) {
        return HttpService.post<IBulkCreateCustomGatesFromLineZonesResponse>(
            "/zone/bulk_create_gate_from_line_zones",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while creating zone gates."));
    },
};
