import { validateTripBins } from "@app/analysis/addOns/components/tripParams/tripParams.actions";
import { getTripBinDefaults } from "@app/analysis/addOns/components/tripParams/tripParams.helpers";
import {
    resetAddOnsReducer,
    setAddOnsInitialData,
    setAddOnsValidation,
    setBypassPrivacyCheckSetting,
    setBypassSizeValidationCheckSetting,
    setSelectedGeographyType,
    setTravelerAttrEnabled,
    setTripAttrEnabled,
    toggleHwlSetting,
} from "@app/analysis/addOns/state/addOns.actions";
import {
    ADD_ONS_TAB_SECTIONS,
    HWL_ZONE_INTERSECTION_TYPES,
    SEGMENT_TYPES_LIST,
    TRAVELER_PARAMS_US_CENSUS_2020,
    TRIP_BIN_TYPES,
    TRIP_BIN_TYPES_LIST,
} from "@app/analysis/addOns/state/addOns.constants";
import {
    analysisHasOption,
    getAddOnsValidation,
    getAdminSettings,
    getAnalysisAddOns,
    getDefaultGeogTypeCode,
} from "@app/analysis/addOns/state/addOns.selectors";
import { INITIAL_TRIP_BIN_UI_STATE } from "@app/analysis/addOns/state/addOns.state";
import {
    resetBasicsReducer,
    setAADTCalibrationYear,
    setAnalysisMeasurementUnit,
    setAvailableCalibrationCodes,
    setBasicsInitialData,
    setBasicsValidation,
    setCalibrationCode,
    setTravelModeCode,
    updateAnalysisData,
    validateAnalysisName,
} from "@app/analysis/basics/state/basics.actions";
import {
    analysisCalibrationInfoSelector,
    getAnalysisCountry,
    getBasics,
    getBasicsValidation,
    getCalibrationSettings,
    getDefaultCalibrationCode,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/basics/state/basics.selectors";
import { getDraft } from "@app/analysis/draftAnalysis/state/draftAnalysis.actions";
import { getDraftState } from "@app/analysis/draftAnalysis/state/draftAnalysis.selectors";
import {
    resetReviewReducer,
    setReviewInitialData,
} from "@app/analysis/review/state/review.actions";
import { getReview } from "@app/analysis/review/state/review.selectors";
import {
    enablePartialDataMonth,
    getAvailableDataMonthsByMode,
    getDefaultDataMonthsByMode,
    getDefaultDataPeriodsByMode,
    getKFactorDefaultDataMonths,
    getZonesLimitValidation,
} from "@app/analysis/state/analysisConfiguration.selectors";
import * as generalActions from "@app/analysis/state/general/general.actions";
import {
    analysisTypeObjSelector,
    getAnalysisTypeCode,
    getCensusMixingPermitted,
    getGeneral,
    getGMCVDFeatureState,
    getIs15MinuteBinsModeAvailable,
    getIsUpsamplingAvailable,
    getIsZAHWLAnalysis,
    getProjectFolder,
    getUiStates,
} from "@app/analysis/state/general/general.selectors";
import { validateDataPeriodsField } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.actions";
import {
    setDayPartKind,
    setDayParts,
    validateDayParts,
} from "@app/analysis/timePeriods/components/dayPartParams/dayPartParams.actions";
import { setDayTypes } from "@app/analysis/timePeriods/components/dayTypeParams/dayTypeParams.actions";
import {
    cancelAllEditDataPeriods,
    resetTimePeriodsReducer,
    setDataMonths,
    setDefaultAADTYear,
    setTimePeriodsInitialData,
    setTimePeriodsValidation,
    validateDataMonthsSection,
} from "@app/analysis/timePeriods/state/timePeriods.actions";
import {
    DAY_CODES,
    DAY_PARTS,
    DAY_PARTS_KINDS,
    DAY_TYPES,
    DAY_TYPES_KINDS,
    getDayShortNameFromCode,
    TIME_PERIODS_TAB_SECTIONS,
    TMC_DEFAULT_15_MINUTE_BINS,
} from "@app/analysis/timePeriods/state/timePeriods.constants";
import {
    checkDatesInsidePartialDataPeriod,
    dayPartsStateToData,
    getDataMonths,
    getDataPeriods,
    getDayFromShortName,
    getDayPartKindByDayParts,
    getDayTypeKindByDayTypes,
    getDefaultDayTypes,
    getIsDataPeriodsMixedWithCensus2020,
    getParsedExcludedDateRanges,
    getPartialMonthsRange,
    getWeekdayTypeByDayTypes,
    prepareExcludedDateRanges,
    processExcludedDates,
} from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    dayTypesToCodeSelector,
    getAadtSettings,
    getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes,
    getIsUSAnalysisWithOnlyCensus2020Dates,
    getIsUSCensus2020TravelerParams,
    getTimePeriods,
    getTimePeriodsValidation,
    getWeekdayType,
    isDateRangeFormat,
    serializedSelectedDataMonthsSelector,
    serializedSelectedDataPeriodsSelector,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { TIME_PERIODS_INITIAL_STATE } from "@app/analysis/timePeriods/state/timePeriods.state";
import { createValidateField } from "@app/analysis/validation";
import {
    getIPFValuesFromAnalysis,
    validateIPFCalibrationValues,
} from "@app/analysis/zones/chooseZones/base/sidebar/ipfCalibrationSection/ipfCalibrationSection.helpers";
import {
    resetTMCChooseZonesReducer,
    setTMCInitialData,
    setValidatedIntersections,
    validateAnalysisIntersections,
} from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.actions";
import { validateIntersections } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.helpers";
import {
    getApiIntersectionZones,
    getIntersectionZonesList,
} from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.selectors";
import {
    resetReducer as resetChooseZonesReducer,
    setIPFCalibrationSettings,
    setShouldCreateZoneSet,
    setZonesInitialData as setChooseZonesInitialData,
    setZonesValidation,
} from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import {
    getZonesFromAnalysis,
    validateCalibrationZones,
    validateODGZonesLocation,
    validateSelectedZones,
    validateUserCountsCalibrationZones,
} from "@app/analysis/zones/chooseZones/state/chooseZones.helpers";
import {
    getApiSelectedZones,
    getIPFCalibrationSettings,
    getSelectedZones,
    getShouldCreateZoneSet,
    getZonesValidation,
} from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import { getAnalyses } from "@app/bigBang/analyses/state/analyses.selectors";
import { getSelectedProjectFolder } from "@app/bigBang/projects/state/projects.selectors";
import { fetchOrgBalance } from "@app/store/currentUser/currentUser.actions";
import { getUserPreferredCountry } from "@app/store/currentUser/currentUser.helpers";
import {
    getAvailableAADTCalibrationYears,
    getAvailableCalibrations,
    getAvailableDataYears,
    getConfigParams,
    getIsOrgHasFeature,
    getIsSandbox,
    getIsSuperUser,
    getPartialDataPeriods,
    getUserOrg,
} from "@app/store/currentUser/currentUser.selector";
import {
    getApplicationPreferences,
    getPreferredCountry,
    getPreferredWeekdayType,
} from "@app/store/userPreferences/userPreferences.selector";
import { LightboxService } from "@common/components/lightbox/lightbox.service";
import { StlNotification } from "@common/components/snackbar/snackbar";
import {
    ANALYSIS_CENSUS_TYPES,
    ANALYSIS_STATUSES,
    ANALYSIS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
} from "@common/constants/analysis.constants";
import { ORG_TYPES } from "@common/constants/orgTypes.constants";
import { ZoneRoles } from "@common/features/zones/zones.types";
import {
    setMapMode,
    setSelectedZone,
    setUiState,
} from "@common/features/zonesManager/state/zonesManager.actions";
import {
    DEFAULT_ZONE_TYPE,
    MANAGER_MODES,
    ZONES_MANAGER_CONTROLS,
} from "@common/features/zonesManager/state/zonesManager.constants";
import {
    getIsAllVehiclesOrTruckTravelMode,
    getIsBikeOrPedTravelMode,
    getIsNetworkPerformanceAnalysis,
    getIsTMCAnalysis,
} from "@common/helpers/analysis";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import { OrgApiService } from "@common/services/server/orgApi.service";
import moment from "moment";
import { batch } from "react-redux";

import {
    AADT_CALIBRATIONS,
    ALL_VEHICLES_TOMTOM_SUPPORTED_ANALYSES,
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
    DEFAULT_AADT_YEAR,
    DEFAULT_AADT_YEAR_CA,
    DEFAULT_PRESET_GEOG_TYPE,
    PRESET_GEOG_TYPES_LIST,
    ROUTE_OPTIONS,
    SCREEN_MODES,
    SCREEN_MODES_LIST,
    TABS,
    TRAVEL_MODES,
    ZONES_MODES,
} from "./analysisConfiguration.constants";
import {
    binsToArray,
    binsToString as defaultBinsToString,
    getAnalysisCalibrationCode,
    getAnalysisCustomDateRanges,
    getAnalysisFeatureName,
    getAnalysisTravelModeFromCode,
    getAnalysisType,
    getAnalysisTypeFromCode,
    getAnalysisZonesMode,
    getAvailableDataMonthsForCopyWithBikePedMode,
    getCopiedAnalysisName,
    getCopyAnalysisValidation,
    getCurrentTabIndex,
    getIsAllTripAttributesDisabled,
    getIsAnalysisWithDeprecatedTravelModes,
    getIsEnableUpsampling,
    getIsIPFCalibrationSelected,
    getProjectTypeValueForCreatePayload,
    getTravelModeValueForCreatePayload,
    getUniqueZoneDetailsPayload,
    isPartialDataPeriod,
} from "./analysisConfiguration.helpers";

const validateTravelModeCodeField = code => {
    const travelModeCodeError =
        code === "" ? "No travel mode is selected. Please select a travel mode" : "";

    return createValidateField(travelModeCodeError);
};

const validateRouteOptionsField = routeOptions => {
    let routeOptionsError = "";

    const selected = Object.keys(routeOptions).filter(option => routeOptions[option]);
    if (!selected.length) {
        routeOptionsError =
            "No route segment type is selected. Analysis must have at least one route segment type";
    }
    return createValidateField(routeOptionsError);
};

const validateHwlFilterOptionsField = (hwl, hwlFilterOptions) => {
    let hwlFilterError = "";

    const selected = Object.keys(hwlFilterOptions).filter(option => hwlFilterOptions[option]);
    if (hwl && !selected.length) {
        hwlFilterError = "Analysis must have at least one home and work location filter checked";
    }
    return createValidateField(hwlFilterError);
};

const validateOutputTypeField = outputType => {
    const outputTypeError = !outputType ? "No output type is selected" : "";

    return createValidateField(outputTypeError);
};

const validateTripAttributes = (newAddOnsValidation, tripAttributesSettings) => {
    TRIP_BIN_TYPES_LIST.forEach(binType => {
        const bin = tripAttributesSettings[binType.code];

        const tripAttributesError =
            tripAttributesSettings.enabled && tripAttributesSettings[binType.uiStateName].enabled
                ? validateTripBins(bin, binType)
                : "";
        const binName = `${binType.uiStateName}Bins`;

        // eslint-disable-next-line no-param-reassign
        newAddOnsValidation[binName] = createValidateField(tripAttributesError);
    });
};

const validateSegmentAttributes = (newAddOnsValidation, tripAttributesSettings) => {
    SEGMENT_TYPES_LIST.forEach(segmentType => {
        const bin = tripAttributesSettings[segmentType.binType.code];

        const segmentAttributesError = validateTripBins(bin, segmentType.binType);
        const binName = `${segmentType.binType.uiStateName}Bins`;

        // eslint-disable-next-line no-param-reassign
        newAddOnsValidation[binName] = createValidateField(segmentAttributesError);
    });
};

export const updateAnalysis = (updatedData, analysisVersionId) => dispatch => {
    batch(() => {
        dispatch(updateAnalysisData(updatedData));
        dispatch(generalActions.updateAnalysisVersionId(analysisVersionId));
    });
};

// Set default Day Types and Parts for provided analysis type
export const setDefaultDayTypesAndParts =
    (analysisTypeCode, isCreateAnalysisMode, config) => (dispatch, getState) => {
        const weekdayType = getWeekdayType(getState());

        batch(() => {
            const analysisType = getAnalysisTypeFromCode(analysisTypeCode);
            if (
                analysisType.supportsNewDayTypesAndParts &&
                isCreateAnalysisMode &&
                !config?.skipTimePeriodsReset
            ) {
                const isTtTmcAnalysis = analysisTypeCode === CREATE_ANALYSIS_TYPES.TT_TMC.code;

                const defaultDayTypes = getDefaultDayTypes(
                    DAY_TYPES_KINDS.DAY_RANGES.id,
                    weekdayType.id,
                );
                // Default Day Types/Parts should be set only when creating analysis from scratch
                const defaultDayParts = isTtTmcAnalysis
                    ? TMC_DEFAULT_15_MINUTE_BINS
                    : DAY_PARTS_KINDS.DAY_PART_RANGES.defaultOptions;

                dispatch(setDayTypes(defaultDayTypes));
                dispatch(setDayParts(defaultDayParts, isTtTmcAnalysis));
            } else {
                switch (analysisTypeCode) {
                    case CREATE_ANALYSIS_TYPES.TMC.code:
                        // Default Day Parts should be set only when creating analysis from scratch
                        if (isCreateAnalysisMode) {
                            dispatch(setDayParts(DAY_PARTS.getTMCAnalysisDefault()));
                        }
                        break;
                    case CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code:
                        dispatch(setDayTypes([DAY_TYPES.ALL_DAYS]));
                        dispatch(setDayParts([DAY_PARTS.ALL_DAY]));
                        break;
                    case CREATE_ANALYSIS_TYPES.CONGESTION.code:
                        dispatch(setDayParts(DAY_PARTS.get24Hrs()));
                        break;
                    default:
                        break;
                }
            }
        });
    };

export const setAnalysisTypeDefaults = (analysisTypeCode, config) => (dispatch, getState) => {
    const state = getState();
    const { screenMode } = getUiStates(state);
    const { measurementUnit } = getBasics(state);
    const org = getUserOrg(state);

    const isCreateAnalysisMode = screenMode === SCREEN_MODES.DEFAULT.id;

    batch(() => {
        if (analysisTypeCode === CREATE_ANALYSIS_TYPES.CONGESTION.code) {
            dispatch(setTripAttrEnabled(true));
            dispatch(setTravelerAttrEnabled(true));
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code) {
            const dataMonths = getKFactorDefaultDataMonths(state);
            dispatch(setDataMonths(dataMonths));
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.ODG.code) {
            dispatch(setSelectedGeographyType(getDefaultGeogTypeCode(state)));
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.TMC.code) {
            dispatch(generalActions.setIs15MinuteBinsActive(true));
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.TT_TMC.code) {
            dispatch(
                setTravelModeCode({
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
                }),
            );
            dispatch(generalActions.setIs15MinuteBinsActive(true));
            dispatch(setDayPartKind(DAY_PARTS_KINDS.INDIVIDUAL_HOURS.id));
        } else if (getIsNetworkPerformanceAnalysis(analysisTypeCode)) {
            dispatch(
                setTravelModeCode({
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
                }),
            );
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.NETWORK_OD.code) {
            dispatch(
                setTravelModeCode({
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
                }),
            );
        } else if (analysisTypeCode === CREATE_ANALYSIS_TYPES.ZA.code) {
            const isOrgHasHwl = getIsOrgHasFeature(state, "home_work_locations");
            const { projectFolder } = getGeneral(state);
            if (projectFolder && isOrgHasHwl) {
                dispatch(toggleHwlSetting());
            }
        }
        if (isCreateAnalysisMode && org.org_type === ORG_TYPES.PAY_PER_USE.id) {
            dispatch(setShouldCreateZoneSet(true));
        }
        if (isCreateAnalysisMode && !measurementUnit) {
            dispatch(setAnalysisMeasurementUnit(org.measurement_unit));
        }
        dispatch(setDefaultDayTypesAndParts(analysisTypeCode, isCreateAnalysisMode, config));
    });
};

export const showNextTab = () => (dispatch, getState) => {
    const { activeTab, screenMode } = getUiStates(getState());

    const tabsList = SCREEN_MODES_LIST.find(mode => mode.id === screenMode)?.tabsList || [];
    const currentTabIndex = getCurrentTabIndex(activeTab, tabsList);

    if (currentTabIndex < tabsList.length - 1) {
        dispatch(generalActions.setActiveTab(tabsList[currentTabIndex + 1].id));
    }

    if (tabsList[currentTabIndex].id === TABS.ZONES.id) {
        dispatch(setUiState({ activeControl: ZONES_MANAGER_CONTROLS.ZONE_TYPES_PICKER }));
        dispatch(setMapMode(MANAGER_MODES.VIEW_MAP));
        dispatch(setSelectedZone(null));
    }
};

export const showPreviousTab = () => (dispatch, getState) => {
    const { activeTab, screenMode } = getUiStates(getState());

    const tabsList = SCREEN_MODES_LIST.find(mode => mode.id === screenMode)?.tabsList || [];
    const currentTabIndex = getCurrentTabIndex(activeTab, tabsList);

    if (currentTabIndex > 0) {
        dispatch(generalActions.setActiveTab(tabsList[currentTabIndex - 1].id));
    }

    if (tabsList[currentTabIndex].id === TABS.ZONES.id) {
        dispatch(setUiState({ activeControl: ZONES_MANAGER_CONTROLS.ZONE_TYPES_PICKER }));
        dispatch(setMapMode(MANAGER_MODES.VIEW_MAP));
        dispatch(setSelectedZone(null));
    }
};

export const stateToProjectData = ({ state, isSavingToDraft }) => {
    const { emailNotificationSettings } = getApplicationPreferences(state);
    const { dataMonthSettings, dataPeriodSettings, aadtSettings, dayPartSettings } =
        getTimePeriods(state);
    const { isDraft, draftId } = getDraftState(state);
    const isUpsamplingAvailable = getIsUpsamplingAvailable(state);
    const analysisType = analysisTypeObjSelector(state);
    const addOns = getAnalysisAddOns(state);
    const { screenMode, zonesMode, is15MinuteBinsModeActive } = getUiStates(state);
    const {
        travelModeCode,
        name: projectName,
        description,
        tagIds,
        measurementUnit,
        country,
    } = getBasics(state);
    const shouldCreateZoneSet = getShouldCreateZoneSet(state);
    const projectDataCalibrationInfo = analysisCalibrationInfoSelector(state);
    const isUSCensus2020Analysis = getIsUSAnalysisWithOnlyCensus2020Dates(state);
    const hasDatesWithDifferentMetricsMethodologies =
        getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes(state);
    const isSandbox = getIsSandbox(state);
    const projectFolder = getProjectFolder(state);
    const isUSCensus2020TravelerParams = getIsUSCensus2020TravelerParams(state);
    const isZAHWLAnalysis = getIsZAHWLAnalysis(state);

    const { code: analysisTypeCode } = analysisType;
    const { hwlSettings, tripAttrSettings, travelerAttrSettings, adminSettings } = addOns;
    const enableCompletionEmail =
        emailNotificationSettings.enableNotification && adminSettings.enableEmailNotification;

    // Get HWL options for Zone Activity analysis
    const getHwlOptions = () => {
        if (!isZAHWLAnalysis) {
            return { premiumVHW: false };
        }
        const { hwlFilterOptions } = hwlSettings;

        return {
            premiumVHW: true,
            zone_intersection_type: hwlSettings.hwlZoneIntersectionCode,
            hwl_enable_visitor: hwlFilterOptions.hwl_enable_visitor,
            hwl_enable_resident: hwlFilterOptions.hwl_enable_resident,
            hwl_enable_worker: hwlFilterOptions.hwl_enable_worker,
        };
    };

    const getExcludedDateRanges = () => {
        let excludedDataPeriods;

        // For orgs with disabled Specific Dates, manually add excluded dates for partial data months
        if (enablePartialDataMonth(state) && !isDateRangeFormat(state)) {
            const partialDataPeriods = getPartialDataPeriods(state);

            excludedDataPeriods = prepareExcludedDateRanges(
                partialDataPeriods,
                dataMonthSettings.dataMonths,
            );
        } else {
            excludedDataPeriods = dataPeriodSettings.excludedDataPeriods || [];
        }

        return excludedDataPeriods.map(dataPeriod => ({
            start_date: moment(dataPeriod.startDate).format("MM/DD/YYYY"),
            end_date: moment(dataPeriod.endDate).format("MM/DD/YYYY"),
        }));
    };

    // Get route type for top routes analysis
    const getRouteTypes = () => {
        const isTopRoutesAnalysis = [
            CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.code,
            CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.code,
        ].includes(analysisTypeCode);

        if (!isTopRoutesAnalysis) return [];

        return Object.entries(addOns.routeOptions).reduce((result, [key, value]) => {
            if (value) return [...result, key];
            return result;
        }, []);
    };

    // Get Project Folder id for analysis when creating an analysis from project folder
    const getSelectedProjectFolderId = () => {
        if (!projectFolder) return null;

        return {
            project_folder_id: Number(projectFolder.project_folder_id),
        };
    };

    const binsToString = bins =>
        defaultBinsToString(bins, { binSeparator: "-", joinSeparator: "," });

    const projectData = {
        country,
        date_ranges: serializedSelectedDataPeriodsSelector(state),
        data_periods: serializedSelectedDataMonthsSelector(state),
        excluded_specific_dates: getExcludedDateRanges(),
        day_parts8: isSavingToDraft
            ? dayPartSettings.dayParts
            : dayPartsStateToData({
                  dayParts: dayPartSettings.dayParts,
                  is15MinuteBinsModeActive,
                  analysisTypeCode,
              }),
        day_types: dayTypesToCodeSelector(state),
        geog_type: addOns.presetGeographyType,
        description: description,
        tag_ids: tagIds,
        measurement_unit: measurementUnit,
        enable_viz: adminSettings.enable_viz,
        ignore_privacy_check: adminSettings.bypassPrivacyCheck,
        ignore_runtime_check: isSandbox || adminSettings.bypassSizeValidationCheck,
        enable_upsampling: getIsEnableUpsampling(isUpsamplingAvailable, is15MinuteBinsModeActive)
            ? adminSettings.enableUpsampling
            : false,
        is_massive_queue: adminSettings.isMassiveQueue,
        travel_mode_type: getTravelModeValueForCreatePayload({
            travelModeCode,
        }),
        premiumA: tripAttrSettings.enabled,
        premiumB: travelerAttrSettings.enabled && !hasDatesWithDifferentMetricsMethodologies,
        project_name: projectName,
        project_type: getProjectTypeValueForCreatePayload({
            analysisType,
            aadtYear: aadtSettings.aadtYear || aadtSettings.defaultAADTYear,
        }),
        road_types: getRouteTypes(),
        use_locked_trip: true,
        has_min_bins: is15MinuteBinsModeActive,
        is_draft: isDraft,
        draft_id: draftId,
        date_format: isDateRangeFormat(state) ? "date_ranges" : "data_periods",
        is_copy_project: screenMode === SCREEN_MODES.COPY_ANALYSIS.id,
        create_method: "A",
        create_zone_sets: shouldCreateZoneSet,
        enable_completion_email: enableCompletionEmail,
        ...projectDataCalibrationInfo,
        ...getHwlOptions(),
        ...getSelectedProjectFolderId(),
        // Draft doesn't include census_type, which is used to set traveler attr
        ...(isSavingToDraft ? { isUSCensus2020TravelerParams } : {}),
    };

    if (getIsTMCAnalysis(analysisTypeCode)) {
        projectData.intersection_zones = getApiIntersectionZones(state);
    } else if (zonesMode === ZONES_MODES.ZONE_SETS || zonesMode === ZONES_MODES.CHOOSE_ZONES) {
        projectData.zones = getApiSelectedZones(state);
    }

    // Include single traveler attributes for US analysis with Census 2020 dates (from 2019 forward)
    if (projectData.premiumB && isUSCensus2020Analysis) {
        projectData[TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.binTypeAccessor] =
            travelerAttrSettings[
                TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.uiStateName
            ].enabled;
        projectData[TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME.binTypeAccessor] =
            travelerAttrSettings[
                TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME.uiStateName
            ].enabled;
        projectData[TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS.binTypeAccessor] =
            travelerAttrSettings[
                TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS.uiStateName
            ].enabled;
        projectData[TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS.binTypeAccessor] =
            travelerAttrSettings[
                TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS.uiStateName
            ].enabled;
        projectData[TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.binTypeAccessor] =
            travelerAttrSettings[TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.uiStateName].enabled;
    }

    if (projectData.premiumA) {
        projectData[TRIP_BIN_TYPES.CIRCUITY.binTypeAccessor] =
            tripAttrSettings[TRIP_BIN_TYPES.CIRCUITY.uiStateName].enabled;
        projectData[TRIP_BIN_TYPES.LENGTH.binTypeAccessor] =
            tripAttrSettings[TRIP_BIN_TYPES.LENGTH.uiStateName].enabled;
        projectData[TRIP_BIN_TYPES.SPEED.binTypeAccessor] =
            tripAttrSettings[TRIP_BIN_TYPES.SPEED.uiStateName].enabled;
        projectData[TRIP_BIN_TYPES.DURATION.binTypeAccessor] =
            tripAttrSettings[TRIP_BIN_TYPES.DURATION.uiStateName].enabled;
        projectData.trip_circuity_bins = binsToString(tripAttrSettings.trip_circuity_bins);
        projectData.trip_duration_bins = binsToString(tripAttrSettings.trip_duration_bins);
        projectData.trip_length_bins = binsToString(tripAttrSettings.trip_length_bins);
        projectData.trip_speed_bins = binsToString(tripAttrSettings.trip_speed_bins);
    }

    if (analysisHasOption("segmentDuration", state)) {
        projectData.trip_duration_bins = binsToString(tripAttrSettings.trip_duration_bins);
    }

    if (analysisHasOption("segmentSpeed", state)) {
        projectData.trip_speed_bins = binsToString(tripAttrSettings.trip_speed_bins);
    }

    const isAllVehiclesOrTruckMode = getIsAllVehiclesOrTruckTravelMode(travelModeCode);
    const isOD_ODMFAnalyses = [ANALYSIS_TYPES.OD.id, ANALYSIS_TYPES.ODMF.id].includes(
        analysisTypeCode,
    );

    if (tripAttrSettings.speed_percentile_bins && analysisHasOption("speedPercentile", state)) {
        if (
            (isAllVehiclesOrTruckMode && (projectData.premiumA || !isOD_ODMFAnalyses)) ||
            travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code
        ) {
            projectData.enable_speed_percentile =
                tripAttrSettings[TRIP_BIN_TYPES.PERCENTILE.uiStateName].enabled;
            projectData.speed_percentile_bins = binsToString(
                tripAttrSettings.speed_percentile_bins,
            );
        }
    }

    if (
        tripAttrSettings.duration_percentile_bins &&
        analysisHasOption("travelTimePercentile", state)
    ) {
        const isSA_Analyses = analysisTypeCode === ANALYSIS_TYPES.SEGMENT.id;

        if (
            isAllVehiclesOrTruckMode &&
            (isSA_Analyses || (projectData.premiumA && isOD_ODMFAnalyses))
        ) {
            projectData.enable_duration_percentile =
                tripAttrSettings[TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.uiStateName].enabled;
            projectData.duration_percentile_bins = binsToString(
                tripAttrSettings.duration_percentile_bins,
            );
        }
    }

    if (adminSettings.size_review_types) {
        projectData.size_review_types = adminSettings.size_review_types;
        projectData.size_review_details = adminSettings.size_review_details;
    }

    return projectData;
};

export const saveAnalysis = () => (dispatch, getState) => {
    return AnalysesApiService.saveAnalysis(stateToProjectData({ state: getState() }))
        .then(() => {
            dispatch(generalActions.saveAnalysisSuccess(false));
            dispatch(fetchOrgBalance());
        })
        .catch(error => {
            const message = error?.response?.data?.message || error.message;
            dispatch(generalActions.setAnalysisActionError(message));
        });
};

export const runAnalysisSizeReview = () => (dispatch, getState) => {
    const state = getState();
    const analysisType = analysisTypeObjSelector(state);
    const { bypassSizeValidationCheck } = getAdminSettings(state);

    if (getIsTMCAnalysis(analysisType.code) || bypassSizeValidationCheck) {
        return dispatch(saveAnalysis());
    }

    const aadtSettings = getAadtSettings(state);
    const zones = getIsTMCAnalysis(analysisType.code)
        ? getApiIntersectionZones(state)
        : getApiSelectedZones(state);
    const { name, travelModeCode } = getBasics(state);
    const isZAHWLAnalysis = getIsZAHWLAnalysis(state);

    const options = {
        zones,
        project_name: name,
        project_type: getProjectTypeValueForCreatePayload({
            analysisType,
            aadtYear: aadtSettings.aadtYear || aadtSettings.defaultAADTYear,
        }),
        travel_mode_type: getTravelModeValueForCreatePayload({
            travelModeCode,
        }),
        date_format: isDateRangeFormat(state) ? "date_ranges" : "data_periods",
        data_periods: serializedSelectedDataMonthsSelector(state),
        date_ranges: serializedSelectedDataPeriodsSelector(state),
        is_hwl: isZAHWLAnalysis,
    };

    return AnalysesApiService.validateAnalysis(options)
        .then(data => {
            if (data.validation_status === "success") {
                dispatch(saveAnalysis());
            } else {
                dispatch(
                    generalActions.showAnalysisSizeReviewModal({
                        validationMessages: data.validation_messages,
                        validationTypes: data.validation_types,
                    }),
                );
            }
        })
        .catch(error => {
            // handled the same way as in saveAnalysis()
            const message = error?.response?.data?.message || error.message;
            dispatch(generalActions.setAnalysisActionError(message));
        });
};

export const getZoneQuotaData = () => (dispatch, getState) => {
    dispatch(generalActions.showPreviewAnalysis(false));

    const state = getState();
    const analysisTypeCode = getAnalysisTypeCode(state);
    const selectedZones = getApiSelectedZones(state);
    const zones = getUniqueZoneDetailsPayload(selectedZones);

    const tmcData = {};
    if (getIsTMCAnalysis(analysisTypeCode)) {
        const intersections = getIntersectionZonesList(state);

        tmcData.tmc_gates = intersections.map(intersection =>
            intersection.gates.map(gate => JSON.parse(gate.line_geom)),
        );

        tmcData.travel_mode_type =
            analysisTypeCode === CREATE_ANALYSIS_TYPES.TT_TMC.code
                ? MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code
                : MODES_OF_TRAVEL.ALL_VEHICLES.code;
    }

    return AnalysesApiService.getUniqueZoneDetails({ zones, ...tmcData })
        .then(({ unique_zone_data: uniqueZoneData }) => {
            if (uniqueZoneData) {
                dispatch(generalActions.showZoneQuotaModal(uniqueZoneData));
            } else {
                dispatch(runAnalysisSizeReview());
            }
        })
        .catch(error => {
            const message = error?.response?.data?.message || error.message;
            dispatch(generalActions.setAnalysisActionError(message));
        });
};

export const requestZoneUpgrade = zoneQuotaData => dispatch => {
    return OrgApiService.requestZoneUpgrade({
        zones_shortfall: Math.abs(zoneQuotaData.zone_project_remain),
    })
        .then(() => dispatch(generalActions.resetUniqueZoneData()))
        .catch(error => {
            const message = error?.response?.data?.message || error.message;
            StlNotification.error(message);
            dispatch(generalActions.resetUniqueZoneData());
        });
};

export const setSelectedAnalysisType = (analysisTypeCode, config) => dispatch => {
    dispatch(generalActions.setAnalysisTypeCode(analysisTypeCode, config));
};

// Use this action to dispatch initial analysis state requiring current user info
// such as privileges and org features.
export const setUserAnalysisDefaults = () => (dispatch, getState) => {
    const state = getState();
    const isSuper = getIsSuperUser(state);
    const availableCalibrations = getAvailableCalibrations(state);
    const aadtCalibrationYears = getAvailableAADTCalibrationYears(state);
    // Choose the most recent AADT calibration year.
    const defaultAADTCalibrationYear = aadtCalibrationYears[aadtCalibrationYears.length - 1];

    batch(() => {
        // Set the bypass privacy and runtime checks.
        dispatch(setBypassPrivacyCheckSetting(isSuper));
        dispatch(setBypassSizeValidationCheckSetting(false));
        dispatch(setDefaultAADTYear());
        dispatch(
            setAvailableCalibrationCodes(
                availableCalibrations.map(calibration => calibration.code),
            ),
        );
        dispatch(setAADTCalibrationYear(defaultAADTCalibrationYear));
        dispatch(setCalibrationCode(getDefaultCalibrationCode(state)));
    });
};

export const validateAnalysis = () => (dispatch, getState) => {
    const state = getState();

    const analysisType = analysisTypeObjSelector(state);
    const { tripAttrSettings, hwlSettings, routeOptions } = getAnalysisAddOns(state);
    const { dataPeriodSettings, dataMonthSettings, dayPartSettings } = getTimePeriods(state);
    const { travelModeCode } = getBasics(state);
    const { calibrationCode } = getCalibrationSettings(state);
    const analysisTypeCode = getAnalysisTypeCode(state);
    const { zonesMode, is15MinuteBinsModeActive } = getUiStates(state);
    const analysisCountry = getAnalysisCountry(state);
    const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state);
    const zonesLimitValidation = getZonesLimitValidation(state);

    // Basics validation
    const newBasicsValidation = {
        travelModeCode: validateTravelModeCodeField(travelModeCode),
        calibrationCode: validateOutputTypeField(calibrationCode),
    };

    batch(() => {
        dispatch(validateAnalysisName());
        dispatch(setBasicsValidation(newBasicsValidation));
    });

    // Zones validation
    if (getIsTMCAnalysis(analysisTypeCode)) {
        const validationResult = validateIntersections(getApiIntersectionZones(state), zonesMode);
        const newZonesValidation = {
            ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
            chooseZones: createValidateField(validationResult),
        };

        dispatch(setValidatedIntersections(validationResult));
        dispatch(setZonesValidation(newZonesValidation));
    } else {
        let validationResult,
            ipfValidationResult = "";
        const selectedZones = getSelectedZones(state);

        if (analysisTypeCode === ANALYSIS_TYPES.ODG.id) {
            validationResult = validateODGZonesLocation(
                selectedZones,
                analysisCountry,
                zonesLimitValidation,
            );
        } else {
            validationResult = validateSelectedZones({
                selectedZones: getSelectedZones(state),
                analysisCountry,
                isTruckVolumeAnalysis,
                zonesLimitValidation,
                analysisTypeCode,
            });
        }
        if (getIsIPFCalibrationSelected(calibrationCode)) {
            const ipfCalibrationSettings = getIPFCalibrationSettings(state);
            const configParams = getConfigParams(state);

            const ipfValidation = validateIPFCalibrationValues({
                ipfCalibrationSettings,
                ipfThresholds: configParams,
                selectedZones,
            });
            if (ipfValidation.ipfValues) {
                dispatch(setIPFCalibrationSettings({ ipfValues: ipfValidation.ipfValues }));
            }
            ipfValidationResult = ipfValidation.reasons.join(" ");
        }

        const field = zonesMode === ZONES_MODES.ZONE_SETS ? "zones" : "chooseZones";
        const newZonesValidation = {
            ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
            [field]: createValidateField(validationResult),
            ipfValues: createValidateField(ipfValidationResult),
            az: validateCalibrationZones({
                zones: selectedZones.az,
                zoneRole: ZoneRoles.az,
                calibrationCode,
            }),
            cz: validateUserCountsCalibrationZones({
                zones: selectedZones.cz,
                zoneRole: ZoneRoles.cz,
                calibrationCode,
                travelModeCode,
            }),
        };

        dispatch(setZonesValidation(newZonesValidation));
    }

    // Time periods validation
    const newTimePeriodsValidation = {};
    // AADT analyses are yearly and do not require data periods
    if (analysisType.requiresDataPeriods) {
        const censusMixingPermitted = getCensusMixingPermitted(state);
        const isNetworkPerformanceAnalysis = getIsNetworkPerformanceAnalysis(analysisTypeCode);

        if (isDateRangeFormat(state)) {
            newTimePeriodsValidation.dataPeriods = validateDataPeriodsField({
                dataPeriods: dataPeriodSettings.dataPeriods,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
                isNetworkPerformanceAnalysis,
            });
            newTimePeriodsValidation.dataMonths = [];
        } else {
            newTimePeriodsValidation.dataPeriods = [];
            newTimePeriodsValidation.dataMonths = validateDataMonthsSection({
                dataMonths: dataMonthSettings.dataMonths,
                censusMixingPermitted,
                isTruckVolumeAnalysis,
                isNetworkPerformanceAnalysis,
            });
        }

        if (dataPeriodSettings.excludeDateRangesEnabled) {
            newTimePeriodsValidation.excludedDataPeriods = validateDataPeriodsField({
                dataPeriods: dataPeriodSettings.excludedDataPeriods,
                dataPeriodType: "excludedDataPeriods",
            });
        } else {
            newTimePeriodsValidation.excludedDataPeriods = null;
        }
    } else {
        newTimePeriodsValidation.dataPeriods = [];
        newTimePeriodsValidation.dataMonths = [];
    }

    if (
        analysisHasOption(TIME_PERIODS_TAB_SECTIONS.DAY_PARTS.code, state) ||
        getIsTMCAnalysis(analysisTypeCode)
    ) {
        newTimePeriodsValidation.dayParts = validateDayParts(
            dayPartSettings.dayParts,
            is15MinuteBinsModeActive,
        );
    }
    dispatch(setTimePeriodsValidation(newTimePeriodsValidation));

    const newAddOnsValidation = {
        routeOptions: validateRouteOptionsField(routeOptions),
        hwlFilterOptions: validateHwlFilterOptionsField(
            hwlSettings.enabled,
            hwlSettings.hwlFilterOptions,
        ),
    };

    if (analysisHasOption(ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES.code, state)) {
        validateTripAttributes(newAddOnsValidation, tripAttrSettings);
    }
    if (
        [
            CREATE_ANALYSIS_TYPES.SEGMENT.code,
            CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
            CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.code,
        ].includes(analysisType.code)
    ) {
        validateSegmentAttributes(newAddOnsValidation, tripAttrSettings);
    }

    dispatch(setAddOnsValidation(newAddOnsValidation));

    // Computing analysis validation
    const newState = getState();

    const { isInvalid: isBasicsInvalid } = getBasicsValidation(newState);
    const { isInvalid: isZonesInvalid } = getZonesValidation(newState);
    const { isInvalid: isAddOnsInvalid } = getAddOnsValidation(newState);
    const { isInvalid: isTimePeriodsInvalid } = getTimePeriodsValidation(newState);

    const isAnalysisInvalid = !!(
        isBasicsInvalid ||
        isZonesInvalid ||
        isAddOnsInvalid ||
        isTimePeriodsInvalid
    );

    dispatch(generalActions.setAnalysisValidation(isAnalysisInvalid));
};

export const confirmAnalysis = () => (dispatch, getState) => {
    const state = getState();
    const analysisTypeCode = getAnalysisTypeCode(state);

    batch(() => {
        // Cancel any editing before validating.
        dispatch(cancelAllEditDataPeriods());
        dispatch(validateAnalysis());
    });
    // Validate intersections in TMC analysis and show warning lightbox
    if (getIsTMCAnalysis(analysisTypeCode)) {
        const { zonesMode } = getUiStates(state);
        const validationResult = validateIntersections(getApiIntersectionZones(state), zonesMode);

        if (validationResult) {
            const validationErrors = validationResult.split(". ");

            LightboxService.openNotification({
                title: `Warning (${validationErrors.length})`,
                content: validationErrors.map(error => <p key={error}>{error}.</p>),
                centered: true,
            });
            return;
        }

        dispatch(validateAnalysisIntersections()).then(hasErrors => {
            if (hasErrors) {
                LightboxService.openConfirmation({
                    isWarning: true,
                    content:
                        "One or more Intersection Gates are of questionable configuration. Please review.",
                    centered: true,
                    okText: "Go Back",
                    cancelText: "Proceed with warnings",
                    onCancel: () => dispatch(generalActions.showPreviewAnalysis(true)),
                });
            } else {
                const newZonesValidation = {
                    ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                    chooseZones: null,
                };
                dispatch(setZonesValidation(newZonesValidation));
                dispatch(generalActions.showPreviewAnalysis(true));
            }
        });
        return;
    }
    dispatch(generalActions.showPreviewAnalysis(true));
};

export const resetAnalysisConfiguration = params => dispatch => {
    // Reset the configuration to the initial state and with the correct defaults.
    batch(() => {
        dispatch(generalActions.resetGeneralReducer(params?.skipActiveTabReset));
        dispatch(resetBasicsReducer(params?.skipAnalysisNameReset));
        dispatch(resetAddOnsReducer());
        dispatch(resetReviewReducer());
        dispatch(resetChooseZonesReducer());
        if (!params?.skipTimePeriodsReset) dispatch(resetTimePeriodsReducer());
        dispatch(resetTMCChooseZonesReducer());
        dispatch(setUserAnalysisDefaults());
    });
};

export const analysisDataToState = ({
    analysisData,
    availableAnalyses,
    partialDataPeriods,
    isCopy,
    isDraft,
    isSuperUser,
    is15MinuteBinsModeAvailable,
    isUpsamplingAvailable,
    invalidAnalysisData,
    availableDataMonths,
    userPreferredCountry,
    userPreferredWeekdayType,
    projectFolder,
}) => {
    const errors = [];
    const defaultAADTYear =
        analysisData.country === ORG_COUNTRIES.US.code ? DEFAULT_AADT_YEAR : DEFAULT_AADT_YEAR_CA;
    const matchedAADT = /Estimated_(.+)_AADT/.exec(analysisData.project_type);
    const aadtYear = matchedAADT ? parseInt(matchedAADT[1], 10) : defaultAADTYear;
    const isAADTAnalysis = !!matchedAADT;
    const analysisType = getAnalysisType(analysisData.project_type, analysisData.travel_mode_type);
    const isUSCensus2020Analysis = analysisData.census_type === ANALYSIS_CENSUS_TYPES.US_2020;

    const getTravelMode = () => {
        if (
            !analysisType.canChooseTravelMode &&
            !ALL_VEHICLES_TOMTOM_SUPPORTED_ANALYSES.includes(analysisData.project_type) &&
            analysisType.code !== CREATE_ANALYSIS_TYPES.TT_TMC.code
        ) {
            return TRAVEL_MODES.ALL_VEHICLES;
        }

        const travelMode = getAnalysisTravelModeFromCode(analysisData.travel_mode_type);
        if (!travelMode) {
            errors.push("Mode of Travel is no longer available and could not be copied.");
            return analysisType.travelModes[0];
        }
        return travelMode;
    };
    const travelMode = getTravelMode();

    const excludedPeriods = getParsedExcludedDateRanges(
        processExcludedDates(analysisData.excluded_specific_dates, isDraft),
    );

    let dataPeriods;
    if (invalidAnalysisData.ttApiData.isInvalid) {
        dataPeriods = invalidAnalysisData.ttApiData.defaultDataPeriods;
    } else if (invalidAnalysisData.gmCVDData.isInvalid) {
        dataPeriods = invalidAnalysisData.gmCVDData.defaultDataPeriods;
    } else {
        dataPeriods = getDataPeriods(analysisData, partialDataPeriods, excludedPeriods);
    }

    let dataMonths;
    if (
        invalidAnalysisData.bikePedData.timePeriods ||
        invalidAnalysisData.bikePedData.outputType
    ) {
        dataMonths = invalidAnalysisData.bikePedData.defaultDataMonths[travelMode.code];
    } else if (invalidAnalysisData.gmCVDData.isInvalid) {
        dataMonths = invalidAnalysisData.gmCVDData.defaultDataMonths;
    } else {
        dataMonths = getDataMonths({
            analysis: analysisData,
            partialDataPeriods,
            excludedDates: excludedPeriods,
        });
    }

    const _dataMonths = getAvailableDataMonthsForCopyWithBikePedMode({
        dataMonths,
        availableDataMonths,
        travelModeType: analysisData?.travel_mode_type,
        country: analysisData?.country,
        isCopyOrDraft: isCopy || isDraft,
    });

    // For analysis with 'data_periods' date format
    // remove any excluded date period that belongs to partial data months
    let excludedDataPeriods;
    if (analysisData.date_format === "date_ranges") {
        excludedDataPeriods = excludedPeriods;
    } else {
        const partialMonthsRange = getPartialMonthsRange(partialDataPeriods);

        excludedDataPeriods = excludedPeriods.filter(excludedPeriod => {
            if (analysisData.date_format === "date_ranges") return true;

            return !checkDatesInsidePartialDataPeriod(partialMonthsRange, [excludedPeriod]);
        });
    }

    //The data format we receive from server for day types is different for draft analysis
    const reformatDayTypesForDraft = dayTypes => {
        return dayTypes.split(",").map((dayType, index) => {
            const dayTypeArr = dayType.split("|");
            const firstDayCode = Math.floor(dayTypeArr[1] / 10);
            const secondDayCode = dayTypeArr[1] % 10;
            return `${index}: ${dayTypeArr[0]} (${getDayShortNameFromCode(
                firstDayCode.toString(),
            )}-${getDayShortNameFromCode(secondDayCode.toString())})`;
        });
    };

    const allDaysStartName = DAY_TYPES.ALL_DAYS.start.name;
    const allDaysEndName = DAY_TYPES.ALL_DAYS.end.name;
    const getDayTypes = dayTypes => {
        let updatedDayTypes = dayTypes;
        if (isDraft) {
            updatedDayTypes = reformatDayTypesForDraft(dayTypes);
        }
        const parsedDayTypes = updatedDayTypes.map(dayType => {
            const dayTypeString = dayType.split(":")[1];
            const name = dayTypeString.split("(")[0].trim();
            const regex = /\((.+?)\)/;
            const periodsString = regex.exec(dayTypeString)[1];
            const periodsSplit = periodsString.split("-");
            const startName = getDayFromShortName(periodsSplit[0].trim()).name;
            const endName = getDayFromShortName(periodsSplit[1].trim()).name;

            return {
                name,
                start: { name: startName },
                end: { name: endName },
                isReadOnly: startName === allDaysStartName && endName === allDaysEndName,
            };
        });
        // Force the default 'All Days' day type in the copy or draft flow. This handles if the
        // default day type has the old 'Average Day' name.
        if (isCopy || isDraft) {
            const filteredDayTypes = parsedDayTypes.filter(dayType => !dayType.isReadOnly);
            filteredDayTypes.unshift(DAY_TYPES.ALL_DAYS);
            return filteredDayTypes;
        }
        return parsedDayTypes;
    };

    const getDayParts = (dayParts, has15MinuteBins) => {
        // We save day parts as-is for draft.
        if (isDraft) return dayParts;

        let _dayParts = dayParts;

        if (analysisData.has_min_bins) {
            if (isCopy && !is15MinuteBinsModeAvailable) return DAY_PARTS.getDefault();

            // Remove "All Day" part from UI.
            _dayParts = dayParts.filter(part => !part.includes(DAY_PARTS.ALL_DAY.name));
        }

        const parsedDayParts = _dayParts.map(part => {
            const name = part.match(/(.+)(?= \()/)?.[0];
            const range = part.match(/[^(]+(?=\))/)[0];

            const splitRange = range.split("-");

            return {
                name: name || range,
                start: { name: splitRange[0] },
                end: { name: splitRange[1] },
                isReadOnly:
                    splitRange[0] === DAY_PARTS.ALL_DAY.start.name &&
                    splitRange[1] === DAY_PARTS.ALL_DAY.end.name,
            };
        });

        if (
            (analysisData.project_type === CREATE_ANALYSIS_TYPES.NETWORK_OD.code ||
                analysisType.code === CREATE_ANALYSIS_TYPES.TT_TMC.code) &&
            has15MinuteBins
        ) {
            return parsedDayParts.reduce(
                (res, dayPart) => {
                    const startHour = moment(dayPart.start.name, "ha");
                    const endHour = moment(dayPart.end.name, "ha");

                    // Handles the case when whole day was selected
                    if (dayPart.start.name === dayPart.end.name) {
                        endHour.add(24, "hours");
                    }
                    while (!startHour.isSame(endHour, "hour")) {
                        const startHourName = startHour.format("ha");

                        startHour.add(1, "hours");
                        res.push({
                            name: startHourName,
                            start: { name: startHourName },
                            end: { name: startHour.format("ha") },
                            isReadOnly: false,
                        });
                    }
                    return res;
                },
                [DAY_PARTS.ALL_DAY],
            );
        }
        return parsedDayParts;
    };

    // Get route type for top routes analysis
    const getRouteOptions = roadTypes => {
        const routeOptions = {};

        if (!roadTypes || !roadTypes.length) {
            return ROUTE_OPTIONS;
        }

        Object.keys(ROUTE_OPTIONS).forEach(option => {
            routeOptions[option] = false;
        });
        roadTypes.forEach(roadType => {
            routeOptions[roadType] = true;
        });

        return routeOptions;
    };

    const getPresetGeographyType = geogType => geogType || DEFAULT_PRESET_GEOG_TYPE.code;

    const analysisName = isCopy
        ? getCopiedAnalysisName(analysisData.project_name, availableAnalyses)
        : analysisData.project_name;

    const hwlOptions = analysisData.premiumVHW
        ? {
              hwlZoneIntersectionCode:
                  analysisData.zone_intersection_type || HWL_ZONE_INTERSECTION_TYPES.TRIP_ALL.code,
              hwlFilterOptions: {
                  hwl_enable_visitor: !!analysisData.hwl_enable_visitor,
                  hwl_enable_resident: !!analysisData.hwl_enable_resident,
                  hwl_enable_worker: !!analysisData.hwl_enable_worker,
              },
          }
        : null;

    const dayTypes = getDayTypes(analysisData.day_types);
    const dayParts = getDayParts(analysisData.day_parts8, analysisData.has_min_bins);

    const basicsData = {
        name: analysisName,
        createdBy: analysisData.created_by,
        createdDate: analysisData.created_date,
        description: analysisData.project_desc,
        country: analysisData.country || userPreferredCountry.code,
        tagIds: analysisData.tag_ids || [],
        travelModeCode: travelMode.code,
        measurementUnit: analysisData.measurement_unit,
        orgName: analysisData.org_name,
        calibrationSettings: {
            calibrationCode: invalidAnalysisData.bikePedData.outputType
                ? CALIBRATIONS.VOLUME.code
                : getAnalysisCalibrationCode(analysisData),
            aadtCalibrationYear: analysisData.aadt_calibration_year || defaultAADTYear,
        },
        censusType: analysisData.census_type,
        zoneCensusType: analysisData.current_zone_census_type,
    };

    const isReviewAnalysisStatus = [
        ANALYSIS_STATUSES.IN_COVERAGE_REVIEW.id,
        ANALYSIS_STATUSES.IN_SIZE_REVIEW.id,
    ].includes(analysisData.status);
    // 'is_review_zone_render' flag should be considered only for analyses in Coverage review or Size review statuses in View/Review mode
    const shouldRenderZones =
        !isReviewAnalysisStatus || isCopy || analysisData.is_review_zone_render;

    const reviewData = {
        reviewNote: analysisData.review_note,
        currentReviewNote: analysisData.review_note,
        reviewNoteVersionId: analysisData.rev_note_sa_version_id,
        validationDetails: analysisData.validation_details,
        reviewDetail: analysisData.review_detail,
        reviewRegion: analysisData.region_detail,
        shouldRenderZones,
        zendeskUrl: analysisData.zendesk_ticket_url,
        streetlightSupportUrl: analysisData.support_case_url,
    };

    // set_geoms will be omitted and won't be sent from BE when analysis exceeds allowed limit
    const questionableZoneIds = (analysisData.review_detail?.set_geoms?.zones || []).map(zone =>
        String(zone.zone_id),
    );

    const chooseZonesData = {
        selectedZones: shouldRenderZones
            ? {
                  oz: getZonesFromAnalysis(
                      analysisData.oz_zones,
                      invalidAnalysisData.zoneKindIdsWithOldZones,
                  ),
                  dz: getZonesFromAnalysis(
                      analysisData.dz_zones,
                      invalidAnalysisData.zoneKindIdsWithOldZones,
                  ),
                  mfz: getZonesFromAnalysis(
                      analysisData.mfz_zones,
                      invalidAnalysisData.zoneKindIdsWithOldZones,
                  ),
                  tfz: getZonesFromAnalysis(analysisData.tfz_zones),
                  cz: getZonesFromAnalysis(analysisData.cz_zones),
                  az: getZonesFromAnalysis(analysisData.az_zones),
              }
            : {
                  oz: [],
                  dz: [],
                  mfz: [],
                  tfz: [],
                  cz: [],
                  az: [],
              },
        shouldCreateZoneSet: !!analysisData.create_zone_sets,
        zoneType: DEFAULT_ZONE_TYPE,
        zoneVintage: analysisData.zone_vintage || {},
        ipfCalibrationSettings: getIPFValuesFromAnalysis({
            analysisIPFValues: analysisData.ipf_calibration_values,
            dayTypes,
            dayParts,
        }),
        ...(!isCopy && questionableZoneIds),
    };

    // For Speed/Duration Percentiles need to check not only appropriate flag but bins as well, as there was an issue
    // when flag was not set correctly. This led to bad UX when copying such 'invalid' analyses (INST-24200).
    const shouldEnableSpeedPercentiles =
        analysisData[TRIP_BIN_TYPES.PERCENTILE.binTypeAccessor] ||
        !!analysisData[TRIP_BIN_TYPES.PERCENTILE.code];
    const shouldEnableDurationPercentiles =
        analysisData[TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.binTypeAccessor] ||
        !!analysisData[TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.code];

    // Handle case when analysis was created using api, trip attr enabled, but all specific trip attrs disabled
    const isAllTripAttributesDisabled = TRIP_BIN_TYPES_LIST.every(
        attr => !analysisData[attr.binTypeAccessor],
    );
    const shouldEnableAllTripAttributes = analysisData.premiumA && isAllTripAttributesDisabled;

    const isMixedDataPeriods = getIsDataPeriodsMixedWithCensus2020({
        dataPeriodsType: "dataPeriods",
        dataPeriodsData: dataPeriods,
    });
    // We can have some analyses, which were created before there was added logic restricting enabling of traveler attributes when data periods were mixed from different censuses.
    // For copy and draft analyses in addition to analysis.premiumB we check if analysis has data periods mixed from different censuses. We should uncheck travelerParams in such cases.
    // For view and review analysis we should check for mixed data periods only in case if analysis was created with US 2020 census, otherwise we should be fine to use analysis.premiumB.
    const isViewMode = !isCopy && !isDraft;

    const _isUSCensus2020Analysis = isDraft
        ? analysisData.isUSCensus2020TravelerParams
        : isUSCensus2020Analysis;

    const shouldEnableTravelerAttr =
        analysisData.premiumB &&
        (isViewMode ? !_isUSCensus2020Analysis || !isMixedDataPeriods : !isMixedDataPeriods);
    const shouldEnableAllTravelerAttributes =
        shouldEnableTravelerAttr &&
        getIsAllTripAttributesDisabled(analysisData, _isUSCensus2020Analysis);
    const addOnsData = {
        presetGeographyType: getPresetGeographyType(analysisData.geog_type),
        routeOptions: getRouteOptions(analysisData.road_types),
        hwlSettings: {
            enabled: analysisData.premiumVHW,
            ...hwlOptions,
        },
        tripAttrSettings: {
            enabled: analysisData.premiumA,
            trip_circuity_bins: analysisData.circuity_bins
                ? binsToArray(analysisData.circuity_bins)
                : getTripBinDefaults(
                      TRIP_BIN_TYPES.CIRCUITY,
                      basicsData.travelModeCode,
                      basicsData.measurementUnit,
                  ),
            trip_duration_bins: analysisData.duration_bins
                ? binsToArray(analysisData.duration_bins)
                : getTripBinDefaults(
                      TRIP_BIN_TYPES.DURATION,
                      basicsData.travelModeCode,
                      basicsData.measurementUnit,
                  ),
            trip_length_bins: analysisData.length_bins
                ? binsToArray(analysisData.length_bins)
                : getTripBinDefaults(
                      TRIP_BIN_TYPES.LENGTH,
                      basicsData.travelModeCode,
                      basicsData.measurementUnit,
                  ),
            trip_speed_bins: analysisData.speed_bins
                ? binsToArray(analysisData.speed_bins)
                : getTripBinDefaults(
                      TRIP_BIN_TYPES.SPEED,
                      basicsData.travelModeCode,
                      basicsData.measurementUnit,
                  ),
            speed_percentile_bins: analysisData.speed_percentile_bins
                ? binsToArray(analysisData.speed_percentile_bins, true)
                : null,
            duration_percentile_bins: analysisData.duration_percentile_bins
                ? binsToArray(analysisData.duration_percentile_bins, true)
                : null,
            [TRIP_BIN_TYPES.DURATION.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled:
                    shouldEnableAllTripAttributes ||
                    analysisData[TRIP_BIN_TYPES.DURATION.binTypeAccessor],
            },
            [TRIP_BIN_TYPES.SPEED.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled:
                    shouldEnableAllTripAttributes ||
                    analysisData[TRIP_BIN_TYPES.SPEED.binTypeAccessor],
            },
            [TRIP_BIN_TYPES.LENGTH.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled:
                    shouldEnableAllTripAttributes ||
                    analysisData[TRIP_BIN_TYPES.LENGTH.binTypeAccessor],
            },
            [TRIP_BIN_TYPES.CIRCUITY.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled:
                    shouldEnableAllTripAttributes ||
                    analysisData[TRIP_BIN_TYPES.CIRCUITY.binTypeAccessor],
            },
            [TRIP_BIN_TYPES.PERCENTILE.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled: shouldEnableAllTripAttributes || shouldEnableSpeedPercentiles,
            },
            [TRIP_BIN_TYPES.TRAVEL_TIME_PERCENTILE.uiStateName]: {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled: shouldEnableAllTripAttributes || shouldEnableDurationPercentiles,
            },
        },
        travelerAttrSettings: {
            enabled: shouldEnableTravelerAttr,
            [TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.uiStateName]: {
                enabled:
                    shouldEnableAllTravelerAttributes ||
                    (analysisData[
                        TRAVELER_PARAMS_US_CENSUS_2020.EQUITY_DEMOGRAPHICS.binTypeAccessor
                    ] ??
                        shouldEnableTravelerAttr),
            },
            [TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME.uiStateName]: {
                enabled:
                    shouldEnableAllTravelerAttributes ||
                    (analysisData[
                        TRAVELER_PARAMS_US_CENSUS_2020.EDUCATION_INCOME.binTypeAccessor
                    ] ??
                        shouldEnableTravelerAttr),
            },
            [TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS.uiStateName]: {
                enabled:
                    shouldEnableAllTravelerAttributes ||
                    (analysisData[
                        TRAVELER_PARAMS_US_CENSUS_2020.HOUSEHOLD_CHARACTERISTICS.binTypeAccessor
                    ] ??
                        shouldEnableTravelerAttr),
            },
            [TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS.uiStateName]: {
                enabled:
                    analysisData[
                        TRAVELER_PARAMS_US_CENSUS_2020.EMPLOYMENT_CHARACTERISTICS.binTypeAccessor
                    ] ?? shouldEnableTravelerAttr,
            },
            [TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.uiStateName]: {
                enabled:
                    shouldEnableAllTravelerAttributes ||
                    (analysisData[TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.binTypeAccessor] ??
                        shouldEnableTravelerAttr),
            },
        },
        commercialVehicleSettings: {
            enabled: analysisData.include_comm_heavy || analysisData.include_comm_medium,
            commercialVehicleType: {
                cvHeavy: analysisData.include_comm_heavy,
                cvMedium: analysisData.include_comm_medium,
            },
        },
        adminSettings: {
            bypassSizeValidationCheck: isSuperUser ? analysisData.ignore_runtime_check : false,
            bypassPrivacyCheck: isSuperUser ? analysisData.ignore_privacy_check : false,
            isMassiveQueue: isSuperUser ? analysisData.is_massive_queue : false,
            enable_viz: analysisData.enable_viz,
            enable_demographics: analysisData.enable_demographics,
            enableUpsampling:
                analysisData.enable_upsampling &&
                getIsEnableUpsampling(isUpsamplingAvailable, analysisData.has_min_bins),
        },
        validationDetails: analysisData.validation_details,
    };

    const customDateRanges = getAnalysisCustomDateRanges(
        analysisData,
        dataPeriods,
        excludedDataPeriods,
    );
    const timePeriodsData = {
        shouldUseCustomRanges: customDateRanges.hasCustomRanges,
        dataPeriodSettings: {
            ...TIME_PERIODS_INITIAL_STATE.dataPeriodSettings,
            dataPeriods: dataPeriods,
            excludedDataPeriods: excludedDataPeriods,
            excludeDateRangesEnabled: !!excludedDataPeriods.length,
            customDateRanges: customDateRanges.customRanges,
        },
        dataMonthSettings: {
            dataMonths: _dataMonths,
        },
        dayTypeSettings: {
            ...TIME_PERIODS_INITIAL_STATE.dayTypeSettings,
            dayTypes,
            dayTypeKind: getDayTypeKindByDayTypes(dayTypes),
            weekdayType: getWeekdayTypeByDayTypes(dayTypes, userPreferredWeekdayType),
        },
        dayPartSettings: {
            ...TIME_PERIODS_INITIAL_STATE.dayPartSettings,
            dayParts,
            dayPartKind: getDayPartKindByDayParts(dayParts, analysisData.has_min_bins),
        },
        aadtSettings: {
            aadtYear: isAADTAnalysis ? aadtYear : null,
        },
    };

    const screenMode = isCopy
        ? SCREEN_MODES.COPY_ANALYSIS.id
        : isDraft
        ? SCREEN_MODES.DRAFT.id
        : SCREEN_MODES.DEFAULT.id;

    const projectFolderName = analysisData.project_folder_name
        ? {
              project_folder_name: analysisData.project_folder_name,
          }
        : null;

    const generalData = {
        analysisId: analysisData.project_id,
        analysisVersionId: analysisData.sa_version_id,
        analysisStatus: analysisData.status,
        analysisTypeCode: analysisType.code,
        uiStates: {
            is15MinuteBinsModeActive: analysisData.has_min_bins,
            zonesMode: getAnalysisZonesMode(analysisData),
            screenMode,
        },
        projectFolder: projectFolder || projectFolderName,
    };

    if (errors.length) {
        errors.push("Other configurations has been copied.");
    }

    return {
        basicsData,
        chooseZonesData,
        addOnsData,
        generalData,
        timePeriodsData,
        reviewData,
        errors,
    };
};

export const setInitialAnalysisConfiguration =
    ({ analysisData, isCopy, isDraft, invalidData = {} }) =>
    (dispatch, getState) => {
        // Need to remember and restore value of 'isShowAllAnalysesAllowed' flag before config reset
        const { isShowAllAnalysesAllowed } = getUiStates(getState());
        dispatch(resetAnalysisConfiguration());

        const state = getState();
        const availableAnalyses = getAnalyses(state);
        const partialDataPeriods = getPartialDataPeriods(state);
        const defaultDataPeriods = getDefaultDataPeriodsByMode(state);
        const invalidAnalysisData = {
            bikePedData: {
                timePeriods: invalidData?.bikePedTimePeriods?.value,
                outputType: invalidData?.bikePedOutputType?.value,
                defaultDataMonths: getDefaultDataMonthsByMode(state),
            },
            gmCVDData: {
                isInvalid: invalidData?.gmCVDTimePeriods?.value,
                defaultDataPeriods: invalidData?.gmCVDTimePeriods?.defaultDataPeriods,
                defaultDataMonths: invalidData?.gmCVDTimePeriods?.defaultDataMonths,
            },
            ttApiData: {
                isInvalid: invalidData?.ttApiTimePeriods?.value,
                defaultDataPeriods:
                    defaultDataPeriods[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code],
            },
            zoneKindIdsWithOldZones: invalidData?.zoneKindIdsWithOldZones?.value,
        };
        const availableDataMonths = getAvailableDataMonthsByMode(state);
        const userPreferredWeekdayType = getPreferredWeekdayType(state);
        const preferredCountry = getPreferredCountry(state);
        const org = getUserOrg(state);
        const userPreferredCountry = getUserPreferredCountry({
            org,
            preferredCountryCode: preferredCountry,
        });

        const {
            basicsData,
            chooseZonesData,
            addOnsData,
            generalData,
            timePeriodsData,
            reviewData,
            errors,
        } = analysisDataToState({
            analysisData,
            availableAnalyses,
            partialDataPeriods,
            isCopy,
            isDraft,
            isSuperUser: getIsSuperUser(state),
            is15MinuteBinsModeAvailable: getIs15MinuteBinsModeAvailable(state),
            isUpsamplingAvailable: getIsUpsamplingAvailable(state),
            invalidAnalysisData,
            availableDataMonths,
            projectFolder: getSelectedProjectFolder(state),
            userPreferredCountry,
            userPreferredWeekdayType,
        });
        generalData.isShowAllAnalysesAllowed = isShowAllAnalysesAllowed;

        batch(() => {
            dispatch(setBasicsInitialData(basicsData));
            dispatch(setChooseZonesInitialData(chooseZonesData));
            dispatch(setAddOnsInitialData(addOnsData));
            dispatch(setTimePeriodsInitialData(timePeriodsData));
            dispatch(generalActions.setGeneralInitialData(generalData));
            dispatch(setReviewInitialData(reviewData));
            dispatch(setTMCInitialData(analysisData));
        });

        if (errors.length) {
            dispatch(generalActions.setAnalysisActionError(errors.join(" ")));
        }
    };

export const getAnalysis =
    ({ project_id, ...analysisData }) =>
    dispatch => {
        const requestParams = {
            format: "zoneDesc",
            ...analysisData,
        };

        return AnalysesApiService.getAnalysis(project_id, { params: requestParams }).catch(
            error => {
                const errorMsg = error?.response?.data?.message || error.message;

                dispatch(generalActions.setAnalysisActionError(errorMsg));

                return Promise.reject([errorMsg]);
            },
        );
    };

export const validateFeatureFlags = analysis => (dispatch, getState) => {
    const state = getState();
    const matchedAADT = /Estimated_(.+)_AADT/.exec(analysis.project_type);
    const isAADTAnalysis = !!matchedAADT;
    const analysisType = isAADTAnalysis
        ? CREATE_ANALYSIS_TYPES.AADT.aadtTypes[analysis.project_type]
        : getAnalysisType(analysis.project_type, analysis.travel_mode_type);
    const analysisFeatureName = getAnalysisFeatureName(analysisType);
    const getTravelMode = () => {
        if (isAADTAnalysis) {
            return null;
        }

        return getAnalysisTravelModeFromCode(analysis.travel_mode_type);
    };
    const travelMode = getTravelMode();
    const validationErrors = [];

    if (getIsAnalysisWithDeprecatedTravelModes(analysis.project_type, analysis.travel_mode_type)) {
        const deprecatedTravelModeError =
            "The selected travel mode and analysis type is no longer supported for new analyses, please contact support if you have any questions.";
        return Promise.resolve([deprecatedTravelModeError]);
    }
    if (!analysisType || !analysisFeatureName) {
        return Promise.resolve([`${analysis.project_type} analysis type is not supported`]);
    }

    // Check analysis type feature flag
    if (!getIsOrgHasFeature(state, analysisFeatureName)) {
        validationErrors.push(`${analysisType.display} analysis type is disabled.`);
    }

    const isBikePedTravelMode = getIsBikeOrPedTravelMode(travelMode?.code);
    // Check 'Output: StreetLight All Vehicles Volume' feature flag
    if (
        travelMode &&
        !isBikePedTravelMode &&
        !getIsOrgHasFeature(state, "stl_volume") &&
        analysis.output_type_id === CALIBRATIONS.VOLUME.id
    ) {
        validationErrors.push(
            "StreetLight All Vehicles Volume output type for analyses is disabled.",
        );
    }

    // Check 'Output: StreetLight Bicycle Volume' is available
    if (
        travelMode?.code === TRAVEL_MODES.BICYCLE.code &&
        !getIsOrgHasFeature(state, "personal_bike_travel") &&
        analysis.output_type_id === CALIBRATIONS.BIKE_VOLUME.id
    ) {
        validationErrors.push("StreetLight Bicycle Volume output type for analyses is disabled.");
    }

    // Check 'Output: StreetLight Pedestrian Volume' is available
    if (
        travelMode?.code === TRAVEL_MODES.PEDESTRIAN.code &&
        !getIsOrgHasFeature(state, "personal_ped_travel") &&
        analysis.output_type_id === CALIBRATIONS.PED_VOLUME.id
    ) {
        validationErrors.push(
            "StreetLight Pedestrian Volume output type for analyses is disabled.",
        );
    }

    // Check 'Output: Single Factor Calibration' feature flag
    if (
        !getIsOrgHasFeature(state, "calibration") &&
        ([
            CALIBRATIONS.USER_COUNTS.id,
            AADT_CALIBRATIONS.AADT_2016.id,
            AADT_CALIBRATIONS.AADT_2017.id,
            AADT_CALIBRATIONS.AADT_2018.id,
            AADT_CALIBRATIONS.AADT_2019.id,
            AADT_CALIBRATIONS.AADT_2020.id,
            AADT_CALIBRATIONS.AADT_2021.id,
        ].includes(analysis.output_type_id) ||
            Object.values(analysis.cz_zones).flat().length > 0)
    ) {
        validationErrors.push("Calibration for analyses are disabled.");
    }

    // Check 'Output: IPF Calibration' feature flag
    if (
        !getIsOrgHasFeature(state, "ipf_calibration") &&
        analysis.output_type_id === CALIBRATIONS.IPF.id
    ) {
        validationErrors.push("IPF Calibration for analyses are disabled.");
    }

    // Check 'Output: StreetLight Index' feature flag
    if (
        !getIsOrgHasFeature(state, "stl_idx") &&
        analysis.output_type_id === CALIBRATIONS.INDEX.id
    ) {
        validationErrors.push("StreetLight Index output type for analyses is disabled.");
    }

    // Check 'Output: Sample Trip Count' feature flag
    if (
        !getIsOrgHasFeature(state, "sample_trip_count") &&
        analysis.output_type_id === CALIBRATIONS.TRIP_COUNTS.id
    ) {
        validationErrors.push("Sample Trip Counts output type for analyses is disabled.");
    }

    // Check 'Estimated 2016-2023 AADT Values' feature flag
    const estimatedAADTFeatures = [
        "estimated_2023_aadt",
        "estimated_2022_aadt",
        "estimated_2021_aadt",
        "estimated_2020_aadt",
        "estimated_2019_aadt",
        "estimated_2018_aadt",
        "estimated_2017_aadt",
        "estimated_2016_aadt",
    ];
    const analysisAADTFeature = estimatedAADTFeatures.find(featureName =>
        featureName.includes(analysis.aadt_calibration_year),
    );

    if (analysisAADTFeature && !getIsOrgHasFeature(state, analysisAADTFeature)) {
        validationErrors.push("AADT Calibration for analyses are disabled.");
    }

    // Check 'Trip Attributes' feature flag
    if (!getIsOrgHasFeature(state, "trip_attr") && analysis.premiumA) {
        validationErrors.push("Trip Attributes are disabled.");
    }

    // Check 'Traveler Attributes' feature flag
    if (!getIsOrgHasFeature(state, "traveler_attr") && analysis.premiumB) {
        validationErrors.push("Traveler Attributes are disabled.");
    }

    // Check 'Truck Data by Weight Class' feature flag
    if (!getIsOrgHasFeature(state, "comm_weight_class") && analysis.enable_veh_weight) {
        validationErrors.push("Vehicle Weight for analyses are disabled.");
    }

    // Check 'Home and Work Locations' feature flag
    if (!getIsOrgHasFeature(state, "home_work_locations") && analysis.premiumVHW) {
        validationErrors.push("Home and Work Locations feature for analyses is disabled.");
    }

    // Check 'Specific Project Dates' feature flag
    // Add validation error, only if analysis has Excluded Dates or its travel mode has editable Date Ranges
    const hasExcludedDates = !!analysis.excluded_specific_dates?.length;
    const hasEditableDateRanges = travelMode?.editableDataDateRanges;

    // Validation should pass for orgs with disabled 'specific_project_dates' flag
    // This check is only for analysis with date_format = "data_periods"
    const hasPartialMonthExcludedDates = () => {
        const partialDataPeriods = getPartialDataPeriods(state);
        const hasPartialDataMonth = !!analysis.data_periods.find(period =>
            isPartialDataPeriod(partialDataPeriods, period.year_num, period.month_num),
        );
        if (!hasPartialDataMonth || analysis.date_format === "date_ranges") return false;

        const excludedDateRanges = getParsedExcludedDateRanges(
            processExcludedDates(analysis.excluded_specific_dates),
        );
        const partialMonthsRange = getPartialMonthsRange(partialDataPeriods);

        // Check if there are any excluded dates inside partial months
        return excludedDateRanges.some(excludedPeriod => {
            return checkDatesInsidePartialDataPeriod(partialMonthsRange, [excludedPeriod]);
        });
    };

    if (
        !getIsOrgHasFeature(state, "specific_project_dates") &&
        ((hasExcludedDates && !hasPartialMonthExcludedDates()) ||
            (analysis.date_format === "date_ranges" && hasEditableDateRanges))
    ) {
        validationErrors.push("Specific Dates for analyses are disabled.");
    }

    // Check 'Customize Day Types and Parts' feature flag
    if (!getIsOrgHasFeature(state, "day_part_day_type")) {
        let defaultDayParts = DAY_PARTS.getDefault();
        let defaultDayTypes = isAADTAnalysis
            ? [DAY_TYPES.ALL_DAYS]
            : [DAY_TYPES.ALL_DAYS].concat(DAY_TYPES.DEFAULT);

        defaultDayParts = defaultDayParts.map(
            dayPart => `${dayPart.name} (${dayPart.start.name}-${dayPart.end.name})`,
        );

        const defaultDpArray = defaultDayParts.map(
            (dayPart, index) => `${index.toString()}: ${dayPart}`,
        );
        const defaultDpString = defaultDpArray.join(",");

        defaultDayTypes = defaultDayTypes.map(
            dayType =>
                `${dayType.name} (${DAY_CODES[dayType.start.name].shortName}-${
                    DAY_CODES[dayType.end.name].shortName
                })`,
        );
        const defaultDtArray = defaultDayTypes.map(
            (dayType, index) => `${index.toString()}: ${dayType}`,
        );
        const defaultDtString = defaultDtArray.join(",");

        const analysisDayPartsString = Array.isArray(analysis.day_parts)
            ? analysis.day_parts.join(",")
            : analysis.day_parts;
        const analysisDayTypesString = Array.isArray(analysis.day_types)
            ? analysis.day_types.join(",")
            : analysis.day_types;
        if (
            analysisDayPartsString !== defaultDpString ||
            analysisDayTypesString !== defaultDtString
        ) {
            validationErrors.push("Customize Day Types and Parts for analyses is disabled.");
        }
    }

    // Check Data Period Years feature flags
    const availableDataYears = getAvailableDataYears(state);
    let disabledDataYears;
    if (analysis.date_format === "date_ranges") {
        disabledDataYears = (analysis.date_ranges || []).reduce((res, dateRange) => {
            const startDateYear = moment.utc(dateRange.start_date, "MM-DD-YYYY").year();
            const endDateYear = moment.utc(dateRange.end_date, "MM-DD-YYYY").year();
            if (!availableDataYears.includes(startDateYear)) {
                res.add(startDateYear);
            }
            if (!availableDataYears.includes(endDateYear)) {
                res.add(endDateYear);
            }
            return res;
        }, new Set());
    } else {
        disabledDataYears = (analysis.data_periods || []).reduce((res, dataPeriod) => {
            if (!availableDataYears.includes(dataPeriod.year_num)) {
                res.add(dataPeriod.year_num);
            }
            return res;
        }, new Set());
    }
    if (disabledDataYears.size) {
        validationErrors.push(
            `${[...disabledDataYears].join(", ")} data period year(s) is disabled for the org.`,
        );
    }

    if (analysisType.code === CREATE_ANALYSIS_TYPES.ODG.code && analysis.geog_type) {
        const org = getUserOrg(state);
        const presetGeographyType = PRESET_GEOG_TYPES_LIST.find(
            type => type.code === analysis.geog_type,
        );
        if (!org[presetGeographyType.featureName]) {
            validationErrors.push(
                `${presetGeographyType.country} country is disabled for the org.`,
            );
        }
    }

    // Check feature flags for travel mode
    if (travelMode?.featureName && !getIsOrgHasFeature(state, travelMode.featureName)) {
        validationErrors.push(travelMode.featureValidationText);
    }

    return Promise.resolve(validationErrors);
};

export const copyAnalysis = analysisData => (dispatch, getState) => {
    if (analysisData.status === "Draft") {
        return dispatch(getDraft(analysisData, true /*isCopy*/));
    } else {
        return dispatch(getAnalysis(analysisData))
            .then(({ project }) =>
                dispatch(validateFeatureFlags(project)).then(errors => {
                    if (errors.length) {
                        return Promise.reject(errors);
                    }
                    const state = getState();
                    const availableDataMonths = getAvailableDataMonthsByMode(state);
                    const gmCVDFeatureState = getGMCVDFeatureState(state);
                    const analysisValidation = getCopyAnalysisValidation(project, {
                        availableDataMonths,
                        gmCVDFeatureState,
                    });

                    if (analysisValidation.hasWarnings || getIsTMCAnalysis(project.project_type)) {
                        return {
                            analysisData: project,
                            analysisValidation,
                            isDraft: false,
                        };
                    }

                    return dispatch(
                        setInitialAnalysisConfiguration({
                            analysisData: project,
                            isCopy: true,
                            isDraft: false,
                        }),
                    );
                }),
            )
            .catch(error => Promise.reject(error));
    }
};

export const viewAnalysis = analysisData => (dispatch, getState) => {
    const { isShowAllAnalysesAllowed } = getUiStates(getState());
    const params = {
        ...(isShowAllAnalysesAllowed && { show_all: true }),
        ...analysisData,
    };

    return dispatch(getAnalysis(params)).then(({ project }) =>
        dispatch(
            setInitialAnalysisConfiguration({
                analysisData: project,
                isCopy: false,
                isDraft: false,
            }),
        ),
    );
};

export const fetchReviewAnalysis = analysisData => dispatch => {
    const params = { show_all: true, ...analysisData };

    return dispatch(getAnalysis(params)).then(res =>
        dispatch(
            setInitialAnalysisConfiguration({
                analysisData: res.project,
                isCopy: false,
                isDraft: false,
            }),
        ),
    );
};

export const flagAnalysis = closeReview => (dispatch, getState) => {
    const state = getState();
    const analysisInfo = getGeneral(state);
    const { currentReviewNote, reviewNote, reviewNoteVersionId } = getReview(state);
    let requestParams = {
        status: "Flagged_Coverage",
        rerun: false,
        ignore_privacy_check: false,
        is_size_review: false,
    };

    if (analysisInfo.analysisStatus === ANALYSIS_STATUSES.IN_SIZE_REVIEW.id) {
        requestParams.is_size_review = true;
        requestParams.status = "Flagged_Size";
    }

    if (currentReviewNote !== reviewNote) {
        requestParams = {
            ...requestParams,
            review_note: currentReviewNote,
            rev_note_sa_version_id: reviewNoteVersionId,
        };
    }

    const param = { sa_version_id: analysisInfo.analysisVersionId };

    if (currentReviewNote === "") {
        return StlNotification.error("Please fill in review notes.");
    }

    return AnalysesApiService.updateAnalysis(analysisInfo.analysisId, requestParams, {
        params: param,
    })
        .then(() => {
            closeReview();
        })
        .catch(error => {
            const message = error?.response?.data?.message || error.message;
            dispatch(generalActions.setAnalysisActionError(message));

            StlNotification.error(message);
        });
};

export const saveAnalysisNote = closeReview => (dispatch, getState) => {
    const state = getState();
    const analysisInfo = getGeneral(state);
    const { currentReviewNote, reviewNote, reviewNoteVersionId } = getReview(state);

    if (reviewNote !== currentReviewNote || !currentReviewNote) {
        const requestParams = {
            review_note: currentReviewNote,
            rev_note_sa_version_id: reviewNoteVersionId,
        };

        const param = { sa_version_id: analysisInfo.analysisVersionId };

        return AnalysesApiService.updateAnalysis(analysisInfo.analysisId, requestParams, {
            params: param,
        })
            .catch(error => {
                const message = error?.response?.data?.message || error.message;

                dispatch(generalActions.setAnalysisActionError(message));
                StlNotification.error(message);
            })
            .finally(closeReview);
    }

    return closeReview();
};

export const confirmAnalysisReview =
    (closeReview, { withCaveats }) =>
    (dispatch, getState) => {
        const state = getState();
        const analysisInfo = getGeneral(state);
        const {
            currentReviewNote,
            reviewNote,
            reviewNoteVersionId,
            byPassRuntimeReview,
            byPassCoverageReview,
        } = getReview(getState());
        const requestParams = {
            status: "Processing",
            rerun: true,
            ignore_privacy_check: true,
            is_size_review: false,
            is_confirm_with_caveats: withCaveats,
        };

        if (analysisInfo.analysisStatus === ANALYSIS_STATUSES.IN_SIZE_REVIEW.id) {
            requestParams.ignore_privacy_check = false;
            requestParams.is_size_review = true;
            requestParams.ignore_runtime_check = byPassRuntimeReview;
        }

        if (analysisInfo.analysisStatus === ANALYSIS_STATUSES.IN_COVERAGE_REVIEW.id) {
            requestParams.bypass_sets_coverage = byPassCoverageReview;
        }

        if (reviewNote !== currentReviewNote) {
            requestParams.review_note = currentReviewNote;
            requestParams.rev_note_sa_version_id = reviewNoteVersionId;
        }

        if (currentReviewNote === "") {
            return StlNotification.error("Please fill in review notes.");
        }

        const params = { sa_version_id: analysisInfo.analysisVersionId };
        return AnalysesApiService.updateAnalysis(analysisInfo.analysisId, requestParams, {
            params,
        })
            .then(() => {
                closeReview();
            })
            .catch(error => {
                const message = error?.response?.data?.message || error.message;
                dispatch(generalActions.setAnalysisActionError(message));

                StlNotification.error(message);
            });
    };
