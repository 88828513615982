import {
    ANALYSIS_CONSTANTS,
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
    ZONES_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import { getAnalysisTravelModeFromCode } from "@app/analysis/state/analysisConfiguration.helpers";
import { createValidateField } from "@app/analysis/validation";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";
import {
    BUS_ZONE_KINDS,
    RAIL_ZONE_KINDS,
    ZONE_KINDS,
    ZONE_KINDS_WITH_OUTDATED_VINTAGES,
    ZONE_KINDS_WITH_OUTDATED_ZONES,
} from "@common/constants/zoneLibrary.constants";
import { getZonesByZoneKind } from "@common/features/zones/zones.helpers";
import { ZoneRoles } from "@common/features/zones/zones.types";
import { CALIBRATIONS_BY_TRAVEL_MODE_LIST } from "@common/features/zonesManager/components/zoneCalibration/zoneCalibration.constants";
import { validateZoneCalibrationInputByTravelMode } from "@common/features/zonesManager/components/zoneCalibration/zoneCalibration.helpers";
import {
    DEFAULT_BUS_ZONES_BUFFER_RADIUS,
    DEFAULT_ZONE_TYPE,
    OSM_SEGMENT_SPLIT_TYPES,
    ZONE_TYPES,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { cloneDeep, uniqBy } from "lodash-es";

import {
    DEFAULT_STANDARD_AREA,
    SUPPORTED_ROAD_TYPES_BY_TRAVEL_MODES,
} from "./chooseZones.constants";

export const makeZoneTypeObject = source => {
    switch (source.id) {
        case ZONE_TYPES.OSM_LINE_SEGMENTS.id: {
            return {
                id: ZONE_TYPES.OSM_LINE_SEGMENTS.id,
                [ZONE_TYPES.OSM_LINE_SEGMENTS.id]: {
                    segmentSplitType:
                        source[ZONE_TYPES.OSM_LINE_SEGMENTS.id] ||
                        OSM_SEGMENT_SPLIT_TYPES.TERTIARY.id,
                },
            };
        }

        case ZONE_TYPES.STANDARD_AREAS.id: {
            return {
                id: ZONE_TYPES.STANDARD_AREAS.id,
                [ZONE_TYPES.STANDARD_AREAS.id]:
                    source[ZONE_TYPES.STANDARD_AREAS.id] || DEFAULT_STANDARD_AREA.id,
            };
        }

        case ZONE_TYPES.USER.id: {
            return {
                id: ZONE_TYPES.USER.id,
            };
        }

        case ZONE_TYPES.TRANSIT.id: {
            const busFilters = source[ZONE_KINDS.BUS.code] || {};
            const railFilters = source[ZONE_KINDS.RAIL.code] || {};

            return {
                id: ZONE_TYPES.TRANSIT.id,
                [ZONE_KINDS.BUS.code]: {
                    bufferRadius: busFilters.bufferRadius || DEFAULT_BUS_ZONES_BUFFER_RADIUS,
                    selectedAgencies: busFilters.selectedAgencies,
                    selectedLines: busFilters.selectedLines,
                    selectedKinds: busFilters.selectedKinds || {
                        [BUS_ZONE_KINDS.ROUTES.id]: true,
                    },
                },
                [ZONE_KINDS.RAIL.code]: {
                    selectedAgencies: railFilters.selectedAgencies,
                    selectedLines: railFilters.selectedLines,
                    selectedKinds: railFilters.selectedKinds || {
                        [RAIL_ZONE_KINDS.SEGMENTS.id]: true,
                        [RAIL_ZONE_KINDS.STATIONS.id]: true,
                    },
                },
            };
        }

        default: {
            return DEFAULT_ZONE_TYPE;
        }
    }
};

export const getZonesFromAnalysis = (zoneKindMap, zoneKindIdsWithOldZones) => {
    return Object.entries(zoneKindMap).reduce((result, [zoneKindId, zones]) => {
        if (zoneKindIdsWithOldZones?.includes(Number(zoneKindId))) {
            return result;
        }
        const _zones = zones.map(zone => ({
            ...zone,
            zone_id: zone.zone_id.toString(),
            zone_kind_id: Number(zoneKindId),
        }));

        return [...result, ..._zones];
    }, []);
};

export const getZoneKindIdsWithOldZones = analysis => {
    const getKindIds = zones => {
        if (!zones) return [];

        return Object.keys(zones).reduce((zoneKinds, zoneKind) => {
            if (ZONE_KINDS_WITH_OUTDATED_VINTAGES.includes(Number(zoneKind))) {
                return [...zoneKinds, Number(zoneKind)];
            }

            if (ZONE_KINDS_WITH_OUTDATED_ZONES.includes(Number(zoneKind))) {
                const hasOldZones = zones[zoneKind].some(zone => zone.is_current === false);

                return hasOldZones ? [...zoneKinds, Number(zoneKind)] : zoneKinds;
            }

            return zoneKinds;
        }, []);
    };

    const zoneKindIds = ["oz_zones", "dz_zones", "mfz_zones"].reduce((result, zoneKindName) => {
        const kindIds = getKindIds(analysis[zoneKindName]);

        return [...result, ...kindIds];
    }, []);

    return [...new Set(zoneKindIds)];
};

export const validateODGZonesLocation = (selectedZones, analysisCountry, zonesLimitValidation) => {
    if (!zonesLimitValidation.canCreateAnalysis) {
        return zonesLimitValidation.message;
    }

    const isCountryCanada = analysisCountry === ORG_COUNTRIES.CA.code;
    const isCountryUS = analysisCountry === ORG_COUNTRIES.US.code;

    const isZonesValid = selectedZones.oz.every(zone => {
        return (isCountryCanada && zone.touches_ca) || (isCountryUS && zone.touches_us);
    });

    return !isZonesValid
        ? ANALYSIS_CONSTANTS.presetGeographyWarning(ZONES_MODES.CHOOSE_ZONES)
        : "";
};

export const getZoneIdsByZoneKind = selectedZones => {
    const allSelectedZones = Object.values(selectedZones).flat();
    const { zones } = getZonesByZoneKind(allSelectedZones);

    return zones;
};

export const getIsCalibrationZoneRole = zoneRole =>
    [ZoneRoles.az, ZoneRoles.cz].includes(zoneRole);

export const isZoneSupportedByTravelMode = ({ zone, travelModeCode, isTruckVolumeAnalysis }) => {
    // Truck Volume analyses support only pass-through zones
    if (isTruckVolumeAnalysis && !zone.is_pass) return false;

    const isOsmZoneKind = Number(zone.zone_kind_id) === ZONE_KINDS.OSM.id[0];
    if (!isOsmZoneKind) return true;

    const selectedTravelMode = getAnalysisTravelModeFromCode(travelModeCode);

    return SUPPORTED_ROAD_TYPES_BY_TRAVEL_MODES[selectedTravelMode.osmNetwork.id].includes(
        zone.highway,
    );
};

export const getUnsupportedByTravelModeZones = ({
    zones,
    travelModeCode,
    isTruckVolumeAnalysis,
}) => {
    const unavailableZones = Object.values(zones)
        .flat()
        .filter(
            zone => !isZoneSupportedByTravelMode({ zone, travelModeCode, isTruckVolumeAnalysis }),
        );

    return uniqBy(unavailableZones, "zone_id");
};

export const getSelectedZonesByRoles = ({ selectedZones, unavailableZones }) => {
    return Object.entries(selectedZones).reduce(
        (result, [role, zones]) => {
            const availableZones = zones.reduce((_result, zone) => {
                const isZoneAvailable = !unavailableZones.find(
                    unavailableZone => unavailableZone.zone_id === zone.zone_id,
                );

                if (isZoneAvailable) {
                    return [
                        ..._result,
                        {
                            ...zone,
                            zone_id: zone.zone_id.toString(),
                        },
                    ];
                }

                return _result;
            }, []);

            return {
                ...result,
                [role]: availableZones,
            };
        },
        {
            oz: [],
            dz: [],
            mfz: [],
            tfz: [],
        },
    );
};

export const convertZoneIdToString = zone => {
    const newZone = cloneDeep(zone);
    newZone.zone_id = zone.zone_id.toString();
    return newZone;
};

export const validateSelectedZones = ({
    selectedZones,
    analysisCountry,
    isTruckVolumeAnalysis,
    zonesLimitValidation,
    analysisTypeCode,
}) => {
    const allSelectedZones = Object.values(selectedZones).flat();

    if (!zonesLimitValidation.canCreateAnalysis) {
        return zonesLimitValidation.message;
    }
    if (isTruckVolumeAnalysis) {
        const isPassThroughZones = allSelectedZones.every(selectedZone => selectedZone.is_pass);
        if (!isPassThroughZones) {
            return ANALYSIS_CONSTANTS.truckVolumePassThroughZonesWarning;
        }
    }

    if (analysisTypeCode === CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.code) {
        const hasInvalidZones = allSelectedZones.some(selectedZone => !!selectedZone.error_type);

        if (hasInvalidZones) {
            return ANALYSIS_CONSTANTS.invalidZonesWarning;
        }
    }

    const isCountryCanada = analysisCountry === ORG_COUNTRIES.CA.code;
    const isCountryUS = analysisCountry === ORG_COUNTRIES.US.code;

    const isValidZones = allSelectedZones.every(selectedZone => {
        return (
            (isCountryCanada && selectedZone.touches_ca) ||
            (isCountryUS && selectedZone.touches_us)
        );
    });

    return !isValidZones ? ANALYSIS_CONSTANTS.zoneCountryWarning(ZONES_MODES.CHOOSE_ZONES) : "";
};

export const validateCalibrationZones = ({ zones, zoneRole, calibrationCode }) => {
    const zonesFieldError = [];
    const checkZones =
        (calibrationCode === CALIBRATIONS.USER_COUNTS.code && zoneRole === ZoneRoles.cz) ||
        (calibrationCode === CALIBRATIONS.AADT.code && zoneRole === ZoneRoles.az);

    const zoneRoleDisplayText = () => {
        let zoneText = "";
        if (zoneRole === ZoneRoles.az) {
            zoneText = "AADT calibration";
        } else if (zoneRole === ZoneRoles.cz) {
            zoneText = "calibration";
        }

        return zoneText ? `${zoneText} ` : "";
    };

    if (checkZones && !zones.length) {
        const zoneSetTypeText = zoneRoleDisplayText();
        zonesFieldError.push(
            `No ${zoneSetTypeText}zones are selected. Please select at least one ${zoneSetTypeText}zone`,
        );
    }

    return createValidateField(zonesFieldError.join(". "));
};

/*
    Validate "cz" role zones (user counts calibration):
    1. check that any zone is selected
    2. check calibration data for zones
*/
export const validateUserCountsCalibrationZones = ({
    zones,
    zoneRole,
    calibrationCode,
    travelModeCode,
}) => {
    const validation = validateCalibrationZones({ zones, zoneRole, calibrationCode });
    if (validation.isInvalid || calibrationCode !== CALIBRATIONS.USER_COUNTS.code) {
        return validation;
    }

    const calibrationByTravelMode = CALIBRATIONS_BY_TRAVEL_MODE_LIST.find(calibration =>
        calibration.travelModes.includes(travelModeCode),
    );
    const calibrationByTravelModeName = calibrationByTravelMode?.typeName || "";

    const calibrationErrors = zones.reduce(
        (res, zone) => {
            if (!zone?.with_calibration) {
                res.noCalibrationZones.push(zone.zone_name);
                return res;
            }
            const calibrationValidation = validateZoneCalibrationInputByTravelMode(
                zone,
                travelModeCode,
            );
            if (calibrationValidation.length) {
                res.invalidCalibrationZones.push(zone.zone_name);
            }
            return res;
        },
        { noCalibrationZones: [], invalidCalibrationZones: [] },
    );

    // Show generic messages as showing all errors will occupy a lot of space
    const errors = [];
    if (calibrationErrors.noCalibrationZones.length) {
        errors.push(
            `Please add ${calibrationByTravelModeName} calibration data for the zone(s): ${calibrationErrors.noCalibrationZones.join(
                ", ",
            )}`,
        );
    }
    if (calibrationErrors.invalidCalibrationZones.length) {
        errors.push(
            `Please check ${calibrationByTravelModeName} calibration data for the zone(s): ${calibrationErrors.invalidCalibrationZones.join(
                ", ",
            )}`,
        );
    }
    return createValidateField(errors.join(". "));
};

export const isFieldsInvalid = validationFields => {
    return Object.keys(validationFields).some(fieldName => {
        return validationFields[fieldName] && validationFields[fieldName].isInvalid;
    });
};

export const getZoneDirection = zone => {
    if (zone.direction === undefined || zone.direction === null) {
        return zone.is_pass ? "Not Set" : "N/A";
    }
    return zone.direction;
};

export const getZonesCount = zoneSet =>
    zoneSet.reduce((total, { zone_count, zones }) => total + (zone_count || zones.length), 0);

export const getSupportedByTravelModeZones = ({
    selectedZones,
    travelModeCode,
    isTruckVolumeAnalysis,
}) => {
    const unavailableZones = getUnsupportedByTravelModeZones({
        zones: selectedZones,
        travelModeCode,
        isTruckVolumeAnalysis,
    });

    return getSelectedZonesByRoles({ selectedZones, unavailableZones });
};
