import { ZONE_GEOM_TYPES, ZONE_NAME_MAX_LENGTH } from "@common/constants/zoneSet.constants";
import { store } from "@app/store";
import { getConfigParams } from "@app/store/currentUser/currentUser.selector";
import {
    CALIBRATION_FIELDS_LIST,
    UPLOAD_VALIDATION_MSG,
    UPLOAD_BLANK_OPTION,
    UPLOAD_DEFAULT_OPTION,
    REQUIRED_FIELDS,
    ZONE_SET_MODAL_TRAVEL_MODES,
} from "@common/features/zonesManager/components/uploadZonesModal/uploadZonesModal.constants";
import {
    FieldChoices,
    IInvalidField,
    IUploadZonesError,
    IZoneSetData,
    IZonesUploadData,
    IZonesUploadToAnalysisData,
    IZonesUploadToZoneSetData,
    TParsedZonesUploadData,
} from "@common/features/zonesManager/components/uploadZonesModal/uploadZonesModal.types";
import { IZoneSet } from "@common/features/zones/zones.types";
import { EDefaultColors } from "@common/constants/color.constants";

export const getValidationMessage = (msg: string) => {
    return `${msg} ${UPLOAD_VALIDATION_MSG}`;
};

export const getErrorMessage = (error: IUploadZonesError) => {
    if (error?.isValidation) {
        return `A problem occurred while processing the shapefile. Please make changes to the file as needed and try again (${error.message}).`;
    }
    return error.message;
};

export const getValidValue = (value?: string) => {
    return value && value !== UPLOAD_DEFAULT_OPTION ? value : "";
};

export const getSelectOptions = (options: Array<string> = []) =>
    options.map((option: string) => ({ label: option, value: option }));

export const isDisplayOptionsValid = (displayOptions: Array<string>) => {
    // Not valid if only default option is available.
    const hasOnlyDefaultOption =
        displayOptions.length === 1 && displayOptions[0] === UPLOAD_DEFAULT_OPTION;

    return !!displayOptions.length && !hasOnlyDefaultOption;
};

export const validateBreakToChunksOption = (chunksSize: number, maxZoneSetChunkSize: number) => {
    return chunksSize <= maxZoneSetChunkSize && chunksSize >= 1;
};

export const validateZoneSetName = (
    name: string,
    zoneSetNames: Array<IZoneSetData>,
    position: number,
) => {
    return zoneSetNames.some(
        (_zoneSetName, index) => index !== position && _zoneSetName.set_name === name,
    );
};

export const hasUploadCalibration = (uploadData: IZonesUploadData) => {
    return (
        uploadData.withCalibration ||
        uploadData.withBikeCalibration ||
        uploadData.withPedCalibration
    );
};

export const getDefaultCalibrationDisplayOptions = (
    zonesUploadData: Partial<IZonesUploadData>,
) => {
    return CALIBRATION_FIELDS_LIST.reduce((res, option) => {
        return {
            ...res,
            [option.displayOptionName]: [
                ...((zonesUploadData[
                    option.editOptionName as keyof typeof zonesUploadData
                ] as Array<string>) || []),
            ],
        };
    }, {});
};

export const filterDefaultOptions = (fieldValues: Partial<IInvalidField | FieldChoices>) =>
    Object.keys(fieldValues).filter(
        key => fieldValues[key as keyof typeof fieldValues] === UPLOAD_DEFAULT_OPTION,
    );

// Validate basic fields selection: Zone Name, Zone ID, Pass-Through, Direction and Bi-Di
export const validateZonesUploadBasicData = (zonesUploadData: IZonesUploadData) => {
    const fieldValues = {
        "Zone Name": zonesUploadData.nameSelect,
        "Zone ID": zonesUploadData.idSelect,
        "Zone is Pass-Through": zonesUploadData.isPassSelect,
        Direction: zonesUploadData.directionSelect,
        "Bi-directionality": zonesUploadData.isBidiSelect,
    };

    return filterDefaultOptions(fieldValues);
};

// Validate Calibration Data
export const validateZonesUploadCalibrationData = (zonesUploadData: TParsedZonesUploadData) => {
    let fieldValues = {} as Partial<IInvalidField>;

    // Ignore validation for optional fields, such as collection method.
    if (zonesUploadData.withCalibration) {
        fieldValues = {
            ...fieldValues,
            "Vehicles Calibration Type": zonesUploadData.calibrationTypeSelect,
            "Vehicles Calibration Value": zonesUploadData.calibrationValueSelect,
            "Personal Traffic Ratio": zonesUploadData.persRatioSelect,
            "Commercial Medium Duty Traffic Ratio": zonesUploadData.mdRatioSelect,
            "Commercial Heavy Duty Traffic Ratio": zonesUploadData.hdRatioSelect,
        };
    }

    if (zonesUploadData.withBikeCalibration) {
        fieldValues["Bicycles Calibration Value"] = zonesUploadData.bikeCalValueSelect;
    }

    if (zonesUploadData.withPedCalibration) {
        fieldValues["Pedestrians Calibration Value"] = zonesUploadData.pedCalValueSelect;
    }

    const invalidFields = filterDefaultOptions(fieldValues);
    if (
        zonesUploadData.isPassSelect !==
            "[Always No (note, will ignore direction, if provided)]" &&
        zonesUploadData.directionSelect === UPLOAD_DEFAULT_OPTION
    ) {
        invalidFields.push("Direction");
    }

    return invalidFields;
};

const validateAdvancedControls = (zonesUploadData: IZonesUploadData) => {
    const { shapefile_max_zone_set_chunk_size: maxZoneSetChunkSize } = getConfigParams(
        store.getState(),
    );

    const { shouldBreakToChunks, chunksSize } = zonesUploadData.breakToChunks || {};

    const invalidFields = [];

    if (
        shouldBreakToChunks &&
        !validateBreakToChunksOption(Number(chunksSize), maxZoneSetChunkSize)
    ) {
        invalidFields.push(`zone count in each set that is between 1 and ${maxZoneSetChunkSize}`);
    }

    return invalidFields;
};

export const validateZonesUploadData = (
    zonesUploadData: IZonesUploadData,
    isFullValidation: boolean,
) => {
    const basicValidation = validateZonesUploadBasicData(zonesUploadData);
    const calibrationValidation = isFullValidation
        ? validateZonesUploadCalibrationData(zonesUploadData)
        : [];
    const advancedControlsValidation = validateAdvancedControls(zonesUploadData);

    return [...basicValidation, ...calibrationValidation, ...advancedControlsValidation];
};

const hasUnselectedZonesUploadData = (zonesUploadData: IZonesUploadData, isLineZone: boolean) => {
    const lineZoneRequiredFields = ["gateLatSelect", "gateLngSelect", "gateSizeSelect"];

    return REQUIRED_FIELDS.some(fieldName => {
        if (!isLineZone && lineZoneRequiredFields.includes(fieldName)) return false;

        return !zonesUploadData[fieldName] || zonesUploadData[fieldName] === UPLOAD_DEFAULT_OPTION;
    });
};

export const validateUploadForm = (
    zonesUploadData: IZonesUploadData,
    zoneSetName: string,
    isZoneSetUploadType: boolean,
) => {
    const basicFieldsValidation = validateZonesUploadBasicData(zonesUploadData);
    const parsedZonesUploadData = getUploadZonesCalibrationData(zonesUploadData);

    const isLineZone = zonesUploadData.zoneSetGeomType === ZONE_GEOM_TYPES.LINE.id;
    const isZoneSetNameValid = zoneSetName?.length && zoneSetName.length <= ZONE_NAME_MAX_LENGTH;
    const invalidRoadType =
        !zonesUploadData.roadTypeSelect ||
        zonesUploadData.roadTypeSelect === UPLOAD_DEFAULT_OPTION;
    const isRoadTypeAvailable = isLineZone && !!zonesUploadData.road_type_options.length;

    const isSaveDisabled =
        !!basicFieldsValidation.length ||
        !!parsedZonesUploadData.invalidCalibrationMode ||
        (isZoneSetUploadType && !isZoneSetNameValid) ||
        hasUnselectedZonesUploadData(zonesUploadData, isLineZone) ||
        (isRoadTypeAvailable && invalidRoadType);

    return { isSaveDisabled, invalidRoadType, parsedZonesUploadData };
};

export const parseUploadFileResponse = (response: any, { uuid }: { uuid: string }) => {
    const mappings = response.field_mapping_options || {};

    const uploadData = {
        uuid,
        name_options: mappings.name,
        id_options: mappings.id,
        is_pass_options: mappings.is_pass,
        direction_options: mappings.direction,
        is_bidi_options: mappings.is_bidi,
        road_type_options: mappings.road_type,
        gate_lat_options: mappings.gate_lat,
        gate_lng_options: mappings.gate_lng,
        gate_size_options: mappings.gate_size,
        calibration_type_options: mappings.cal_type,
        calibration_value_options: mappings.cal_value,
        pers_ratio_options: mappings.pers_ratio,
        md_ratio_options: mappings.md_ratio,
        hd_ratio_options: mappings.hd_ratio,
        collection_method_options: mappings.cal_method,
        description_options: mappings.desc_notes,
        bike_cal_value_options: mappings.bcal_value,
        bike_desc_options: mappings.bike_desc,
        ped_cal_value_options: mappings.pcal_value,
        ped_desc_options: mappings.ped_desc,
        // Assume single calibration type for bicycle and pedestrian.
        bikeCalTypeInput: mappings.bcal_type?.[0],
        pedCalTypeInput: mappings.pcal_type?.[0],
        nameSelect: mappings.name?.[0],
        idSelect: mappings.id?.[0],
        isPassSelect: mappings.is_pass?.[0],
        directionSelect: mappings.direction?.[0],
        isBidiSelect: mappings.is_bidi?.[0],
        zoneSetGeomType: response.geom_type,
        fieldValidationSummary: response.field_mapping_validation_messages,
        vehicleCalibrationValidationSummary: response.calibration_field_validation_summary,
        bikeCalibrationValidationSummary: response.bike_cal_field_validation_summary,
        pedCalibrationValidationSummary: response.ped_cal_field_validation_summary,
        breakToChunks: {
            shouldBreakToChunks: false,
            chunksSize: null,
            zoneSetsData: [],
        },
        zone_count: response.zone_count,
    } as Partial<IZonesUploadData>;

    if (response.geom_type === ZONE_GEOM_TYPES.LINE.id) {
        uploadData.roadTypeSelect = mappings.road_type?.[0];
        uploadData.gateLatSelect = mappings.gate_lat?.[0];
        uploadData.gateLngSelect = mappings.gate_lng?.[0];
        uploadData.gateSizeSelect = mappings.gate_size?.[0];
    }

    const calibrationDisplayOptions = getDefaultCalibrationDisplayOptions(uploadData);

    return { ...uploadData, ...calibrationDisplayOptions } as Partial<IZonesUploadData>;
};

export const convertZonesUploadDataToApiObject = ({
    zoneSet,
    zonesUploadData,
}: {
    zoneSet?: IZoneSet;
    zonesUploadData: TParsedZonesUploadData;
}) => {
    let fieldMappings = {
        name: zonesUploadData.nameSelect,
        id: zonesUploadData.idSelect,
        is_pass: zonesUploadData.isPassSelect,
        direction: zonesUploadData.directionSelect,
        is_bidi: zonesUploadData.isBidiSelect,
    } as FieldChoices;

    if (
        zonesUploadData.isPassSelect === "[Always No (note, will ignore direction, if provided)]"
    ) {
        fieldMappings.direction = UPLOAD_BLANK_OPTION;
    }

    if (zonesUploadData.withCalibration) {
        fieldMappings = {
            ...fieldMappings,
            cal_value: zonesUploadData.calibrationValueSelect,
            cal_type: zonesUploadData.calibrationTypeSelect,
            pers_ratio: zonesUploadData.persRatioSelect,
            md_ratio: zonesUploadData.mdRatioSelect,
            hd_ratio: zonesUploadData.hdRatioSelect,
            cal_method: zonesUploadData.collectionMethodSelect,
            desc_notes: zonesUploadData.descriptionSelect,
        };
    }

    if (zonesUploadData.withBikeCalibration) {
        fieldMappings = {
            ...fieldMappings,
            bcal_type: zonesUploadData.bikeCalTypeInput,
            bcal_value: zonesUploadData.bikeCalValueSelect,
            bike_desc: zonesUploadData.bikeDescSelect,
        };
    }

    if (zonesUploadData.withPedCalibration) {
        fieldMappings = {
            ...fieldMappings,
            pcal_type: zonesUploadData.pedCalTypeInput,
            pcal_value: zonesUploadData.pedCalValueSelect,
            ped_desc: zonesUploadData.pedDescSelect,
        };
    }

    if (zonesUploadData.zoneSetGeomType === ZONE_GEOM_TYPES.LINE.id) {
        fieldMappings = {
            ...fieldMappings,
            road_type: zonesUploadData.roadTypeSelect,
            gate_lat: zonesUploadData.gateLatSelect,
            gate_lng: zonesUploadData.gateLngSelect,
            gate_size: zonesUploadData.gateSizeSelect,
        };
    }

    const withCalibration = hasUploadCalibration(zonesUploadData);

    const data = {
        zone_shapefile_uuid: zonesUploadData.uuid,
        field_choices: fieldMappings,
        with_calibration: withCalibration,
    };

    if (zoneSet?.set_name) {
        return {
            ...data,
            ...(zonesUploadData.breakToChunks?.shouldBreakToChunks
                ? {
                      chunk_size: zonesUploadData.breakToChunks.chunksSize,
                      sets: zonesUploadData.breakToChunks.zoneSetsData,
                  }
                : {
                      sets: [
                          {
                              set_name: zoneSet.set_name,
                              zone_set_color: zoneSet?.zone_set_color || null,
                          },
                      ],
                  }),
        } as IZonesUploadToZoneSetData;
    } else {
        return data as IZonesUploadToAnalysisData;
    }
};

export const getUploadZonesCalibrationData = (zonesUploadData: IZonesUploadData) => {
    const zoneSelectList = [
        zonesUploadData.nameSelect,
        zonesUploadData.idSelect,
        zonesUploadData.isPassSelect,
        zonesUploadData.directionSelect,
        zonesUploadData.isBidiSelect,
    ];

    const calibrationData = CALIBRATION_FIELDS_LIST.reduce((res, option) => {
        // Remove any calibration display option that is in zoneSelectList.
        const filteredOptions = (
            zonesUploadData[
                option.displayOptionName as keyof typeof zonesUploadData
            ] as Array<string>
        )?.filter((optionValue: string) => {
            if ([UPLOAD_DEFAULT_OPTION, UPLOAD_BLANK_OPTION].includes(optionValue)) {
                return true;
            }
            return !zoneSelectList.includes(optionValue);
        });

        return {
            ...res,
            [option.displayOptionName]: filteredOptions,
            // Assign the first display option for each calibration field.
            [option.name]: (
                zonesUploadData[
                    option.displayOptionName as keyof typeof zonesUploadData
                ] as Array<string>
            )?.[0],
        };
    }, {} as IZonesUploadData);

    let invalidCalibrationMode = "";
    if (
        zonesUploadData.withCalibration &&
        !isDisplayOptionsValid(calibrationData.calibration_value_options_display)
    ) {
        invalidCalibrationMode = ZONE_SET_MODAL_TRAVEL_MODES.VEHICLE.id;
    } else if (
        zonesUploadData.withBikeCalibration &&
        !isDisplayOptionsValid(calibrationData.bike_cal_value_options_display)
    ) {
        invalidCalibrationMode = ZONE_SET_MODAL_TRAVEL_MODES.BICYCLE.id;
    } else if (
        zonesUploadData.withPedCalibration &&
        !isDisplayOptionsValid(calibrationData.ped_cal_value_options_display)
    ) {
        invalidCalibrationMode = ZONE_SET_MODAL_TRAVEL_MODES.PEDESTRIAN.id;
    }

    return {
        ...zonesUploadData,
        ...calibrationData,
        invalidCalibrationMode,
    };
};

export const getZoneSetsData = (
    zoneSetCount: number,
    zoneSetName: string,
    zoneSetColor: EDefaultColors,
) => {
    const sets = [];

    for (let i = 0; i <= zoneSetCount - 1; i++) {
        sets.push({
            set_name: !sets.length ? zoneSetName : `${zoneSetName} ${i}`,
            zone_set_color: !sets.length && zoneSetColor ? zoneSetColor : null,
        });
    }

    return sets;
};
