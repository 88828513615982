import { useEffect, useState } from "react";
import type { Map, Layer } from "mapbox-gl";
import { getFirstLabelLayerId, toSafeMatchArray } from "@common/utils/mapUtils";
import type { IOSMMetaLayer } from "@common/services/server/layerGroupApi.types";
import { getRoadIdsFromRoads } from "@common/features/zonesManager/state/zonesManager.helpers";
import { TRoadType } from "@common/features/zonesManager/state/zonesManager.constants";
import {
    OSM_LAYERS_LIST,
    MIN_INTERACTIVE_ZOOM,
    OSM_LAYER_STYLES,
    TOSMLayer,
} from "../mapLayers.constants";

const toOSMLayerId = (id: string) => `stl:osm:line:${id}`;

const getLayerType = (layerId: IOSMMetaLayer["id"]) => {
    const osmLayer = OSM_LAYERS_LIST.find(layer =>
        layerId.includes(layer.layerCode.toLowerCase()),
    )!;

    return osmLayer.layerCode;
};

interface ILayer {
    code: string;
    layerCode: string;
    display: string;
    legend: {
        style: string;
    };
    features: readonly string[];
    children?: TRoadType["id"][];
}

export const useOSMLayersVisibility = (
    map: Map | null,
    {
        windshaftLayers,
        osmLayerCodes,
    }: {
        windshaftLayers: Array<IOSMMetaLayer>;
        osmLayerCodes: Array<TOSMLayer["layerCode"]>;
    },
) => {
    useEffect(() => {
        if (!map || !windshaftLayers?.length) return;

        windshaftLayers.forEach(layer => {
            const layerType = getLayerType(layer.id);

            map.setLayoutProperty(
                toOSMLayerId(layer.id),
                "visibility",
                osmLayerCodes.includes(layerType) ? "visible" : "none",
            );
        });
    }, [map, windshaftLayers, osmLayerCodes]);
};

type TStateLayer = Layer & { layerOSMCode: TOSMLayer["layerCode"] };

export const useDrawLineLayers = (
    map: Map | null,
    {
        source,
        windshaftLayers,
        osmLayerCategories,
    }: {
        source: string | null;
        windshaftLayers: Array<IOSMMetaLayer>;
        osmLayerCategories: ILayer[];
    },
) => {
    const [staticLayers, setStaticLayers] = useState<TStateLayer[]>([]);

    useEffect(() => {
        if (!map || !windshaftLayers?.length || !source) return undefined;

        const _staticLayers = [] as TStateLayer[];

        windshaftLayers.forEach(layer => {
            const layerCode = getLayerType(layer.id);
            const layerStyles = OSM_LAYER_STYLES[layerCode];

            const lineOutlineLayer = {
                id: toOSMLayerId(layer.id),
                type: "line",
                minzoom: MIN_INTERACTIVE_ZOOM.OSM,
                source: source,
                "source-layer": layer.id,
                paint: layerStyles.line,
                layerOSMCode: layerCode,
            } as TStateLayer;

            if (map.getLayer(lineOutlineLayer.id)) {
                map.removeLayer(lineOutlineLayer.id);
            }

            map.addLayer(lineOutlineLayer, getFirstLabelLayerId(map));
            _staticLayers.push(lineOutlineLayer);
        });

        setStaticLayers(_staticLayers);

        return () => setStaticLayers([]);
    }, [map, windshaftLayers, source]);

    useEffect(() => {
        if (!map || !staticLayers.length || !map.getSource(staticLayers[0].source as string)) {
            return;
        }

        staticLayers.forEach(staticLayer => {
            const roadList =
                osmLayerCategories.find(
                    category => category.layerCode === staticLayer.layerOSMCode,
                )?.children || [];

            const roadsIds = getRoadIdsFromRoads(roadList);

            if (roadsIds.length) {
                map.setFilter(staticLayer.id, [
                    "all",
                    [
                        "any",
                        ["==", ["get", "highway"], null],
                        ["match", ["get", "highway"], toSafeMatchArray(roadsIds), true, false],
                    ],
                ]);
            }
        });
    }, [map, staticLayers, osmLayerCategories, windshaftLayers.length]);
    return { staticLayers };
};
