import { DAY_PARTS, DAY_TYPES } from "@app/analysis/timePeriods/state/timePeriods.constants";
import { SWITCH_MODES, ZONE_ROLES } from "./chooseZones.constants";

export const CHOOSE_ZONES_INITIAL_STATE = {
    zoneRole: ZONE_ROLES.DEFAULT.accessor,
    zoneType: null,
    location: null,
    roads: [],
    selectedZones: {
        oz: [],
        dz: [],
        mfz: [],
        tfz: [],
        cz: [],
        az: [],
    },
    ipfCalibrationSettings: {
        dayTypes: [DAY_TYPES.ALL_DAYS],
        dayParts: [DAY_PARTS.ALL_DAY],
        ipfValues: {},
    },
    uiStates: {
        showZonesByRole: {
            oz: true,
            dz: true,
            mfz: true,
            tfz: true,
            cz: true,
            az: true,
        },
        loadingZoneTables: {
            oz: false,
            dz: false,
            mfz: false,
            tfz: false,
        },
        activeTabId: SWITCH_MODES.ANALYSIS_ZONES.id,
        newCalibrationZonesCount: 0,
    },
    questionableZoneIds: [],
    zoneVintage: {},
    hoveredZone: null,
    hoveredDacZone: null,
    hoveredBusZones: [],
    mapError: null,
    isWizardModalOpen: false,
    shouldCreateZoneSet: false,
    validation: {
        fields: {
            zones: null,
            chooseZones: null,
            ipfValues: null,
            cz: null,
            az: null,
        },
        isInvalid: false,
    },
};
